import React, { FC, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import { FormTextField } from '../UI/FormTextField'
import { FormPhoneField } from '../UI/FormPhoneField'

import { getErrorMessage } from '../../utils/reactHookForm'
import { defaultOperator, Operator } from '../../models/operator'
import { phonesCheck, str, namesCheck } from '../../assets/constans/formValidation'
import { COLORS } from '../../theme'

const operatorSchema = z.object({
    id: z.number().optional(),
    partnerName: str.optional(),
    customerName: namesCheck,
    mainContactNumber: phonesCheck,
    emergencyContactNumber: phonesCheck.or(str.length(0)),
    mainContactFirstName: namesCheck.or(str.length(0)),
    mainContactSurname: namesCheck.or(str.length(0)),
    mainContactEmail: str.email('Invalid email address')
})

const keys = operatorSchema.keyof().Values
type Keys = keyof typeof keys

export type OperatorSchema = ReturnType<typeof operatorSchema['parse']>

interface OperatorFormProps {
    customer: string | undefined
    open: boolean
    onClose: () => void
    onSave: (user: OperatorSchema, id?: number) => void
    operator: Operator | null
}

const defaultValues: OperatorSchema = defaultOperator

const OperatorForm: FC<OperatorFormProps> = ({ customer, open, operator, onClose, onSave }) => {
    const {
        handleSubmit,
        watch,
        control,
        reset,
        formState: { errors }
    } = useForm<OperatorSchema>({
        defaultValues,
        resolver: zodResolver(operatorSchema)
    })

    useEffect(() => {
        if (operator) {
            const prefilledFields: Omit<OperatorSchema, 'id' | 'partnerName'> = {
                customerName: operator.customerName,
                mainContactFirstName: operator.mainContactFirstName,
                mainContactSurname: operator.mainContactSurname,
                mainContactEmail: operator.mainContactEmail,
                mainContactNumber: operator.mainContactNumber,
                emergencyContactNumber: operator.emergencyContactNumber
            }
            reset(prefilledFields)
        }
    }, [operator])

    const onSubmit = handleSubmit((operatorForm) => {
        onSave(operatorForm, operator?.id)
        close()
        return
    })

    const close = () => {
        reset(defaultValues, { keepDefaultValues: true })
        onClose()
    }
    const errText = (key: Keys) => (errors[key] ? getErrorMessage(errors[key]!) : '')

    return (
        <Dialog
            keepMounted={false}
            open={open}
            onClose={close}
            sx={{ '& .MuiPaper-root': { width: '812px', height: 'auto', padding: '24px' } }}
        >
            <Grid
                container
                justifyContent='space-around'
                direction='column'
                alignItems='center'
                height='100%'
            >
                <form onSubmit={onSubmit}>
                    <DialogTitle textAlign='center' mt='8px' mb='16px' variant='title1'>
                        <Typography variant='title1Bold'>
                            {operator ? 'Edit operator' : 'Add a new operator'}
                        </Typography>
                    </DialogTitle>
                    <Grid container direction='column' justifyContent='space-between'>
                        <Grid container>
                            <Controller
                                control={control}
                                name={'customerName'}
                                render={({ field }) => (
                                    <FormTextField
                                        textFieldProps={{
                                            label: 'Name',
                                            required: true
                                        }}
                                        error={errText('customerName')}
                                        field={field}
                                    />
                                )}
                            />
                        </Grid>
                        <Typography m={'16px 0 8px 16px'} variant='subheadlineBold' color='secondary'>
                            Main contact:
                        </Typography>
                        <Grid container spacing={2} justifyContent='space-between'>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={'mainContactFirstName'}
                                    render={({ field }) => (
                                        <FormTextField
                                            textFieldProps={{
                                                label: 'First name'
                                            }}
                                            error={errText('mainContactFirstName')}
                                            field={field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={'mainContactSurname'}
                                    render={({ field }) => (
                                        <FormTextField
                                            textFieldProps={{ label: 'Surname' }}
                                            error={errText('mainContactSurname')}
                                            field={field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt='8px' container spacing={2} justifyContent='space-between'>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={'mainContactNumber'}
                                    render={({ field }) => (
                                        <FormPhoneField
                                            textFieldProps={{
                                                label: 'Contact number',
                                                required: true
                                            }}
                                            error={errText('mainContactNumber')}
                                            field={field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={'emergencyContactNumber'}
                                    render={({ field }) => (
                                        <FormPhoneField
                                            textFieldProps={{ label: 'Emergency number' }}
                                            error={errText('emergencyContactNumber')}
                                            field={field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt='8px' container spacing={2} justifyContent='space-between'>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={'mainContactEmail'}
                                    render={({ field }) => (
                                        <FormTextField
                                            textFieldProps={{ label: 'Email', required: true }}
                                            error={errText('mainContactEmail')}
                                            field={field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        mt='14px'
                        container
                        justifyContent='space-between'
                        alignItems='flex-end'
                        spacing={2}
                    >
                        <Grid item>
                            <Button sx={{ minWidth: '200px' }} variant='outlined' onClick={close}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                disabled={
                                    !!Object.keys(errors).length ||
                                    !watch('mainContactEmail') ||
                                    !watch('customerName') ||
                                    !watch('mainContactNumber')
                                }
                                sx={{
                                    minWidth: '200px',
                                    background: customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399'
                                }}
                                variant='contained'
                                type='submit'
                                autoFocus
                            >
                                {operator ? 'Update' : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Dialog>
    )
}

export default OperatorForm
