import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { Operator, defaultOperator } from '../models/operator'
import { useUserMe } from './user'
import axios, { AxiosResponse } from 'axios'

const OPERATORS = 'operators'
const url = process.env.REACT_APP_ROUTING_API_URL
const STALE = 20 * 60 * 1000

type UseOperator = UseQueryResult<Operator[], unknown>

export const useOperator = (): UseOperator => {
  const { data: userMe } = useUserMe()

  return useQuery(
    [OPERATORS],
    async () => {
      const { data }: AxiosResponse<Operator[]> = await axios.get(
        `${url}/Operator?${userMe?.options}`
      )
      return data
    },
    {
      enabled: !!userMe?.partner,
      initialDataUpdatedAt: Date.now(),
      staleTime: STALE,
      select: (data: Operator[]) =>
        data
          .map((item) => {
            const newItem: Operator = { ...defaultOperator, ...item }
            return newItem
          })
          .reverse()
    }
  )
}

export const useUpdateOperator = () => {
  const queryClient = useQueryClient()
  const { data: userMe } = useUserMe()

  return useMutation(
    async (payload: Partial<Operator>) => {
      payload.partnerName = userMe?.partner
      const { data } = await axios.put(`${url}/Operator`, payload)
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData([OPERATORS], (oldData?: Operator[]) =>
          oldData?.map((item) => (item.id === id ? data : item))
        )
      }
    }
  )
}

export const useAddOperator = () => {
  const queryClient = useQueryClient()
  const { data: userMe } = useUserMe()

  return useMutation(
    async (payload: Partial<Operator>) => {
      payload.partnerName = userMe?.partner
      const { data } = await axios.post(`${url}/Operator`, payload)
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          [OPERATORS],
          (oldData?: Operator[]) => oldData && [...oldData, data]
        )
      }
    }
  )
}

export const useRemoveOperator = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (payload: Partial<Operator>) => {
      const { data } = await axios.delete(`${url}/Operator`, {
        data: {
          ...payload
        }
      })
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData(
          [OPERATORS],
          (oldData?: Operator[]) => oldData && [...oldData.filter((item) => item.id !== id)]
        )
      }
    }
  )
}
