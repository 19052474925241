import React, { FC } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

type Props = {
  error?: string
  field: { value?: string; onChange: (value: string | null) => void }
  limit?: number
  textFieldProps?: TextFieldProps
}

export const FormTextField: FC<Props> = ({ error, field, limit, textFieldProps }) => (
  <TextField
    {...field}
    {...textFieldProps}
    onChange={({ target }) => field.onChange(target.value.slice(0, limit))}
    onBlur={(e) => field.onChange(e.target.value.trim())}
    error={!!error}
    helperText={error}
    InputProps={{
      endAdornment: (
        <IconButton
          onClick={() => field.onChange('')}
          disabled={!field.value}
          sx={{ opacity: field.value ? 1 : 0 }}
        >
          <HighlightOffIcon />
        </IconButton>
      )
    }}
  />
)
