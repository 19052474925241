import * as azureMapsRest from 'azure-maps-rest'
import { SearchFuzzyOptions } from 'azure-maps-rest'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { pipeline } from '../setupPipeline'
import { getUserGeolocation, UserGeolocation } from '../utils/userGeolocation'

// Create an instance of the SearchURL client.
const searchURL = new azureMapsRest.SearchURL(pipeline)
// I have created this type because original type doesn't have 'categorySet' query param
// but in the docs it is.
type CustomSearchFuzzyOptions = SearchFuzzyOptions & {
  categorySet: string //categorySet=1111111,22222,444
}

const SEARCH_ADDRESS = 'searchAddress'
const SEARCH_FUZZY = 'searchFuzzy'

const customSearchFuzzyOptions: CustomSearchFuzzyOptions = {
  countrySet: ['GB'],
  categorySet: '9942002',
  'api-version': '1.0',
  'subscription-key': process.env.REACT_APP_AZURE_MAPS_KEY!,
  language: 'en-GB'
}

const {
  countrySet,
  categorySet,
  'api-version': apiVersion,
  'subscription-key': subscriptionKey,
  language
} = customSearchFuzzyOptions

const queryParams = `api-version=${apiVersion}&countrySet=${
  countrySet![0]
}&subscription-key=${subscriptionKey}&language=${language}`

export const searchFuzzy = (query: string, userGeolocation: UserGeolocation) =>
  fetch(
    `${searchURL.mapsUrl}/search/fuzzy/json?${
      userGeolocation ? `lon=${userGeolocation.longitude}&lat=${userGeolocation.latitude}&` : ''
    }query=${encodeURI(`${query}, UK`)}&${queryParams}`
  )

export const searchReverse = (
  positon: GeoJSON.Position
): Promise<azureMapsRest.SearchAddressReverseResponse> =>
  searchURL.searchAddressReverse(azureMapsRest.Aborter.timeout(10000), positon)

export const useSearchFuzzy = (initialQuery: string) => {
  return useQuery(
    [SEARCH_FUZZY, initialQuery],
    async ({ queryKey }): Promise<azureMapsRest.SearchFuzzyResponse> => {
      const [_, query] = queryKey

      const userGeolocation = getUserGeolocation()
      const response = await searchFuzzy(query, userGeolocation)

      const body: azureMapsRest.SearchFuzzyResponse = await response.json()
      return body
    },
    {
      refetchOnWindowFocus: false
    }
  )
}

export const useSearchAddress = (initialQuery: string) => {
  return useQuery(
    [SEARCH_ADDRESS, initialQuery],
    async ({ queryKey }): Promise<azureMapsRest.SearchAddressResponse> => {
      const [_, query] = queryKey

      const response = await searchURL.searchAddress(
        azureMapsRest.Aborter.timeout(10000),
        `${query}, UK`,
        {
          countrySet: ['GB'],
          language: 'en-GB'
        }
      )
      return response
    },
    {
      refetchOnWindowFocus: false
    }
  )
}
