import React, { FC, useState } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ButtonGroup from '@mui/material/ButtonGroup'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import AddIcon from '@mui/icons-material/Add'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

import { useSnackbar } from 'notistack'

import VehicleForm, { VehicleTransformSchema } from '../components/vehicle/VehicleForm'
import ConfirmationDialog from '../components/UI/ConfirmationDialog'
import Search from '../components/UI/Search'
import TableWrapper from '../components/UI/TableWrapper'

import { useAddVehicle, useVehicles, useUpdateVehicle, useRemoveVehicle } from '../queries/vehicles'
import { useUserMe } from '../queries/user'
import { useTableData } from '../hooks/useTableData'
import {
    Vehicle as VehicleModel,
    UpdateVehiclePayload,
    RemoveVehiclePayload
} from '../models/vehicle'
import { COLORS } from '../theme'

type TableData = { totalPages: number; slicedData: VehicleModel[]; totalItems: number }

const Vehicle: FC = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { status, data: vehicles, isRefetching } = useVehicles()
    const { data: userMe } = useUserMe()
    const [isUpdating, setIsUpdating] = useState(false)
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [editVehicle, setEditVehicle] = useState<null | VehicleModel>(null)
    const [removeVehicle, setRemoveVehicle] = useState<null | VehicleModel>(null)

    const [search, setSearch] = useState('')
    const { totalPages, slicedData, totalItems }: TableData = useTableData({
        search,
        data: vehicles,
        keysToSearch: ['name']
    })

    const { mutateAsync: addVehicleAsync } = useAddVehicle()
    const { mutateAsync: updateVehicleAsync } = useUpdateVehicle()
    const { mutateAsync: removeVehicleAsync } = useRemoveVehicle()

    const onSave = async (vehicle: VehicleTransformSchema, id?: number) => {
        setIsUpdating(true)
        try {
            const payload = {
                ...vehicle,
                type: vehicle.name,
                license: '',
                customerId: 0,
                partnerId: 0,
                partnerName: userMe?.partner || '',
                customerName: userMe?.customer || ''
            }
            if (id) {
                const updateVehiclePayload: UpdateVehiclePayload = { ...payload, id }
                await updateVehicleAsync(updateVehiclePayload)
            } else {
                await addVehicleAsync(payload)
            }
            enqueueSnackbar(`Vehicle has been successfully ${id ? 'updated' : 'added'}.`, {
                variant: 'success'
            })
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    const onRemoveConfirm = async () => {
        setIsUpdating(true)
        try {
            const removeVehiclePayload: RemoveVehiclePayload = {
                id: removeVehicle!.id,
                type: removeVehicle!.type,
                name: removeVehicle!.name,
                customerName: removeVehicle!.customerName,
                license: removeVehicle!.license || '',
                partnerName: userMe?.partner || '',
                customerId: 0,
                partnerId: 0
            }
            await removeVehicleAsync(removeVehiclePayload)
            enqueueSnackbar(`Vehicle has been successfully removed.`, {
                variant: 'success'
            })
            setRemoveVehicle(null)
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    return (
        <>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Search title='Vehicles' onChangeSearch={(data: string) => setSearch(data.toLowerCase())} />
                <Grid padding={'16px 12px'}>
                    <Button
                        sx={{ background: userMe?.customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399' }}
                        onClick={() => setIsFormOpen(true)} variant='contained' startIcon={<AddIcon />}>
                        Add new vehicle
                    </Button>
                </Grid>
            </Grid>
            <TableWrapper
                isSuccess={status === 'success'}
                emptyResultTitle='No vehicles found'
                totalPages={totalPages}
                totalItems={totalItems}
                isUpdating={isUpdating || isRefetching}
            >
                <>
                    <TableHead>
                        <TableRow sx={{ verticalAlign: 'bottom' }}>
                            <TableCell width='25%' align='left'>
                                <Typography variant='subheadlineBold' color='secondary'>
                                    Name
                                </Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='subheadlineBold' color='secondary'>
                                    Dimensions
                                </Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='subheadlineBold' color='secondary'>
                                    Actions
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {slicedData.map((vehicle, i) => (
                            <TableRow
                                key={vehicle.id}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    height: '56px',
                                    verticalAlign: 'middle'
                                }}
                            >
                                <TableCell component='th' scope='row' size='small'>
                                    <Typography>{vehicle.name}</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Grid container alignItems='center'>
                                        <Typography>Height: {vehicle.height}</Typography>
                                        <Typography marginX='24px' variant='body'>
                                            |
                                        </Typography>
                                        <Typography>Length: {vehicle.length}</Typography>

                                        <Typography marginX='24px' variant='body'>
                                            |
                                        </Typography>
                                        <Typography>Width: {vehicle.width}</Typography>

                                        <Typography marginX='24px' variant='body'>
                                            |
                                        </Typography>
                                        <Typography>Weight: {vehicle.weight}</Typography>
                                    </Grid>
                                </TableCell>
                                <TableCell align='left'>
                                    <ButtonGroup aria-label='outlined primary button group'>
                                        <IconButton
                                            color='primary'
                                            onClick={() => {
                                                setEditVehicle(vehicle)
                                                setIsFormOpen(true)
                                            }}
                                            disabled={isUpdating || isRefetching}
                                        >
                                            <ModeEditOutlineOutlinedIcon />
                                        </IconButton>
                                        <IconButton
                                            color='error'
                                            onClick={() => setRemoveVehicle(vehicle)}
                                            disabled={isUpdating || isRefetching}
                                        >
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </>
            </TableWrapper>

            <VehicleForm
                customer={userMe?.customer}
                open={isFormOpen}
                onClose={() => {
                    setEditVehicle(null)
                    setIsFormOpen(false)
                }}
                onSave={onSave}
                vehicle={editVehicle}
            />
            <ConfirmationDialog
                customer={userMe?.customer}
                open={!!removeVehicle}
                onClose={() => setRemoveVehicle(null)}
                onConfirm={onRemoveConfirm}
            />
        </>
    )
}

export default Vehicle
