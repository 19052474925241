import * as azureMapsControl from 'azure-maps-control'
import { Waypoint } from '../models/route'

export const createMapInstance = (mapContainer: string | HTMLElement) =>
  new azureMapsControl.Map(mapContainer, {
    language: 'en-GB',
    preserveDrawingBuffer: true,
    // Replace <Your Azure Maps Key> with your Azure Maps primary subscription key. https://aka.ms/am-primaryKey
    authOptions: {
      authType: azureMapsControl.AuthenticationType.subscriptionKey,
      subscriptionKey: process.env.REACT_APP_AZURE_MAPS_KEY!
    }
  })

export const createDatasourceInstance = (
  ...arg: ConstructorParameters<typeof azureMapsControl.source.DataSource>
) => new azureMapsControl.source.DataSource(...arg)

export const LONDON_BBOX = [-1.170593, 51.177872, 0.915436, 51.822445]

export const getNearestRouteIdx = (
  position: Waypoint['point'] | azureMapsControl.data.Position,
  routePath: (Waypoint['point'] | azureMapsControl.data.Position)[]
) => {
  const { routeIdx } = routePath.reduce(
    (acc, point, index) => {
      const { maxDistance } = acc
      const d = azureMapsControl.math.getDistanceTo(position, point)
      if (d < maxDistance) {
        acc.routeIdx = index
        acc.maxDistance = d
      }
      return { ...acc }
    },
    { maxDistance: Infinity, routeIdx: 0 }
  )
  return routeIdx
}
