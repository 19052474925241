import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { COLORS } from '../../theme'

interface ConfirmationDialogProps {
    customer: string | undefined
    title?: string
    open: boolean
    onConfirm: () => void
    onClose: () => void
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
    customer,
    title = 'Are you sure you want to remove?',
    onClose,
    onConfirm,
    open
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{ '& .MuiPaper-root': { minWidth: '670px', height: '305px' } }}
        >
            <Grid
                container
                justifyContent='space-around'
                direction='column'
                alignItems='center'
                height='100%'
            >
                <DialogTitle textAlign='center' mt='32px' mb='8px' width='400px' variant='title1'>
                    <Typography variant='title1Bold'>{title}</Typography>
                </DialogTitle>
                <Box sx={{ padding: '0 24px 48px 24px', textAlign: 'center' }}>
                    <DialogActions sx={{ padding: 'unset' }} disableSpacing>
                        <Button sx={{ width: '50%' }} variant='outlined' onClick={onClose}>
                            Cancel
                        </Button>
                        <Box width='16px'></Box>
                        <Button sx={{
                            width: '50%',
                            background: customer === "Go Ahead London" ?
                                COLORS.MAIN_BLUE : '#667399'
                        }} variant='contained' onClick={onConfirm} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Box>
            </Grid>
        </Dialog>
    )
}

export default ConfirmationDialog
