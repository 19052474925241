import * as z from 'zod'
import * as azureMapsRest from 'azure-maps-rest'

export enum HAZARD_WAYPOINT_TYPE {
  START = 'START',
  END = 'END',
  EXTRA = 'EXTRA'
}

export const HazardWaypointSchema = z.object({
  name: z.string(),
  point: z.array(z.number()).length(2),
  type: z.nativeEnum(HAZARD_WAYPOINT_TYPE)
})

export type HazardWaypoint = Omit<ReturnType<typeof HazardWaypointSchema['parse']>, 'point'> & {
  point: [number, number] //[lon, lat]
}

export interface HazardDBModel {
  id: number
  createdBy?: string
  created: string
  updatedBy?: string
  updated: string
  name: string
  description: string
  enabled: boolean
  customerName: string
  customerId: number
  partnerName: string
  partnerId: number
  waypoints: string
  routeGeoJson: string
}

export type Hazard = Omit<HazardDBModel, 'waypoints' | 'routeGeoJson'> & {
  waypoints: HazardWaypoint[]
  routeGeoJson: ReturnType<azureMapsRest.RouteGeojson['getFeatures']>
}

export type ToggleHazardPayload = Pick<
  HazardDBModel,
  'enabled' | 'id' | 'name' | 'description' | 'routeGeoJson' | 'waypoints'
>

export type AddHazardPayload = Pick<HazardDBModel, 'name' | 'description' | 'waypoints' | 'routeGeoJson'>

export type UpdateHazardPayload = AddHazardPayload & Pick<HazardDBModel, 'id'>

export type RemoveHazardPayload = Pick<HazardDBModel, 'id' | 'name' | 'description' | 'routeGeoJson' | 'waypoints'>
