import React, { FC, useMemo, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'

import { useLocalStorage } from 'usehooks-ts'

import CircularProgress from '../components/UI/CircularProgress'
import { drawerWidth, closedDrawerWidth, toolbarHeight } from '../layouts/General'
import OverviewMap from '../components/route/RouteOverview/OverviewMap'
import { formWidth } from './RouteAddEdit'

import { useRoute } from '../queries/routes'
import { Route, Waypoint, WAYPOINT_TYPE } from '../models/route'
import { COLORS } from '../theme'
import { StartWaypointIcon } from '../assets/icons/StartWaypointIcon'
import { EndWaypointIcon } from '../assets/icons/EndWaypointIcon'
import { useGoBack } from '../hooks/useGoBack'

type WaypointView = Waypoint & {
  label: [string, string]
}

type RouteViewAggregation = Route & {
  startWaypoint: WaypointView
  endWaypoint: WaypointView
  intermediateWaypoints: WaypointView[]
}

const circleStyles: React.CSSProperties = {
  background: COLORS.MAIN_BLUE,
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  textAlign: 'center',
  minWidth: '24px'
}

const RouteOverview: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()
  const { status, data: route, isFetching, refetch } = useRoute(id)
  const [isLeftBarOpen] = useLocalStorage('OPEN_LEFT_BAR', false)
  const { backPath } = useGoBack()

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  const routeViewAggregation = useMemo(() => {
    if (!route) return null
    const { waypoints, ...rest } = route

    const intermediateWaypoints = waypoints.slice(1, -1).map<WaypointView>((waypoint) => {
      const waypointAfterSplit = waypoint.name.split(',')
      const label: WaypointView['label'] = [
        waypointAfterSplit[0],
        waypointAfterSplit.slice(1).join(',')
      ]
      return { label, ...waypoint }
    })

    const firstWaypoint = waypoints[0]
    const lastWaypoint = waypoints.at(-1)!

    const firstWaypointAfterSplit = firstWaypoint.name.split(',')
    const startWaypointLabel: WaypointView['label'] = [
      firstWaypointAfterSplit[0],
      firstWaypointAfterSplit.slice(1).join(',')
    ]

    const lastWaypointAfterSplit = lastWaypoint.name.split(',')
    const endWaypointLabel: WaypointView['label'] = [
      lastWaypointAfterSplit[0],
      lastWaypointAfterSplit.slice(1).join(',')
    ]

    const routeViewAggregation: RouteViewAggregation = {
      ...rest,
      waypoints,
      startWaypoint: { ...firstWaypoint, label: startWaypointLabel },
      endWaypoint: { ...lastWaypoint, label: endWaypointLabel },
      intermediateWaypoints
    }

    return routeViewAggregation
  }, [route])

  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          background: 'white',
          width: `calc(100% - ${isLeftBarOpen ? drawerWidth : closedDrawerWidth}px - 16px)`,
          marginTop: '-16px',
          right: 0,
          top: `${toolbarHeight + 16}px`,
          zIndex: 100,
          position: 'fixed',
          padding: '16px 12px',
          transition: 'top 1s easy'
        }}
      >
        <Grid
          item
          container
          direction='row'
          justifyContent='start'
          alignItems='center'
          width='auto'
        >
          <IconButton color='secondary' onClick={() => navigate(backPath)}>
            <ArrowBackOutlinedIcon />
          </IconButton>
          <Typography variant='title1Bold' mr='24px' ml='20px'>
            Route overview
          </Typography>
        </Grid>
        <Grid item container direction='row' justifyContent='end' alignItems='center' width='auto'>
          <Button
            onClick={() => navigate(`/routes/${id}/edit`, { state: location?.state })}
            variant='contained'
            startIcon={<ModeEditOutlineOutlinedIcon />}
          >
            Edit route
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        height='100%'
        padding={'8px 12px 0 12px'}
        flexDirection='row'
        marginTop={'72px'}
      >
        {status === 'success' && !!routeViewAggregation && (
          <>
            <Grid
              container
              flexDirection='column'
              alignItems='start'
              justifyContent='start'
              p='12px 16px 16px 16px'
              sx={{
                width: `${formWidth}px`,
                background: COLORS.LIGHT_GRAY
              }}
            >
              <Grid container justifyContent='start' alignItems='flex-start' direction='column'>
                <Typography variant='bodyBold' color='secondary' mb='16px'>
                  General info
                </Typography>
                <Typography variant='title1Bold' color='primary'>
                  {route.routeName}
                </Typography>
                <Typography mt='8px' variant='bodyBold' color='secondary'>
                  {route.description}
                </Typography>

                <Grid
                  container
                  justifyContent='start'
                  alignItems='center'
                  direction='row'
                  mt='12px'
                >
                  <AirportShuttleIcon
                    fontSize='large'
                    sx={{
                      padding: '5px',
                      background: COLORS.MAIN_BLUE,
                      color: COLORS.WHITE,
                      borderRadius: '4px'
                    }}
                  />
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='flex-start'
                    direction='row'
                    ml='16px'
                    width='calc(100% - 60px)'
                  >
                    {routeViewAggregation.vehicles.map(({ type, id }, index, arr) => (
                      <Typography variant='textBold' key={id}>
                        {type}
                        {arr.length > 1 && index + 1 !== arr.length && <>,&nbsp;</>}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              <Box width='100%' height='1px' sx={{ background: COLORS.BORDER_GRAY, my: '16px' }} />

              <Grid container justifyContent='start' alignItems='flex-start' direction='column'>
                <Typography variant='bodyBold' color='secondary' mb='16px'>
                  Route info
                </Typography>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='flex-start'
                  direction='row'
                  mt='12px'
                  whiteSpace='normal'
                  wrap='nowrap'
                >
                  <StartWaypointIcon fill={COLORS.MAIN_BLUE} />
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='flex-start'
                    direction='column'
                    ml='16px'
                    width='auto'
                  >
                    <Typography variant='textBold'>
                      {routeViewAggregation.startWaypoint.label[0]}
                    </Typography>
                    <Typography mt='2px' variant='text' color='secondary'>
                      {routeViewAggregation.startWaypoint.label[1]}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent='start'
                  alignItems='flex-start'
                  direction='row'
                  mt='12px'
                  whiteSpace='normal'
                  wrap='nowrap'
                >
                  <EndWaypointIcon fill={COLORS.MAIN_BLUE} />
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='flex-start'
                    direction='column'
                    ml='16px'
                    width='auto'
                  >
                    <Typography variant='textBold'>
                      {routeViewAggregation.endWaypoint.label[0]}
                    </Typography>
                    <Typography mt='2px' variant='text' color='secondary'>
                      {routeViewAggregation.endWaypoint.label[1]}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Box width='100%' height='1px' sx={{ background: COLORS.BORDER_GRAY, my: '16px' }} />

              <Grid container justifyContent='start' alignItems='flex-start' direction='column'>
                <Typography variant='bodyBold' color='secondary' mb='16px'>
                  Stops
                </Typography>
                <Grid container justifyContent='start' alignItems='start' direction='column'>
                  {routeViewAggregation.intermediateWaypoints
                    .filter(({ type }) => type === WAYPOINT_TYPE.STOP)
                    .map(({ label }, index) => (
                      <Grid
                        key={label[0]}
                        container
                        justifyContent='start'
                        alignItems='flex-start'
                        direction='row'
                        mb='12px'
                        mr='32px'
                        whiteSpace='normal'
                        wrap='nowrap'
                        width='300px'
                      >
                        <Box sx={circleStyles}>
                          <Typography color='white' variant='subheadlineBold'>
                            {index + 1}
                          </Typography>
                        </Box>
                        <Grid
                          container
                          justifyContent='start'
                          alignItems='flex-start'
                          direction='column'
                          ml='16px'
                          width='auto'
                        >
                          <Typography variant='textBold'>{label[0]}</Typography>
                          <Typography mt='2px' variant='text' color='secondary'>
                            {label[1]}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{
                width: `calc(100% - ${
                  isLeftBarOpen ? drawerWidth : closedDrawerWidth
                }px - ${formWidth}px - 48px)`,
                height: `calc(100vh - ${toolbarHeight}px - 121px)`,
                right: '16px',
                position: 'fixed'
              }}
            >
              <OverviewMap
                waypoints={route?.waypoints ?? null}
                routeGeoJson={route?.routeGeoJson ?? null}
              />
            </Grid>
          </>
        )}
        {status === 'loading' && isFetching && (
          <Grid container height='100%' alignItems='center' justifyContent='center'>
            <CircularProgress
              sx={{ marginBottom: '150px' }}
              size={70}
              thickness={5}
              disableShrink
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default RouteOverview
