import React, { FC, useState, useMemo } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'

import { OperatorLookup } from '../lookups/OperatorLookup'
import { UserFile } from './UserFileUploader'
import { COLORS } from '../../theme'
import { useUserMe } from '../../queries/user'

type Props = {
    open: boolean
    handleClose: () => void
    onUpload: (customer: string) => void
    list: Partial<UserFile>[]
}
type Field = Partial<keyof UserFile>
const fields: Field[] = ['name', 'surname', 'email', 'mobile', 'phone', 'nfc', 'admin']

type TextCellProps = { error?: string; value: string }
const TextCell: FC<TextCellProps> = ({ error, value }) => (
    <TableCell size='small' sx={{ maxWidth: '200px', wordWrap: 'break-word' }}>
        <Typography color={error ? 'error' : 'text'} fontSize='14px'>
            {error || value}
        </Typography>
    </TableCell>
)

const UploadedFilesList: FC<Props> = ({ open, handleClose, list, onUpload }) => {
    const [operator, setOperator] = useState<string | null>(null)
    const { data: userMe } = useUserMe()

    const error = useMemo(() => list.filter((item) => item.errors).length, [list])
    const valid = useMemo(() => list.length - error, [list])

    const conclusion = useMemo(() => {
        if (error && valid) {
            return (
                <>
                    <div>{`There are ${valid} valid and ${error} error users`}</div>
                    <div>You can add all valid users or upload a new file</div>
                </>
            )
        }
        return error ? (
            <>
                <div> {`There are ${error} error users`}</div>
                <div> Please fix issues and try again</div>
            </>
        ) : (
            <>
                <div> {`There are ${valid} valid users`}</div>
                <div>Please select the operator name</div>
            </>
        )
    }, [list])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{ '& .MuiPaper-root': { maxWidth: '1000px', minWidth: '700px' } }}
        >
            <IconButton onClick={handleClose} sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                <CloseIcon fontSize='small' />
            </IconButton>
            <DialogTitle m='32px 0 8px 16px' variant='title1'>
                <Grid container justifyContent='space-between'>
                    {!list.length ? (
                        <Grid>
                            <Typography variant='subheadlineBold'>
                                File is epty. Please, select another one
                            </Typography>
                        </Grid>
                    ) : (
                        <>
                            <Grid item width='50%'>
                                <Typography variant='subheadlineBold'>{conclusion}</Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogTitle>

            {!list.length ? (
                ''
            ) : (
                <DialogContent>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    <Typography variant='subheadlineBold' color='secondary' fontSize='14px'>
                                        №
                                    </Typography>
                                </TableCell>
                                {fields.map((label) => (
                                    <TableCell align='left' key={label}>
                                        <Typography variant='subheadlineBold' color='secondary' fontSize='14px'>
                                            {label}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((item, i) => {
                                const { name, surname, mobile, phone, email, admin, errors, nfc } = item

                                return (
                                    <TableRow key={i}>
                                        <TextCell value={`${i + 1}`} error='' />
                                        <TextCell value={name || ''} error={errors?.name} />
                                        <TextCell value={surname || ''} error={errors?.surname} />
                                        <TextCell value={email || ''} error={errors?.email} />
                                        <TextCell value={mobile || ''} error={errors?.mobile} />
                                        <TextCell value={phone || ''} error={errors?.phone} />
                                        <TextCell
                                            value={errors?.nfc || String(nfc || 'false').toLowerCase() || ''}
                                            error={errors?.nfc}
                                        />
                                        <TextCell
                                            value={errors?.admin || String(admin || 'false').toLowerCase() || ''}
                                            error={errors?.admin}
                                        />
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
            )}

            <Grid
                sx={{ padding: '24px 24px 48px 24px' }}
                container
                justifyContent={'space-between'}
                spacing={2}
            >
                <Grid item width={'50%'}>
                    <OperatorLookup
                        textFieldProps={{ disabled: !list.length, size: 'small' }}
                        value={operator || ''}
                        onChange={(data) => setOperator(data)}
                    />
                </Grid>
                <Grid item>
                    <Button
                        sx={{
                            background: userMe?.customer === "Go Ahead London" ?
                                COLORS.MAIN_BLUE : '#667399'
                        }}
                        variant='contained'
                        disabled={!!(error && !valid) || !operator || !list.length}
                        onClick={() => onUpload(operator!)}
                    >
                        {error && valid ? 'Save only valid users' : 'Save'}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default UploadedFilesList
