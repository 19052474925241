import React, { FC, useEffect, useMemo } from 'react'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import HistoryIcon from '@mui/icons-material/History'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { format, parseISO } from 'date-fns'

import CircularProgress from '../UI/CircularProgress'

import { COLORS } from '../../theme'
import { useRouteHistoryLatest } from '../../queries/tracking'
import { WAYPOINT_TYPE } from '../../models/route'

const circleStyles: React.CSSProperties = {
  background: COLORS.MAIN_BLUE,
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  textAlign: 'center',
  minWidth: '24px'
}

type WaypointLabel = [string, string]

type DeviceProps = {
  opened: boolean
  setOpen: (imei: string) => void
  imei: string
  rcv: string
  searchQuery: string
  visible: boolean
  onShow: (imei: string, state: boolean) => void
  deviceName: string
}

const Device: FC<DeviceProps> = ({
  opened = false,
  setOpen,
  imei,
  rcv,
  searchQuery,
  visible,
  onShow,
  deviceName
}) => {
  const { data: routeHistory, status } = useRouteHistoryLatest(imei)

  const startWaypointLabel = useMemo(() => {
    if (routeHistory) {
      const startWaypoint = routeHistory.route.waypoints.find(
        ({ type }) => type === WAYPOINT_TYPE.START
      )
      if (startWaypoint) {
        const { name } = startWaypoint
        const startWaypointAfterSplit = name.split(',')
        const startWaypointLabel: WaypointLabel = [
          startWaypointAfterSplit[0],
          startWaypointAfterSplit.slice(1).join(',')
        ]
        return startWaypointLabel
      }
      return ['Empty', 'Empty']
    }
    return ['Empty', 'Empty']
  }, [routeHistory])

  const endWaypointLabel = useMemo(() => {
    if (routeHistory) {
      const endWaypoint = routeHistory.route.waypoints.find(
        ({ type }) => type === WAYPOINT_TYPE.END
      )
      if (endWaypoint) {
        const { name } = endWaypoint
        const endWaypointAfterSplit = name.split(',')
        const endWaypointLabel: WaypointLabel = [
          endWaypointAfterSplit[0],
          endWaypointAfterSplit.slice(1).join(',')
        ]
        return endWaypointLabel
      }
      return ['Empty', 'Empty']
    }
    return ['Empty', 'Empty']
  }, [routeHistory])

  const datetime = useMemo(() => {
    const date = new Date(rcv)
    const isoDate = date.toISOString()
    return format(
      parseISO(`${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`),
      'dd/MM/yyyy HH:mm:ss'
    )
  }, [rcv])

  useEffect(() => {
    if (!searchQuery) {
      onShow(imei, true)
      return
    }
    if (routeHistory) {
      const {
        route: { routeName, routeNumber },
        driver,
        dutyNumber
      } = routeHistory
      onShow(
        imei,
        (!!imei && imei.toLowerCase().includes(searchQuery)) ||
          (!!routeName && routeName.toLowerCase().includes(searchQuery)) ||
          (!!driver && driver.toLowerCase().includes(searchQuery)) ||
          (!!dutyNumber && dutyNumber.toLowerCase().includes(searchQuery)) ||
          (!!routeNumber && routeNumber.toLowerCase().includes(searchQuery)) ||
          (!!deviceName && deviceName.toLowerCase().includes(searchQuery))
      )
      return
    }
    onShow(
      imei,
      imei.toLowerCase().includes(searchQuery) ||
        (!!deviceName && deviceName.toLowerCase().includes(searchQuery))
    )
  }, [searchQuery, imei, routeHistory])

  if (!visible) {
    return <></>
  }

  return (
    <Grid
      container
      sx={{
        borderRadius: '8px',
        background: COLORS.LIGHT_GRAY,
        border: `solid 1px ${opened ? COLORS.MAIN_BLUE : COLORS.LIGHT_BLUE_1}`,
        padding: '12px 16px',
        mt: '8px'
      }}
    >
      {status === 'loading' && <CircularProgress sx={{ margin: '0 auto' }} />}
      {status === 'success' && !routeHistory && (
        <Grid container direction='column'>
          <Typography variant='title3Bold' color='primary'>
            {"The device is offline or hasn't been used yet."}
          </Typography>
          <Typography variant='subheadline'>Last seen: {datetime}</Typography>
          <Typography variant='subheadline'>
            Imei: {imei}
            <IconButton color='primary' onClick={() => setOpen(imei)}>
              {opened ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
            </IconButton>
          </Typography>
        </Grid>
      )}
      {status === 'success' && routeHistory && (
        <>
          <Box sx={{ cursor: 'pointer' }} onClick={() => setOpen(imei)}>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='start'
              wrap='nowrap'
            >
              <Typography variant='title3Bold' color='primary'>
                {imei} - {routeHistory.route.routeName}
              </Typography>
              {opened ? (
                <ExpandLessOutlinedIcon color='secondary' />
              ) : (
                <ExpandMoreOutlinedIcon color='secondary' />
              )}
            </Grid>
            <Box
              sx={{
                borderRadius: '4px',
                px: '10px',
                border: `1px solid ${COLORS.MAIN_GRAY}`,
                mt: '8px',
                width: 'fit-content',
                background: COLORS.WHITE
              }}
            >
              <Typography variant='subheadline'>{routeHistory.route.description}</Typography>
            </Box>
          </Box>
          <Collapse component='div' in={opened} timeout='auto'>
            <Box width='100%' height='1px' sx={{ background: COLORS.BORDER_GRAY, my: '16px' }} />

            <Grid container justifyContent='start' alignItems='center' direction='row'>
              <AirportShuttleOutlinedIcon
                fontSize='large'
                sx={{
                  padding: '5px',
                  background: COLORS.WHITE,
                  color: COLORS.MAIN_GRAY,
                  borderRadius: '4px',
                  border: `solid 1px ${COLORS.BORDER_GRAY}`
                }}
              />
              <Grid
                container
                justifyContent='start'
                alignItems='flex-start'
                direction='column'
                ml='8px'
                width='auto'
              >
                {routeHistory.vehicleName && (
                  <Typography variant='subheadline'>{routeHistory.vehicleName}</Typography>
                )}
                {!routeHistory.vehicleName && (
                  <Typography variant='subheadline'>No vehicle type</Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent='start'
              alignItems='center'
              direction='row'
              mt='8px'
              wrap='nowrap'
            >
              <PersonPinOutlinedIcon
                fontSize='large'
                sx={{
                  padding: '5px',
                  background: COLORS.WHITE,
                  color: COLORS.MAIN_GRAY,
                  borderRadius: '4px',
                  border: `solid 1px ${COLORS.BORDER_GRAY}`
                }}
              />
              <Typography ml='8px' variant='subheadline' sx={{ wordBreak: 'break-all' }}>
                {routeHistory.driver}
              </Typography>
            </Grid>
            <Grid container justifyContent='start' alignItems='center' direction='row' mt='8px'>
              <BallotOutlinedIcon
                fontSize='large'
                sx={{
                  padding: '5px',
                  background: COLORS.WHITE,
                  color: COLORS.MAIN_GRAY,
                  borderRadius: '4px',
                  border: `solid 1px ${COLORS.BORDER_GRAY}`
                }}
              />
              <Typography ml='8px' variant='subheadline'>
                {routeHistory.dutyNumber}
              </Typography>
            </Grid>
            <Grid container justifyContent='start' alignItems='center' direction='row' mt='8px'>
              <HistoryIcon
                fontSize='large'
                sx={{
                  padding: '5px',
                  background: COLORS.WHITE,
                  color: COLORS.MAIN_GRAY,
                  borderRadius: '4px',
                  border: `solid 1px ${COLORS.BORDER_GRAY}`
                }}
              />

              <Typography ml='8px' variant='subheadline'>
                {datetime}
              </Typography>
            </Grid>

            <Box width='100%' height='1px' sx={{ background: COLORS.BORDER_GRAY, my: '16px' }} />

            <Grid
              container
              justifyContent='start'
              alignItems='flex-start'
              direction='row'
              whiteSpace='normal'
              wrap='nowrap'
            >
              <Box sx={circleStyles}>
                <Typography color='white' variant='captionBold'>
                  A
                </Typography>
              </Box>
              <Grid
                container
                justifyContent='start'
                alignItems='flex-start'
                direction='column'
                ml='16px'
                width='auto'
              >
                <Typography variant='text'>{startWaypointLabel[0]}</Typography>
                <Typography mt='4px' variant='subheadline' color='secondary'>
                  {startWaypointLabel[1]}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent='start'
              alignItems='flex-start'
              direction='row'
              whiteSpace='normal'
              wrap='nowrap'
              mt='8px'
            >
              <Box sx={circleStyles}>
                <Typography color='white' variant='captionBold'>
                  B
                </Typography>
              </Box>
              <Grid
                container
                justifyContent='start'
                alignItems='flex-start'
                direction='column'
                ml='16px'
                width='auto'
              >
                <Typography variant='text'>{endWaypointLabel[0]}</Typography>
                <Typography mt='4px' variant='subheadline' color='secondary'>
                  {endWaypointLabel[1]}
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </>
      )}
    </Grid>
  )
}

export default Device
