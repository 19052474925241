import React from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useUserMe } from './user'
import {
  DeviceGroupe,
  NegotiationResponse,
  RouteHistory,
  RouteHistoryDBModel,
  TrackingMessage
} from '../models/tracking'

export const DEVICE_GROUP_ID = 'deviceGroupId'
export const FAST_TRACKING_INFO = 'fastTrackingInfo'
export const NEGOTIATE = 'negotiate'
export const ROUTE_HISTORY = 'routeHistory'

export const useDevireGroup = () => {
  const { data: userMe } = useUserMe()
  return useQuery(
    [DEVICE_GROUP_ID],
    async (): Promise<DeviceGroupe> => {
      const { data } = await axios.get<DeviceGroupe[]>(
        `${process.env.REACT_APP_AF_API_URL}/devicegroup?${userMe?.options}`
      )

      if (data[0] && data[0].Id) {
        return data[0]
      } else {
        throw new Error('The device group id does not exist')
      }
    },
    {
      enabled: !!userMe?.options
    }
  )
}

export const useFastTrackingInfoLatest = (deviceGroupId: number | undefined) => {
  const { data: userMe } = useUserMe()
  return useQuery(
    [FAST_TRACKING_INFO],
    async (): Promise<TrackingMessage[]> => {
      const { data } = await axios.get<TrackingMessage[]>(
        `${process.env.REACT_APP_AF_API_URL}/mongotrackinginfo/latest?${userMe?.options}&deviceGroupId=${deviceGroupId}`
      )
      return data
    },
    {
      enabled: false,
      refetchOnWindowFocus: false
    }
  )
}

export const useNegotiate = () => {
  const { data: userMe } = useUserMe()
  return useQuery(
    [NEGOTIATE],
    async (): Promise<NegotiationResponse> => {
      const response = await axios.get<NegotiationResponse>(
        `${process.env.REACT_APP_TRACKING_ACCESS_URL}`
      )
      return response.data
    },
    {
      enabled: !!userMe?.options,
      refetchInterval: 3000 * 1000,
      refetchOnWindowFocus: false
    }
  )
}

export const useRouteHistoryLatest = (imei: string | undefined) => {
  const { data: userMe } = useUserMe()
  return useQuery(
    [ROUTE_HISTORY, imei],
    async ({ queryKey }): Promise<RouteHistory | null> => {
      const [_, query] = queryKey

      const response = await axios.get<RouteHistoryDBModel>(
        `${process.env.REACT_APP_ROUTING_API_URL}/RouteHistory/latest/${query}?${userMe?.options}`
      )

      if (response.status === 204 && response.statusText === 'No Content') {
        return null
      }

      const { data } = response
      if (data) {
        const {
          route: { waypoints, routeGeoJson, ...restRoute },
          ...restRouteHistory
        } = data

        const parsedJSONRoute: RouteHistory = {
          ...restRouteHistory,
          route: {
            ...restRoute,
            waypoints: JSON.parse(waypoints),
            routeGeoJson: JSON.parse(routeGeoJson)
          }
        }
        return parsedJSONRoute
      } else {
        throw new Error('The route history does not exist')
      }
    },
    {
      enabled: !!userMe?.options
    }
  )
}
