import React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import {
  Vehicle,
  AddVehiclePayload,
  UpdateVehiclePayload,
  RemoveVehiclePayload
} from '../models/vehicle'
import { useUserMe } from './user'

const VEHICLES = 'vehicles'
const STALE = 20 * 60 * 1000

export const useVehicles = () => {
  const { data: userMe } = useUserMe()

  return useQuery(
    [VEHICLES],
    async (): Promise<Vehicle[]> => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROUTING_API_URL}/Vehicle?${userMe?.options}`
      )
      return data.reverse()
    },
    {
      enabled: !!userMe?.customer,
      initialDataUpdatedAt: Date.now(),
      staleTime: STALE
    }
  )
}

export const useUpdateVehicle = () => {
  const queryClient = useQueryClient()
  const { data: userMe } = useUserMe()

  return useMutation(
    async (updateVehiclePayload: UpdateVehiclePayload) => {
      const { data } = await axios.put(`${process.env.REACT_APP_ROUTING_API_URL}/Vehicle`, {
        ...updateVehiclePayload,
        partnerName: userMe?.partner,
        customerName: userMe?.customer,
        partnerId: 0,
        customerId: 0
      })
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData([VEHICLES], (oldData?: Vehicle[]) =>
          oldData?.map((item) => (item.id === id ? data : item))
        )
      }
    }
  )
}

export const useAddVehicle = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (addVehiclePayload: AddVehiclePayload) => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ROUTING_API_URL}/Vehicle`,
        addVehiclePayload
      )
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData([VEHICLES], (oldData?: Vehicle[]) => oldData && [data, ...oldData])
      }
    }
  )
}

export const useRemoveVehicle = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (removeVehiclePayload: RemoveVehiclePayload) => {
      const { data } = await axios.delete(`${process.env.REACT_APP_ROUTING_API_URL}/Vehicle`, {
        data: removeVehiclePayload
      })
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData(
          [VEHICLES],
          (oldData?: Vehicle[]) => oldData && [...oldData.filter((item) => item.id !== id)]
        )
      }
    }
  )
}
