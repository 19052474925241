import React, { FC } from 'react'
import StepLabelMUI from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'

import { COLORS } from '../../../theme'

const circleStyles: React.CSSProperties = {
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  textAlign: 'center',
  minWidth: '32px',
  paddingTop: '4px'
}

type StepLabelProps = {
  closed: boolean
  filled: boolean
  title: string
  number: number
}

const StepLabel: FC<StepLabelProps> = ({ filled, title, number, closed }) => (
  <StepLabelMUI
    StepIconComponent={() => (
      <Box
        sx={{
          ...circleStyles,
          [filled ? 'background' : 'border']: filled
            ? COLORS.MAIN_BLUE
            : `1px solid ${COLORS.MAIN_BLUE}`
        }}
      >
        {closed ? (
          <CheckIcon htmlColor='white' />
        ) : (
          <Typography color={filled ? 'white' : 'primary'} variant='bodyBold'>
            {number}
          </Typography>
        )}
      </Box>
    )}
  >
    <Typography ml={'4px'} variant='bodyBold' color={filled ? 'primary' : 'secondary'}>
      {title}
    </Typography>
  </StepLabelMUI>
)

export default StepLabel
