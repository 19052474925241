import * as azureMapsSpatialIO from '../../../lib/atlas-spatial'
import * as azureMapsRest from 'azure-maps-rest'
import * as azureMapsControl from 'azure-maps-control'
import * as z from 'zod'
import { namesCheck, nameRegex, str, textCheck } from '../../../assets/constans/formValidation'
import { Waypoint, WaypointSchema } from '../../../models/route'
import { Vehicle, VehicleSchema } from '../../../models/vehicle'

export const waypointFormFieldSchema = WaypointSchema.strict()
  .nullable()
  .transform((value, ctx) => {
    if (value === null) {
      ctx.addIssue({
        code: 'custom',
        message: 'Required'
      })
    }
    return value
  })

export const routeFormSchema = z.object({
  routeName: namesCheck,
  description: textCheck.max(255, 'No more 255 characters').or(str.length(0)),
  routeNumber: str
    .min(1, 'At least 1 character')
    .regex(nameRegex, 'Invalid characters')
    .max(30, 'No more 30 characters'),
  vehicles: z.array(VehicleSchema).min(1, 'The vehicles must contain at least 1 element(s)'),
  startWaypoint: waypointFormFieldSchema,
  endWaypoint: waypointFormFieldSchema,
  intermediateWaypoints: z.array(waypointFormFieldSchema)
})

export const keys = routeFormSchema.keyof().Values

export type RouteFormSchema = Pick<
  ReturnType<typeof routeFormSchema['parse']>,
  'description' | 'routeName' | 'vehicles' | 'routeNumber'
> & {
  startWaypoint: Waypoint | null
  endWaypoint: Waypoint | null
  intermediateWaypoints: (Waypoint | null)[]
}

export type VehicleCharacteristicsForRoute = Pick<Vehicle, 'height' | 'length' | 'weight' | 'width'>

export const defaultValuesRouteForm: RouteFormSchema = {
  routeName: '',
  description: '',
  routeNumber: '',
  vehicles: [],
  startWaypoint: null,
  endWaypoint: null,
  intermediateWaypoints: []
}

export type RouteGeoJson = {
  previewRouteGeoJson:
    | azureMapsControl.data.Feature<
        azureMapsControl.data.LineString,
        azureMapsSpatialIO.SimpleStyleProperties
      >
    | ReturnType<azureMapsRest.RouteGeojson['getFeatures']>
    | null
  currentRouteGeoJson:
    | azureMapsControl.data.Feature<
        azureMapsControl.data.LineString,
        azureMapsSpatialIO.SimpleStyleProperties
      >
    | ReturnType<azureMapsRest.RouteGeojson['getFeatures']>
    | null
  routePath: azureMapsControl.data.Position[]
}
