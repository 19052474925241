import * as azureMapsRest from 'azure-maps-rest'

const subscriptionKey = process.env.REACT_APP_AZURE_MAPS_KEY!

// Use SubscriptionKeyCredential with a subscription key.
const subscriptionKeyCredential = new azureMapsRest.SubscriptionKeyCredential(subscriptionKey)

// Use subscriptionKeyCredential to create a pipeline.
export const pipeline = azureMapsRest.MapsURL.newPipeline(subscriptionKeyCredential, {
  retryOptions: { maxTries: 4 } // Retry options
})
