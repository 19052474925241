import React from 'react'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'

import App from './App'

import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import './index.css'
import CloseSnackbarAction from './components/UI/CloseSnackbarAction'


const Auth0ProviderWithRedirectCallback = ({ children, ...props }: Auth0ProviderOptions) => {
  const navigate = useNavigate()
  const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname)
  }
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  )
}
const queryClient = new QueryClient()
document.title = `Bus routing${location.pathname.replace(/^\/(\w)|^\//, (e, g) =>
  g ? ` - ${g.toUpperCase()}` : ''
)}`

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
          redirectUri={`${location.origin}/tracking`}
          useRefreshTokens={true}
          cacheLocation={'localstorage'}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
        >
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={5000}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              action={(key) => <CloseSnackbarAction id={key} />}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
