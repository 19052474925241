import React, { FC, useEffect, useMemo } from 'react'
import Autocomplete from '@mui/material/Autocomplete/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SearchIcon from '@mui/icons-material/Search'
import { useOperator } from '../../queries/operator'

type Props = {
  value?: string
  onChange: (value: string | null) => void
  textFieldProps?: TextFieldProps
}

export const OperatorLookup: FC<Props> = ({ value, onChange, textFieldProps }) => {
  const { data: operators } = useOperator()

  const names = useMemo(() => {
    let res: string[] = []
    if (operators) {
      res = operators.map(({ customerName }) => customerName).sort()
    }
    return res
  }, [operators])

  useEffect(() => {
    if (!value && names.length) {
      onChange(names[0])
    }
  }, [value, names])

  return (
    <Autocomplete
      value={value}
      onChange={(e, value, reason) => {
        if (reason !== 'clear') {
          onChange(value)
        }
      }}
      options={names.map(String)}
      loading={!names.length}
      disabled={textFieldProps?.disabled || !names.length}
      clearIcon={<HighlightOffIcon />}
      isOptionEqualToValue={(option, value) => option === value || value === ''}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          label='Operator name'
          InputProps={{
            ...params.InputProps,
            endAdornment: !names.length ? (
              <CircularProgress size={20} />
            ) : (
              params.InputProps.endAdornment
            ),
            startAdornment: names.length > 1 && !textFieldProps?.disabled && <SearchIcon />
          }}
        />
      )}
    />
  )
}
