import React, {
    FC,
    useState,
    useCallback,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useMemo
} from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import Collapse from '@mui/material/Collapse'
import CheckIcon from '@mui/icons-material/Check'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import SyncProblemIcon from '@mui/icons-material/SyncProblem'

import * as azureMapsSpatialIO from '../../../lib/atlas-spatial'
import * as azureMapsRest from 'azure-maps-rest'
import * as azureMapsControl from 'azure-maps-control'

import uniqid from 'uniqid'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFieldArray, useForm, useWatch, FormProvider } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import ConfirmationDialog from '../../UI/ConfirmationDialog'
import CircularProgress from '../../UI/CircularProgress'
import FileUploadArea, {
    STEPS as FILE_UPLOAD_AREA_STEPS,
    CustomFile
} from '../../UI/FileUploadArea'
import AddEditRouteFormUI, { RouteInList, RoutesList } from '../RouteAddEdit/AddEditRouteForm'
import AddEditMap, { AddEditMapProps } from '../RouteAddEdit/AddEditMap'
import StepLabel from './StepLabel'
import Vehicles from '../RouteOverview/Vehicles'
import StartOrEndWaypoint from '../RouteOverview/StartOrEndWaypoint'
import Stop from '../RouteOverview/Stop'
import OverviewMap from '../RouteOverview/OverviewMap'

import { AddRoutePayload, Waypoint, WAYPOINT_TYPE } from '../../../models/route'
import { useAddRoutes } from '../../../queries/routes'
import { Actions } from './actionsModel'
import { pipeline } from '../../../setupPipeline'
import { COLORS } from '../../../theme'

import {
    defaultValuesRouteForm,
    keys,
    routeFormSchema,
    RouteFormSchema,
    RouteGeoJson,
    VehicleCharacteristicsForRoute
} from '../RouteAddEdit/routeFormModel'
import { useUserMe } from '../../../queries/user'

const routeURL = new azureMapsRest.RouteURL(pipeline)

enum STEPS {
    UPLOAD_FILE,
    EDIT_ALL_ROUTES,
    REVIEW_AND_SAVE
}

const DIALOG_SIZE = {
    [STEPS.UPLOAD_FILE]: {
        minHeight: '400px',
        height: '400px'
    },
    [STEPS.EDIT_ALL_ROUTES]: {
        minHeight: '95vh',
        height: '80%'
    },
    [STEPS.REVIEW_AND_SAVE]: {
        minHeight: '95vh',
        height: '80%'
    }
}

const RoutesUploadModal: FC<PropsWithChildren> = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [activeStep, setActiveStep] = useState<STEPS>(STEPS.UPLOAD_FILE)
    const [open, setOpen] = useState(false)
    const { data: userMe } = useUserMe()

    const [routes, setRoutes] = useState<RoutesList>({})
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpenConfirmationDialog(true)
    }

    const clearDialogState = () => {
        setActiveStep(STEPS.UPLOAD_FILE)
        setFileUploadAreaStep(FILE_UPLOAD_AREA_STEPS.AWAITING_DROP_OR_UPLOAD)
        setFile(null)
        setErrorText(null)
        setRoutes({})
        setSelectedRouteId(null)
        reset(defaultValuesRouteForm)
    }

    const onConfirmCloseDialog = () => {
        setOpenConfirmationDialog(false)
        setOpen(false)
        clearDialogState()
    }

    //The UPLOAD_FILE step
    const [fileUploadAreaStep, setFileUploadAreaStep] = useState<FILE_UPLOAD_AREA_STEPS>(
        FILE_UPLOAD_AREA_STEPS.AWAITING_DROP_OR_UPLOAD
    )

    const [file, setFile] = useState<CustomFile | null>(null)
    const [errorText, setErrorText] = useState<string | null>(null)

    useEffect(() => {
        if (fileUploadAreaStep === FILE_UPLOAD_AREA_STEPS.START_READING) {
            setErrorText(null)
        }
    }, [fileUploadAreaStep])

    const save = async () => {
        setErrorText(null)
        if (file && file.arrayBuffer) {
            const { features, bbox }: azureMapsSpatialIO.SpatialDataSet =
                await azureMapsSpatialIO.io.read(file.arrayBuffer)

            const routesList = features
                .map<null | { startIndex: number; endIndex: number }>((feature, index) => {
                    if (feature.geometry.type === 'LineString') {
                        const lastLineStringBeforeCurrentIndex = features
                            .slice(0, index)
                            //@ts-ignore
                            .findLastIndex((f) => f.geometry.type === 'LineString')
                        return {
                            startIndex:
                                lastLineStringBeforeCurrentIndex === -1 ? 0 : lastLineStringBeforeCurrentIndex + 1,
                            endIndex: index
                        }
                    } else return null
                })
                .filter((v): v is { startIndex: number; endIndex: number } => !!v)
                .map(({ startIndex, endIndex }) => features.slice(startIndex, endIndex + 1))
                .reduce((acc: RoutesList, routeFeatures) => {
                    const pointFeatures = routeFeatures.filter(
                        (
                            feature
                        ): feature is azureMapsControl.data.Feature<
                            azureMapsControl.data.Point,
                            azureMapsSpatialIO.SimpleStyleProperties
                        > => feature.geometry.type === 'Point'
                    )
                    if (pointFeatures.length < 2) return acc

                    const startWaypoint: Waypoint = {
                        name: pointFeatures[0]?.properties?.name ?? 'Noname Location',
                        point: [
                            pointFeatures[0].geometry.coordinates[0],
                            pointFeatures[0].geometry.coordinates[1]
                        ],
                        type: WAYPOINT_TYPE.START
                    }

                    const endWaypoint: Waypoint = {
                        name: pointFeatures.at(-1)?.properties?.name ?? 'Noname Location',
                        point: [
                            pointFeatures.at(-1)!.geometry.coordinates[0],
                            pointFeatures.at(-1)!.geometry.coordinates[1]
                        ],
                        type: WAYPOINT_TYPE.END
                    }

                    const stops = pointFeatures
                        .filter((v, index) => !(index === 0 || index === pointFeatures.length - 1))
                        .map<Waypoint>((feature) => {
                            const point: Waypoint['point'] = [
                                feature.geometry.coordinates[0],
                                feature.geometry.coordinates[1]
                            ]
                            const name = feature?.properties?.name ?? 'Noname Location'
                            return {
                                name,
                                point,
                                type: WAYPOINT_TYPE.STOP
                            }
                        })

                    const lineStringFeature = routeFeatures.find(
                        (
                            feature
                        ): feature is azureMapsControl.data.Feature<
                            azureMapsControl.data.LineString,
                            azureMapsSpatialIO.SimpleStyleProperties
                        > => feature.geometry.type === 'LineString'
                    )

                    const id = uniqid()
                    const route: RouteInList = {
                        routeName: 'Untitled',
                        routeNumber: '',
                        startWaypoint,
                        endWaypoint,
                        intermediateWaypoints: stops,
                        description: '',
                        vehicles: [],
                        routePath: [],
                        confirmed: false,
                        previewRouteGeoJson: null,
                        currentRouteGeoJson: null
                    }

                    if (lineStringFeature) {
                        route.previewRouteGeoJson = lineStringFeature
                        route.currentRouteGeoJson = lineStringFeature
                        route.routePath = lineStringFeature.geometry.coordinates

                        if (
                            lineStringFeature &&
                            lineStringFeature.properties &&
                            lineStringFeature.properties.name
                        ) {
                            route.routeName = lineStringFeature.properties.name
                        }
                    }

                    acc[id] = route
                    return acc
                }, {})

            if (!Object.keys(routesList).length) {
                setErrorText('The routes file is empty.')
                return
            }

            setRoutes(routesList)
            setActiveStep(STEPS.EDIT_ALL_ROUTES)
        } else {
            setErrorText('The routes file is empty.')
        }
    }
    //The UPLOAD_FILE step

    //The EDIT_ALL_ROUTES step
    const [selectedRouteId, setSelectedRouteId] = useState<string | null>(null)
    const [countActiveMapActions, setCountActiveMapActions] = useState<number>(0) // if we have more than 0 active action we should show a linear loader

    const methods = useForm<RouteFormSchema>({
        defaultValues: defaultValuesRouteForm,
        resolver: zodResolver(routeFormSchema)
    })

    const {
        handleSubmit,
        setValue,
        reset,
        control,
        getValues,
        formState: { isDirty }
    } = methods

    const arrayMethods = useFieldArray({
        control,
        name: keys.intermediateWaypoints
    })

    const { remove, insert, update } = arrayMethods

    const intermediateWaypoints = useWatch({ control, name: keys.intermediateWaypoints })
    const startWaypoint = useWatch({ control, name: keys.startWaypoint })
    const endWaypoint = useWatch({ control, name: keys.endWaypoint })
    const vehicles = useWatch({ control, name: keys.vehicles })

    const vehicleCharacteristicsForRoute: VehicleCharacteristicsForRoute = useMemo(() => {
        if (!selectedRouteId || !vehicles) {
            return { weight: 0, width: 0, height: 0, length: 0 }
        }
        const {
            maxWeight: weight,
            maxWidth: width,
            maxHeight: height,
            maxLength: length
        } = vehicles.reduce(
            (acc, vehicle) => {
                if (!vehicle) return acc
                const { weight, width, height, length } = vehicle
                if (weight && acc.maxWeight < weight) {
                    acc.maxWeight = weight
                }
                if (width && acc.maxWidth < width) {
                    acc.maxWidth = width
                }
                if (height && acc.maxHeight < height) {
                    acc.maxHeight = height
                }
                if (length && acc.maxLength < length) {
                    acc.maxLength = length
                }
                return acc
            },
            { maxWeight: 0, maxWidth: 0, maxHeight: 0, maxLength: 0 }
        )

        return {
            weight,
            width,
            height,
            length
        }
    }, [vehicles, selectedRouteId])

    useEffect(() => {
        if (isDirty && selectedRouteId && routes[selectedRouteId].confirmed) {
            setRoutes((prev) => ({
                ...prev,
                [selectedRouteId]: { ...prev[selectedRouteId], confirmed: false }
            }))
        }
    }, [isDirty])

    useEffect(() => {
        if (Array.isArray(intermediateWaypoints) && selectedRouteId) {
            const waypoints = [startWaypoint, ...intermediateWaypoints, endWaypoint].filter(
                (v): v is Waypoint => !!v
            )
            calculateRouteDirections(waypoints, vehicleCharacteristicsForRoute, selectedRouteId)
        }
    }, [
        startWaypoint,
        endWaypoint,
        intermediateWaypoints,
        vehicleCharacteristicsForRoute,
        selectedRouteId
    ])

    useEffect(() => {
        if (selectedRouteId) {
            const {
                routeName,
                routeNumber,
                description,
                vehicles,
                startWaypoint,
                endWaypoint,
                intermediateWaypoints
            } = routes[selectedRouteId]
            reset({
                routeName,
                routeNumber,
                description,
                vehicles,
                startWaypoint,
                endWaypoint,
                intermediateWaypoints
            })
        } else {
            reset(defaultValuesRouteForm)
        }
    }, [selectedRouteId])

    const mapRouteProps = useMemo(() => {
        if (selectedRouteId && routes[selectedRouteId] && Array.isArray(intermediateWaypoints)) {
            const { previewRouteGeoJson, currentRouteGeoJson, routePath } = routes[selectedRouteId]

            const props: Omit<AddEditMapProps, 'countActiveMapActions' | 'actionMapResolver'> = {
                previewRouteGeoJson,
                currentRouteGeoJson,
                routePath: [...routePath],
                waypoints: [startWaypoint, ...intermediateWaypoints, endWaypoint].filter(
                    (v): v is Waypoint => !!v
                )
            }

            return props
        }
        return null
    }, [selectedRouteId, routes, startWaypoint, endWaypoint, intermediateWaypoints])

    const allRoutesConfirmed = useMemo(
        () => Object.values(routes).every(({ confirmed }) => confirmed),
        [routes]
    )

    const actionMapResolver = useCallback(
        (action: Actions) => {
            switch (action.type) {
                case 'StartActionActionType': {
                    setCountActiveMapActions((count) => count + 1)
                    break
                }
                case 'EndActionActionType': {
                    setCountActiveMapActions((count) => count - 1)
                    break
                }
                case 'UpdateStopActionType': {
                    const { intermediateWaypointIndex, waypoint } = action.payload
                    update(intermediateWaypointIndex, waypoint)
                    break
                }
                case 'UpdateStartActionType': {
                    const { waypoint } = action.payload
                    setValue(keys.startWaypoint, waypoint)
                    break
                }
                case 'UpdateEndActionType': {
                    const { waypoint } = action.payload
                    setValue(keys.endWaypoint, waypoint)
                    break
                }
                case 'UpdateExtraActionType': {
                    const { intermediateWaypointIndex, waypoint } = action.payload
                    update(intermediateWaypointIndex, waypoint)
                    break
                }
                case 'RemoveStartActionType': {
                    setValue(keys.startWaypoint, null)
                    break
                }
                case 'RemoveEndActionType': {
                    setValue(keys.endWaypoint, null)
                    break
                }
                case 'RemoveExtraActionType': {
                    const { intermediateWaypointIndex } = action.payload
                    remove(intermediateWaypointIndex)
                    break
                }
                case 'RemoveStopActionType': {
                    const { intermediateWaypointIndex } = action.payload
                    remove(intermediateWaypointIndex)
                    break
                }
                case 'InsertExtraActionType': {
                    const { intermediateWaypointIndex, waypoint } = action.payload
                    insert(intermediateWaypointIndex, waypoint)
                    break
                }
            }
        },
        [setCountActiveMapActions, insert, remove, update, setValue]
    )

    const calculateRouteDirections = async (
        waypoints: Waypoint[],
        { width, length, height, weight }: VehicleCharacteristicsForRoute,
        id: string
    ) => {
        if (!selectedRouteId) return
        setCountActiveMapActions((count) => count + 1)
        try {
            const response = await routeURL.calculateRouteDirections(
                azureMapsRest.Aborter.timeout(10000),
                waypoints.map(({ point }) => point),
                {
                    vehicleWidth: width ? width : undefined,
                    vehicleLength: length ? length : undefined,
                    vehicleHeight: height ? height : undefined,
                    vehicleWeight: weight ? weight : undefined,
                    instructionsType: azureMapsRest.Models.RouteInstructionsType.Text,
                    travelMode: azureMapsRest.Models.TravelMode.Bus
                }
            )

            const data = response.geojson.getFeatures()

            const routePath = data.features[0].geometry.coordinates.flatMap(
                (partRouteByLine: number[][]) => partRouteByLine
            )

            const instructions: azureMapsRest.Models.RouteResultInstruction[] =
                data.features[0].properties!.guidance.instructions

            const instructionsPointIndexForRemovingAsExtraWaypoint = instructions
                .filter(
                    ({ instructionType }) =>
                        instructionType === azureMapsRest.Models.GuidanceInstructionType.LOCATIONWAYPOINT
                )
                //The pointIndex is in response, but not in the model of RouteResultInstruction
                //@ts-ignore
                .map<number | null>(({ pointIndex }, index) => {
                    const onlyIntermediateWaypoint = waypoints.filter(
                        ({ type }) => type === WAYPOINT_TYPE.EXTRA || type === WAYPOINT_TYPE.STOP
                    )
                    const intermediateWaypoint = onlyIntermediateWaypoint[index]
                    return intermediateWaypoint && intermediateWaypoint.type === WAYPOINT_TYPE.EXTRA
                        ? pointIndex
                        : null
                })
                .filter((v): v is number => !!v)

            const instructionsWithoutExtraWaypointAsStop = instructions.filter(
                //The pointInfex is in response, but not in the model of RouteResultInstruction
                //@ts-ignore
                ({ pointIndex, instructionType }) =>
                    !(
                        instructionType === azureMapsRest.Models.GuidanceInstructionType.LOCATIONWAYPOINT &&
                        instructionsPointIndexForRemovingAsExtraWaypoint.some(
                            //The pointInfex is in response, but not in the model of RouteResultInstruction
                            //@ts-ignore
                            (pointIndexRemoving) => pointIndexRemoving === pointIndex
                        )
                    )
            )

            data.features[0].properties!.guidance.instructions = instructionsWithoutExtraWaypointAsStop

            const routeGeoJson: RouteGeoJson = {
                previewRouteGeoJson: routes[id].previewRouteGeoJson,
                currentRouteGeoJson: data,
                routePath
            }

            setRoutes((prev) => ({ ...prev, [id]: { ...prev[id], ...routeGeoJson } }))
            setCountActiveMapActions((count) => count - 1)
        } catch {
            setCountActiveMapActions((count) => count - 1)
        }
    }

    const selectRoute = (routeId: string) => {
        if (selectedRouteId) {
            if (selectedRouteId === routeId) {
                const formState = getValues()
                setRoutes((prev) => ({
                    ...prev,
                    [selectedRouteId]: { ...prev[selectedRouteId], ...formState }
                }))
                setSelectedRouteId(null)
            } else {
                const formState = getValues()
                setRoutes((prev) => ({
                    ...prev,
                    [selectedRouteId]: { ...prev[selectedRouteId], ...formState }
                }))
                setSelectedRouteId(routeId)
            }
        } else {
            setSelectedRouteId(routeId)
        }
    }

    const deleteRouteFromList = (id: string) => {
        selectRoute(id)
        const dif: RoutesList = { ...routes }
        delete dif[id]

        if (!Object.keys(dif).length) {
            clearDialogState()
        } else {
            setTimeout(() => {
                setRoutes(() => ({
                    ...dif
                }))
            }, 1000)
        }
    }

    const onConfirmRoute = handleSubmit(async (routeForm: NonNullable<RouteFormSchema>) => {
        if (selectedRouteId) {
            const formState = getValues()
            setRoutes((prev) => ({
                ...prev,
                [selectedRouteId]: { ...prev[selectedRouteId], ...formState, confirmed: true }
            }))
            setSelectedRouteId(null)
        }
    })

    //The EDIT_ALL_ROUTES step

    //The REVIEW_AND_SAVE step
    const [isRoutesSending, setIsRoutesSending] = useState<boolean>(false)

    const routesViewAggregationMap = useMemo(() => {
        if (activeStep === STEPS.REVIEW_AND_SAVE) {
            return Object.entries(routes).reduce(
                (
                    acc: Record<
                        string,
                        Pick<RouteFormSchema, 'routeName' | 'routeNumber' | 'description' | 'vehicles'> & {
                            intermediateWaypointLabels: [string, string][]
                            endWaypointLabel: [string, string]
                            startWaypointLabel: [string, string]
                        }
                    >,
                    [routeId, route]
                ) => {
                    const {
                        routeName,
                        routeNumber,
                        description,
                        startWaypoint,
                        endWaypoint,
                        vehicles,
                        intermediateWaypoints
                    } = route

                    const startWaypointAfterSplit = startWaypoint!.name.split(',')
                    const startWaypointLabel: [string, string] = [
                        startWaypointAfterSplit[0],
                        startWaypointAfterSplit.slice(1).join(',')
                    ]

                    const endWaypointAfterSplit = endWaypoint!.name.split(',')
                    const endWaypointLabel: [string, string] = [
                        endWaypointAfterSplit[0],
                        endWaypointAfterSplit.slice(1).join(',')
                    ]

                    const intermediateWaypointLabels = intermediateWaypoints
                        .filter((v): v is Waypoint => !!v)
                        .filter((v) => v.type === WAYPOINT_TYPE.STOP)
                        .map<[string, string]>((waypoint) => {
                            const waypointAfterSplit = waypoint.name.split(',')
                            const label: [string, string] = [
                                waypointAfterSplit[0],
                                waypointAfterSplit.slice(1).join(',')
                            ]
                            return label
                        })

                    acc[routeId] = {
                        routeName,
                        routeNumber,
                        description,
                        intermediateWaypointLabels,
                        endWaypointLabel,
                        startWaypointLabel,
                        vehicles
                    }
                    return acc
                },
                {}
            )
        }
        return {}
    }, [routes, activeStep])

    const { mutateAsync: addRoutesAsync } = useAddRoutes()

    const sendRoutes = async () => {
        setIsRoutesSending(true)
        try {
            const routesForCreating = Object.values(routes).map<AddRoutePayload>(
                ({
                    routeName,
                    description,
                    routeNumber,
                    vehicles,
                    startWaypoint,
                    endWaypoint,
                    intermediateWaypoints,
                    currentRouteGeoJson
                }) => {
                    const route: AddRoutePayload = {
                        routeName,
                        description,
                        routeNumber,
                        vehicles,
                        waypoints: JSON.stringify([startWaypoint, ...intermediateWaypoints, endWaypoint]),
                        routeGeoJson: JSON.stringify(currentRouteGeoJson),
                        imageUri: ''
                    }
                    return route
                }
            )
            const { successfull, failed } = await addRoutesAsync(routesForCreating)
            setIsRoutesSending(false)
            setOpen(false)
            clearDialogState()
            let message = `You successfully added ${successfull.length} routes`
            if (successfull.length && !failed.length) {
                enqueueSnackbar(message, { variant: 'success' })
            } else if (successfull.length && failed.length) {
                message += `, but ${failed.length} were failed for some reasons`
                enqueueSnackbar(message, { variant: 'info' })
            } else if (!successfull.length && failed.length) {
                enqueueSnackbar(
                    `Adding of ${failed.length} routes were failed for some reasons. Try to change data`,
                    {
                        variant: 'error'
                    }
                )
            }
        } catch {
            setIsRoutesSending(false)
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
    }
    //The REVIEW_AND_SAVE step

    return (
        <>
            {React.cloneElement(children as ReactElement, { onClick: handleOpen })}
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: '1176px',
                        width: '100%',
                        maxWidth: '85vw',
                        maxHeight: '80%',
                        ...DIALOG_SIZE[activeStep]
                    }
                }}
            >
                <DialogTitle textAlign={'center'} mt='32px' mb='8px' variant='title1'>
                    <Typography variant='title1Bold'>Route file upload</Typography>
                </DialogTitle>
                <Grid></Grid>
                <Stepper nonLinear activeStep={activeStep} sx={{ width: '80%', margin: '0 auto' }}>
                    <Step>
                        <StepLabel
                            filled={activeStep >= STEPS.UPLOAD_FILE}
                            closed={activeStep > STEPS.UPLOAD_FILE}
                            title='Upload a file'
                            number={1}
                        />
                    </Step>
                    <Step>
                        <StepLabel
                            filled={activeStep >= STEPS.EDIT_ALL_ROUTES}
                            closed={activeStep > STEPS.EDIT_ALL_ROUTES}
                            title='Edit all routes'
                            number={2}
                        />
                    </Step>
                    <Step>
                        <StepLabel
                            filled={activeStep >= STEPS.REVIEW_AND_SAVE}
                            closed={activeStep > STEPS.REVIEW_AND_SAVE}
                            title='Review and save'
                            number={3}
                        />
                    </Step>
                </Stepper>
                {activeStep === STEPS.UPLOAD_FILE && (
                    <Grid
                        container
                        direction='column'
                        justifyContent='space-around'
                        mt='16px'
                        px='24px'
                        width='100%'
                        wrap='nowrap'
                        height='100%'
                    >
                        <FileUploadArea
                            accept={{ 'application/vnd.google-earth.kmz': ['.kmz'] }}
                            onStepChange={(step: FILE_UPLOAD_AREA_STEPS) => setFileUploadAreaStep(step)}
                            onLoad={(file) => setFile(file)}
                            onError={(errorText) => setErrorText(errorText)}
                        />
                        <Box sx={{ padding: '4px 24px 24px 24px', textAlign: 'center' }}>
                            {fileUploadAreaStep === FILE_UPLOAD_AREA_STEPS.FINISH_READING && (
                                <DialogActions sx={{ padding: 'unset', justifyContent: 'center' }} disableSpacing>
                                    <Button variant='outlined' onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Box width='16px'></Box>
                                    <Button
                                        sx={{
                                            background: userMe?.customer === "Go Ahead London" ?
                                                COLORS.MAIN_BLUE : '#667399'
                                        }}
                                        disabled={
                                            fileUploadAreaStep !== FILE_UPLOAD_AREA_STEPS.FINISH_READING || !!errorText
                                        }
                                        variant='contained'
                                        onClick={save}
                                        autoFocus
                                    >
                                        Next
                                    </Button>
                                </DialogActions>
                            )}
                            {!!errorText && (
                                <Typography variant='subheadline' color='error'>
                                    {errorText}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                )}
                {activeStep === STEPS.EDIT_ALL_ROUTES && (
                    <Grid
                        container
                        justifyContent='start'
                        alignItems='start'
                        mt='16px'
                        px='24px'
                        height='calc(100% - 154px)'
                        wrap='nowrap'
                    >
                        <Grid
                            item
                            xs={4}
                            container
                            sx={{ overflow: 'auto', height: '100%' }}
                            direction='column'
                            justifyContent='start'
                            wrap='nowrap'
                        >
                            <FormProvider {...methods} {...arrayMethods}>
                                {Object.entries(routes).map(([routeId, route]) => (
                                    <Box
                                        key={route.routeName}
                                        width='100%'
                                        sx={{
                                            background: COLORS.LIGHT_GRAY,
                                            p: '20px 15px',
                                            mb: '8px',
                                            border: `solid 1px ${route.confirmed
                                                ? COLORS.GREEN
                                                : routeId === selectedRouteId
                                                    ? COLORS.MAIN_BLUE
                                                    : COLORS.GRAY
                                                }`,
                                            borderRadius: '8px'
                                        }}
                                    >
                                        <>
                                            <Box sx={{ cursor: 'pointer' }} onClick={() => selectRoute(routeId)}>
                                                <Grid
                                                    container
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    alignItems='start'
                                                    wrap='nowrap'
                                                >
                                                    <Grid container alignItems='start' justifyContent='start'>
                                                        {route.confirmed ? (
                                                            <CheckCircleOutlineIcon color='success' sx={{ mr: '8px' }} />
                                                        ) : (
                                                            <SyncProblemIcon color='secondary' sx={{ mr: '8px' }} />
                                                        )}

                                                        <Typography variant='title3Bold' color='primary'>
                                                            {route.routeName}
                                                        </Typography>
                                                    </Grid>

                                                    {routeId === selectedRouteId ? (
                                                        <ExpandLessOutlinedIcon color='secondary' />
                                                    ) : (
                                                        <ExpandMoreOutlinedIcon color='secondary' />
                                                    )}
                                                </Grid>
                                            </Box>
                                            <Collapse component='div' in={routeId === selectedRouteId} timeout='auto'>
                                                <AddEditRouteFormUI />
                                                <Grid
                                                    container
                                                    direction='column'
                                                    justifyContent='start'
                                                    alignItems='center'
                                                    mt='12px'
                                                >
                                                    <Button
                                                        sx={{
                                                            background: userMe?.customer === "Go Ahead London" ?
                                                                COLORS.MAIN_BLUE : '#667399'
                                                        }}
                                                        variant='contained'
                                                        startIcon={<CheckIcon />}
                                                        fullWidth
                                                        onClick={onConfirmRoute}
                                                    >
                                                        Confirm route
                                                    </Button>
                                                    <Button
                                                        variant='text'
                                                        color='error'
                                                        fullWidth
                                                        sx={{ mt: '12px' }}
                                                        onClick={() => deleteRouteFromList(routeId)}
                                                    >
                                                        Delete route
                                                    </Button>
                                                </Grid>
                                            </Collapse>
                                        </>
                                    </Box>
                                ))}
                            </FormProvider>
                        </Grid>

                        <Grid item xs={8} ml='16px' height='calc(100% - 88px)'>
                            <AddEditMap
                                waypoints={mapRouteProps?.waypoints ?? null}
                                previewRouteGeoJson={mapRouteProps?.previewRouteGeoJson ?? null}
                                currentRouteGeoJson={mapRouteProps?.currentRouteGeoJson ?? null}
                                routePath={mapRouteProps?.routePath ?? []}
                                countActiveMapActions={countActiveMapActions}
                                actionMapResolver={actionMapResolver}
                            />
                            <Grid
                                container
                                justifyContent='space-between'
                                alignItems='center'
                                height='72px'
                                mt='16px'
                            >
                                <Grid
                                    container
                                    alignItems='center'
                                    justifyContent='start'
                                    width='unset'
                                    alignSelf='start'
                                >
                                    <ErrorOutlineOutlinedIcon color='secondary' sx={{ mr: '8px' }} />

                                    <Typography variant='caption' color='secondary'>
                                        You need to check all routes in this file
                                    </Typography>
                                </Grid>

                                <Button
                                    sx={{
                                        background: userMe?.customer === "Go Ahead London" ?
                                            COLORS.MAIN_BLUE : '#667399'
                                    }}
                                    disabled={!allRoutesConfirmed}
                                    variant='contained'
                                    onClick={() => setActiveStep(STEPS.REVIEW_AND_SAVE)}
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {activeStep === STEPS.REVIEW_AND_SAVE && (
                    <Grid
                        container
                        justifyContent='start'
                        alignItems='start'
                        mt='16px'
                        px='24px'
                        height='calc(100% - 154px)'
                        wrap='nowrap'
                    >
                        <Grid
                            item
                            xs={4}
                            container
                            sx={{ overflow: 'auto', height: '100%' }}
                            direction='column'
                            justifyContent='start'
                            wrap='nowrap'
                        >
                            {Object.entries(routesViewAggregationMap).map(([routeId, route]) => (
                                <Box
                                    key={route.routeName}
                                    width='100%'
                                    sx={{
                                        background: COLORS.LIGHT_GRAY,
                                        p: '20px 15px',
                                        mb: '8px',
                                        border: `solid 1px ${selectedRouteId === routeId ? COLORS.MAIN_BLUE : COLORS.LIGHT_BLUE_2
                                            }`,
                                        borderRadius: '8px'
                                    }}
                                >
                                    <>
                                        <Box sx={{ cursor: 'pointer' }} onClick={() => selectRoute(routeId)}>
                                            <Grid
                                                container
                                                direction='row'
                                                justifyContent='start'
                                                alignItems='start'
                                                wrap='nowrap'
                                            >
                                                {routeId === selectedRouteId ? (
                                                    <ExpandLessOutlinedIcon color='secondary' />
                                                ) : (
                                                    <ExpandMoreOutlinedIcon color='secondary' />
                                                )}

                                                <Typography variant='title3Bold' color='primary' ml='14px'>
                                                    {route.routeName}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                container
                                                justifyContent='start'
                                                alignItems='flex-start'
                                                direction='column'
                                            >
                                                <Box
                                                    width='100%'
                                                    height='1px'
                                                    sx={{ background: COLORS.BORDER_GRAY, my: '16px' }}
                                                />

                                                {route.description && (
                                                    <Typography variant='bodyBold' color='secondary'>
                                                        {route.description}
                                                    </Typography>
                                                )}

                                                {route.routeNumber && (
                                                    <Typography mt='8px' variant='bodyBold' color='secondary'>
                                                        {route.routeNumber}
                                                    </Typography>
                                                )}

                                                <Vehicles vehicles={route.vehicles} />

                                                <Box
                                                    width='100%'
                                                    height='1px'
                                                    sx={{ background: COLORS.BORDER_GRAY, my: '16px' }}
                                                />

                                                <Grid
                                                    container
                                                    justifyContent='start'
                                                    alignItems='flex-start'
                                                    direction='column'
                                                >
                                                    <StartOrEndWaypoint type='A' label={route.startWaypointLabel} />
                                                    <Box width='100%' height='1px' sx={{ my: '4px' }} />
                                                    <StartOrEndWaypoint type='B' label={route.endWaypointLabel} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Collapse component='div' in={routeId === selectedRouteId} timeout='auto'>
                                            <Box
                                                width='100%'
                                                height='1px'
                                                sx={{ background: COLORS.BORDER_GRAY, mt: '16px', mb: '25px' }}
                                            />

                                            {route.intermediateWaypointLabels.map((label, index) => (
                                                <Stop number={index + 1} label={label} key={`${label[0]}_${label[1]}`} />
                                            ))}
                                        </Collapse>
                                    </>
                                </Box>
                            ))}
                        </Grid>

                        <Grid item xs={8} ml='16px' height='calc(100% - 88px)'>
                            <OverviewMap
                                waypoints={mapRouteProps?.waypoints ?? null}
                                routeGeoJson={mapRouteProps?.currentRouteGeoJson ?? null}
                            />

                            <Grid container justifyContent='end' alignItems='center' height='72px' mt='16px'>
                                <Button
                                    variant='outlined'
                                    onClick={() => setActiveStep(STEPS.EDIT_ALL_ROUTES)}
                                    sx={{ mr: '16px' }}
                                    disabled={isRoutesSending}
                                >
                                    Back to edit
                                </Button>

                                <Button
                                    sx={{
                                        background: userMe?.customer === "Go Ahead London" ?
                                            COLORS.MAIN_BLUE : '#667399'
                                    }}
                                    disabled={isRoutesSending}
                                    startIcon={<CheckIcon />}
                                    variant='contained'
                                    onClick={sendRoutes}
                                >
                                    {isRoutesSending ? <CircularProgress size={25} /> : 'Save all routes'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <ConfirmationDialog
                    customer={userMe?.customer}
                    open={openConfirmationDialog}
                    onClose={() => setOpenConfirmationDialog(false)}
                    onConfirm={onConfirmCloseDialog}
                    title='Are you sure you want to close the upload dialog window?'
                />
            </Dialog>
        </>
    )
}

export default RoutesUploadModal
