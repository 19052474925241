import * as z from 'zod'

export const nameRegex = /^[')\w _,.(-]+$/
export const phoneRegex = /^[\d]+$|^\+[\d]+$/

export const str = z.string().trim()
export const namesCheck = str
  .min(2, 'At least 2 characters')
  .regex(nameRegex, 'Invalid characters')
  .max(32, 'No more 32 characters')

export const textCheck = str.min(2, 'At least 2 characters').regex(nameRegex, 'Invalid characters')
export const phonesCheck = str
  .regex(phoneRegex, 'Only numbers with optional +')
  .min(11, 'At least 11 characters')
  .max(30, 'Maximum 30 characters')
