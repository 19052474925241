import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { defaultUser, User, UserMe, UserMeResult, UserPayload } from '../models/user'
import axios from 'axios'

const afUrl = process.env.REACT_APP_AF_API_URL
const routingUrl = process.env.REACT_APP_ROUTING_API_URL
const USER = 'user'
const USERME = 'userme'
const STALE = 20 * 60 * 1000

export const useUserMe = (): UseQueryResult<UserMeResult> =>
  useQuery(
    [USERME],
    async () => {
      const { data } = await axios.get(`${afUrl}/user/me`)
      return data
    },
    {
      enabled: false,
      initialDataUpdatedAt: Date.now(),
      staleTime: 60 * 60 * 1000,
      select: (data: UserMe) => {
        const isPartner = data.Customer === data.Partner
        let options = `partner=${data.Partner}`
        if (!isPartner) {
          options += `&customer=${data.Customer}`
        }
        return {
          customer: data.Customer,
          partner: data.Partner,
          isPartner,
          options
        }
      }
    }
  )

export const useUser = (): UseQueryResult<User[], unknown> => {
  const { data: userme, status } = useUserMe()

  return useQuery(
    [USER],
    async () => {
      const { data } = await axios.get(`${routingUrl}/user?${userme?.options}`)

      return data.reverse()
    },
    {
      enabled: status === 'success',
      initialDataUpdatedAt: Date.now(),
      staleTime: STALE,
      select: (data: User[]) => {
        return data.map((user: User) => {
          const uniqueId = (user.isNfc && user.email.replace(/@.+/, '')) || ''
          return {
            ...defaultUser,
            ...user,
            uniqueId
          }
        })
      }
    }
  )
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const { data: userme } = useUserMe()

  return useMutation(
    async (payload: UserPayload) => {
      payload.partnerName = userme?.partner || ''

      const { data } = await axios.put(`${routingUrl}/User`, payload)
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData([USER], (oldData?: User[]) =>
          oldData?.map((item) => (item.id === id ? data : item))
        )
      }
    }
  )
}

export const useAddUser = () => {
  const queryClient = useQueryClient()
  const { data: userme } = useUserMe()

  return useMutation(
    async (payload: UserPayload) => {
      payload.partnerName = userme?.partner || ''

      const { data } = await axios.post(`${routingUrl}/User`, payload)
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData([USER], (oldData?: User[]) => oldData && [data, ...oldData])
      }
    }
  )
}

export const useAddBulkUser = () => {
  const queryClient = useQueryClient()
  const { data: userme } = useUserMe()

  return useMutation(
    async (payload: UserPayload[]) => {
      const newPayload = payload.map((user) => ({ ...user, partnerName: userme?.partner || '' }))

      const { data } = await axios.post(`${routingUrl}/user/bulk`, newPayload)
      return data
    },
    {
      onSuccess: ({ successful }) => {
        successful.length &&
          queryClient.setQueryData(
            [USER],
            (oldData?: User[]) => oldData && [...successful, ...oldData]
          )
      }
    }
  )
}

export const useRemoveUser = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (payload: UserPayload) => {
      const { data } = await axios.delete(`${routingUrl}/User`, {
        data: {
          ...payload
        }
      })
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData(
          [USER],
          (oldData?: User[]) => oldData && [...oldData.filter((item) => item.id !== id)]
        )
      }
    }
  )
}
