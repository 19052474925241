import React, { FC, useState, useCallback, PropsWithChildren, ReactElement, useEffect } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { COLORS } from '../../theme'
import LinearProgress from '@mui/material/LinearProgress'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CloseIcon from '@mui/icons-material/Close'
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

export enum STEPS {
  AWAITING_DROP_OR_UPLOAD,
  START_READING,
  FINISH_READING,
  ERROR_READING
}

export type CustomFile = {
  name: string
  size: number
  arrayBuffer: ArrayBuffer | null
}

let abordLinkFunc: FileReader['abort'] | null = null

export interface FileUploadAreaProps {
  onLoad: (file: CustomFile) => void
  onError: (errorText: string) => void
  onStepChange: (step: STEPS) => void
  /*
    an exapmle:
    accept: {'text/csv': ['.csv'], 'text/html': ['.html', '.htm'], ....}
  */
  accept: DropzoneOptions['accept']
}

const MAX_FILES = 1

const FileUploadArea: FC<PropsWithChildren<FileUploadAreaProps>> = ({
  onLoad,
  onError,
  onStepChange,
  accept
}) => {
  const [step, setStep] = useState<STEPS>(STEPS.AWAITING_DROP_OR_UPLOAD)
  const [progress, setProgress] = useState<number>(0)
  const [file, setFile] = useState<CustomFile | null>(null)

  const [errorText, setErrorText] = useState<string | null>(null)

  useEffect(() => () => clearFileUploaderState(), [])

  useEffect(() => {
    if (step === STEPS.FINISH_READING && file && file.arrayBuffer) {
      onLoad(file)
    }
  }, [step, file])

  useEffect(() => {
    if (step === STEPS.ERROR_READING && errorText) {
      onError(errorText)
    }
  }, [step, errorText])

  useEffect(() => {
    onStepChange(step)
  }, [step])

  const onDropAccepted = useCallback<Required<DropzoneOptions>['onDropAccepted']>(
    ([acceptedFile]) => {
      const reader = new FileReader()

      abordLinkFunc = () => reader.abort()

      const { name, size } = acceptedFile

      const uploadingFile: CustomFile = {
        name,
        size: Math.ceil(size / 1024),
        arrayBuffer: null
      }

      setFile(uploadingFile)

      reader.onabort = () => setStep(STEPS.AWAITING_DROP_OR_UPLOAD)
      reader.onerror = (e) => {
        setErrorText('Something went wrong!')
        setStep(STEPS.ERROR_READING)
      }
      reader.onloadstart = () => setStep(STEPS.START_READING)
      reader.onprogress = ({ total, loaded }) => setProgress(Math.floor((loaded / total) * 100))
      reader.onload = (e) => {
        const binaryStr = reader.result as ArrayBuffer

        setFile((file) => ({ ...file!, arrayBuffer: binaryStr }))
        setStep(STEPS.FINISH_READING)
      }
      reader.readAsArrayBuffer(acceptedFile)
    },
    []
  )

  const onDropRejected = useCallback<Required<DropzoneOptions>['onDropRejected']>(
    ([acceptedFile]) => {
      setErrorText(acceptedFile.errors[0].message ?? 'Something went wrong!')
      setStep(STEPS.ERROR_READING)
    },
    []
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxFiles: MAX_FILES,
    accept
  })

  const clearFileUploaderState = () => {
    if (abordLinkFunc) {
      abordLinkFunc()
      abordLinkFunc = null
    }
    setStep(STEPS.AWAITING_DROP_OR_UPLOAD)
    setFile(null)
  }

  const getFormatFileSize = (size: number) => {
    return `${size}`.split('').reduce((acc, current, index) => {
      if (index === 0) acc += current
      else if (!(index % 3)) acc += ` ${current}`
      else acc += current
      return acc
    }, '')
  }

  return (
    <Box>
      {(step === STEPS.AWAITING_DROP_OR_UPLOAD || step === STEPS.ERROR_READING) && (
        <Grid
          sx={{
            background: COLORS.LIGHT_GRAY,
            height: '152px',
            border: `dashed 1px ${COLORS.LIGHT_BLUE_2}`
          }}
        >
          <Grid
            {...getRootProps()}
            sx={{ cursor: 'pointer', height: '100%' }}
            display='flex'
            direction='column'
            container
            alignItems='center'
            justifyContent='space-evenly'
          >
            <input {...getInputProps()} accept='.csv' />
            <CloudUploadOutlinedIcon color='primary' sx={{ fontSize: 40 }} />
            <Typography variant='subheadline' color='secondary'>
              {'Drag & drop files here or upload from desktop'}
            </Typography>
            <Button variant='text' startIcon={<AddIcon />}>
              Upload
            </Button>
          </Grid>
        </Grid>
      )}
      {step === STEPS.START_READING && (
        <Grid
          sx={{
            background: COLORS.LIGHT_GRAY,
            height: '88px',
            padding: '16px 24px',
            border: `solid 1px ${COLORS.LIGHT_BLUE_2}`
          }}
        >
          <Grid
            display='flex'
            direction='row'
            container
            alignItems='center'
            justifyContent='flex-start'
            mb='12px'
          >
            <FilePresentOutlinedIcon fontSize='large' color='primary' />
            <Typography ml='8px' variant='subheadline' color='secondary'>
              Uploading file...
            </Typography>
            <Typography ml='8px' variant='subheadlineBold' color='primary'>
              {progress}%
            </Typography>

            <IconButton
              onClick={clearFileUploaderState}
              sx={{ marginLeft: 'auto', padding: 'unset' }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </Grid>
          <LinearProgress
            sx={{ height: '8px', borderRadius: '10px' }}
            variant='determinate'
            value={progress}
          />
        </Grid>
      )}
      {step === STEPS.FINISH_READING && file && (
        <Grid
          sx={{
            background: COLORS.LIGHT_GRAY,
            height: '88px',
            padding: '16px 24px',
            border: `solid 1px ${COLORS.LIGHT_BLUE_2}`
          }}
        >
          <Grid
            display='flex'
            direction='row'
            container
            alignItems='center'
            justifyContent='flex-start'
          >
            <FilePresentOutlinedIcon fontSize='large' color='primary' />
            <Box ml='8px'>
              <Typography variant='subheadline'>{file.name}</Typography>
              <br />
              <Typography variant='subheadline' color='secondary'>
                {getFormatFileSize(file.size)} KB
              </Typography>
            </Box>

            <IconButton
              onClick={clearFileUploaderState}
              sx={{ marginLeft: 'auto', padding: 'unset' }}
            >
              <DeleteOutlinedIcon fontSize='small' color='error' />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default FileUploadArea
