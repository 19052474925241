import React, { FC, useState } from 'react'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'

import TableWrapper from '../components/UI/TableWrapper'
import OperatorForm, { OperatorSchema } from '../components/operator/OperatorForm'
import ConfirmationDialog from '../components/UI/ConfirmationDialog'
import Search from '../components/UI/Search'

import { useUserMe } from '../queries/user'
import {
    useAddOperator,
    useOperator,
    useRemoveOperator,
    useUpdateOperator
} from '../queries/operator'
import { defaultOperator, Operator as OperatorType } from '../models/operator'
import { useSnackbar } from 'notistack'
import { useTableData } from '../hooks/useTableData'
import { COLORS } from '../theme'

const headerColumn = [
    'Operator Name',
    'Main Contact',
    'Phone Number',
    '24-Hour Number',
    'Email Address'
]
type TableData = { totalPages: number; slicedData: OperatorType[]; totalItems: number }

const Operator: FC = () => {
    const { status, data: operators, isRefetching } = useOperator()
    const { data } = useUserMe()
    const { enqueueSnackbar } = useSnackbar()
    const { data: userMe } = useUserMe()
    const { mutateAsync: addOperatorAsync } = useAddOperator()
    const { mutateAsync: updateOperatorAsync } = useUpdateOperator()
    const { mutateAsync: removeOperatorAsync } = useRemoveOperator()
    const [search, setSearch] = useState('')
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [editOperator, setEditOperator] = useState<null | OperatorType>(null)
    const [removeOperator, setRemoveOperator] = useState<null | OperatorType>(null)
    const { totalPages, slicedData, totalItems }: TableData = useTableData({
        search,
        data: operators,
        keysToSearch: ['customerName']
    })

    const onSave = async (operator: OperatorSchema, id?: number) => {
        setIsUpdating(true)
        try {
            if (id) {
                const payload = { ...editOperator, ...operator }
                await updateOperatorAsync(payload)
            } else {
                const payload = { ...defaultOperator, ...operator }
                await addOperatorAsync(payload)
            }
            enqueueSnackbar(`Operator has been successfully ${id ? 'updated' : 'added'}.`, {
                variant: 'success'
            })
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    const onRemoveConfirm = async () => {
        setIsUpdating(true)
        try {
            if (removeOperator) {
                await removeOperatorAsync(removeOperator)
                enqueueSnackbar(`Operator has been successfully removed.`, {
                    variant: 'success'
                })
                setRemoveOperator(null)
            }
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    const rowsNode = (
        <>
            {slicedData.map((operator, i) => {
                const {
                    customerName,
                    mainContactNumber,
                    emergencyContactNumber,
                    mainContactFirstName,
                    mainContactSurname,
                    mainContactEmail
                } = operator
                const name = `${mainContactFirstName} ${mainContactSurname}`
                return (
                    <TableRow sx={{ height: '52px', verticalAlign: 'middle' }} key={i}>
                        <TableCell component='th' scope='row'>
                            <Typography>{customerName || '-'}</Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                            <Typography>{name === ' ' ? '-' : name}</Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                            <Typography>{Number(mainContactNumber) ? mainContactNumber : '-'}</Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                            <Typography>
                                {Number(emergencyContactNumber) ? emergencyContactNumber : '-'}
                            </Typography>
                        </TableCell>
                        <TableCell
                            component='th'
                            scope='row'
                            sx={{ maxWidth: '250px', wordWrap: 'break-word' }}
                        >
                            <Typography>{mainContactEmail || '-'}</Typography>
                        </TableCell>
                        {data?.isPartner && (
                            <TableCell align='left'>
                                <ButtonGroup variant='text'>
                                    <IconButton
                                        color='primary'
                                        onClick={() => {
                                            setEditOperator(operator)
                                            setIsFormOpen(true)
                                        }}
                                        disabled={isUpdating || isRefetching}
                                    >
                                        <ModeEditOutlineOutlinedIcon />
                                    </IconButton>
                                    <IconButton
                                        color='error'
                                        onClick={() => setRemoveOperator(operator)}
                                        disabled={isUpdating || isRefetching}
                                    >
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </ButtonGroup>
                            </TableCell>
                        )}
                    </TableRow>
                )
            })}
        </>
    )

    return (
        <>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Search
                    title='Operators'
                    onChangeSearch={(data: string) => setSearch(data.toLowerCase())}
                />
                {data?.isPartner && (
                    <Grid padding={'16px 12px'}>
                        <Button
                            sx={{ background: userMe?.customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399' }}

                            onClick={() => setIsFormOpen(true)} variant='contained' startIcon={<AddIcon />}>
                            Add new Operator
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableWrapper
                isSuccess={status === 'success'}
                emptyResultTitle='No operators found'
                totalPages={totalPages}
                totalItems={totalItems}
                isUpdating={isUpdating || isRefetching}
            >
                <>
                    <TableHead>
                        <TableRow>
                            {headerColumn.map((label) => (
                                <TableCell align='left' key={label}>
                                    <Typography variant='subheadlineBold' color='secondary'>
                                        {label}
                                    </Typography>
                                </TableCell>
                            ))}
                            {data?.isPartner && (
                                <TableCell align='left'>
                                    <Typography variant='subheadlineBold' color='secondary'>
                                        Actions
                                    </Typography>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rowsNode}</TableBody>
                </>
            </TableWrapper>
            <OperatorForm
                customer={userMe?.customer}
                open={isFormOpen}
                onClose={() => {
                    setEditOperator(null)
                    setIsFormOpen(false)
                }}
                onSave={onSave}
                operator={editOperator}
            />
            <ConfirmationDialog
                customer={userMe?.customer}
                open={!!removeOperator}
                onClose={() => setRemoveOperator(null)}
                onConfirm={onRemoveConfirm}
            />
        </>
    )
}

export default Operator
