import * as z from 'zod'
import { Vehicle } from './vehicle'
import * as azureMapsRest from 'azure-maps-rest'

export enum WAYPOINT_TYPE {
  START = 'START',
  END = 'END',
  STOP = 'STOP',
  EXTRA = 'EXTRA'
}

export const WaypointSchema = z.object({
  name: z.string(),
  point: z.array(z.number()).length(2),
  type: z.nativeEnum(WAYPOINT_TYPE)
})

export type Waypoint = Omit<ReturnType<typeof WaypointSchema['parse']>, 'point'> & {
  point: [number, number] //[lon, lat]
}

export enum TABS {
  'ALL_ROUTES',
  'ADHOC_HAZARDS'
}

export interface RouteDBModel {
  id: number
  createdBy?: string
  created: string
  updatedBy?: string
  updated: string
  routeNumber: string
  partnerName: string
  partnerId: number
  customerName: string
  customerId: number
  routeName: string
  enabled: boolean
  waypoints: string
  vehicles: Vehicle[]
  routeGeoJson: string
  description: string
  imageUri: string //base64
}

export type Route = Omit<RouteDBModel, 'waypoints' | 'routeGeoJson'> & {
  waypoints: Waypoint[]
  routeGeoJson: ReturnType<azureMapsRest.RouteGeojson['getFeatures']>
}

export type ToggleRoutePayload = Pick<Route, 'id'>

export type AddRoutePayload = Pick<
  RouteDBModel,
  | 'routeName'
  | 'waypoints'
  | 'vehicles'
  | 'routeGeoJson'
  | 'description'
  | 'imageUri'
  | 'routeNumber'
>

export type UpdateRoutePayload = AddRoutePayload & Pick<RouteDBModel, 'id'>

export type RemoveRoutePayload = Pick<RouteDBModel, 'id'>
