import React, { ComponentType, useEffect } from 'react'
import './App.css'
import { Routes as ReactRouterDomRoutes, Route, Navigate } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useAuth0 } from '@auth0/auth0-react'
import setupAxiosInterceptors from './setupAxios'
import Operator from './pages/Operator'
import Backdrop from './components/UI/Backdrop'
import General from './layouts/General'
import UIComponents from './pages/UIComponents'
import Vehicle from './pages/Vehicle'
import { useUserMe } from './queries/user'
import User from './pages/User'
import Routes from './pages/Routes'
import Hazards from './pages/Hazards'
import RouteOverview from './pages/RouteOverview'
import RouteAddEdit from './pages/RouteAddEdit'
import HazardAddEdit from './pages/HazardAddEdit'
import Tracking from './pages/Tracking'

const ProtectedRoute = ({ component, ...args }: { component: ComponentType }) => {
  const Component = withAuthenticationRequired(component, {
    ...args,
    onRedirecting: () => <Backdrop background={'#fff'} />
  })

  return (
    <General>
      <Component />
    </General>
  )
}

const ROUTES = [
  {
    path: '/routes',
    search: '?page=1',
    element: <ProtectedRoute component={Routes} />
  },
  {
    path: '/routes/:id',
    element: <ProtectedRoute component={RouteOverview} />
  },
  {
    path: '/routes/:id/edit',
    element: <ProtectedRoute component={RouteAddEdit} />
  },
  {
    path: '/routes/add',
    element: <ProtectedRoute component={RouteAddEdit} />
  },
  {
    path: '/routes/hazards',
    search: '?page=1',
    element: <ProtectedRoute component={Hazards} />
  },
  {
    path: '/routes/hazards/:id',
    element: <ProtectedRoute component={HazardAddEdit} />
  },
  {
    path: '/routes/hazards/add',
    element: <ProtectedRoute component={HazardAddEdit} />
  },
  {
    path: '/users',
    search: '?page=1',
    element: <ProtectedRoute component={User} />
  },
  {
    path: '/vehicles',
    search: '?page=1',
    element: <ProtectedRoute component={Vehicle} />
  },
  {
    path: '/operators',
    search: '?page=1',
    element: <ProtectedRoute component={Operator} />
  },
  {
    path: '/tracking',
    element: <ProtectedRoute component={Tracking} />
  },
  {
    path: '/ui',
    element: <ProtectedRoute component={UIComponents} />
  }
]

function App() {
  const { getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated, getIdTokenClaims } = useAuth0()
  const { refetch } = useUserMe()

  useEffect(() => {
    if (isAuthenticated) {
      setupAxiosInterceptors(getIdTokenClaims, getAccessTokenSilently, getAccessTokenWithPopup)
      refetch()
    }
  }, [isAuthenticated])

  return (
    <ReactRouterDomRoutes>
      {ROUTES.map((route) => (
        <Route key={route.path} {...route} />
      ))}
      <Route path='*' element={<Navigate to='/tracking' />} />
    </ReactRouterDomRoutes>
  )
}

export default App
