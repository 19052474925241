import React, { FC } from 'react'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import PaginationMUI, { PaginationProps } from '@mui/material/Pagination'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useSearchParams } from 'react-router-dom'

const BaseButtonStyle = {
  width: 36,
  height: 36,
  minWidth: 'unset',
  borderRadius: '4px'
}

const Pagination: FC<PaginationProps & { totalItems: number }> = ({ count, totalItems }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Typography variant='subheadline' color='secondary' mr={'32px'}>
        Total items: {totalItems}
      </Typography>
      <PaginationMUI
        sx={{ display: 'flex', justifyContent: 'center' }}
        siblingCount={0}
        boundaryCount={0}
        count={count}
        variant='text'
        page={Number(searchParams.get('page') || 1)}
        shape='rounded'
        onChange={(e, page) => setSearchParams({ page: `${page}` })}
        renderItem={({ type, disabled, page, selected, onClick }) => {
          if (type === 'page' && !selected) return

          switch (type) {
            case 'page': {
              return (
                <Button
                  disabled={disabled}
                  sx={{ ...BaseButtonStyle, mx: '4px', pointerEvents: 'none' }}
                  variant='outlined'
                  color='secondary'
                >
                  {page}
                </Button>
              )
            }
            case 'previous': {
              return (
                <Button
                  onClick={onClick}
                  disabled={disabled}
                  sx={BaseButtonStyle}
                  variant='outlined'
                  color='secondary'
                >
                  <ArrowBackIosNewOutlinedIcon fontSize='small' />
                </Button>
              )
            }
            case 'next': {
              return (
                <Button
                  onClick={onClick}
                  disabled={disabled}
                  sx={BaseButtonStyle}
                  variant='outlined'
                  color='secondary'
                >
                  <ArrowForwardIosOutlinedIcon fontSize='small' />
                </Button>
              )
            }
          }
          return
        }}
      />
      <Typography variant='subheadline' color='secondary' ml={'12px'}>
        of {count}
      </Typography>
    </Grid>
  )
}

export default Pagination
