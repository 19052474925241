import React, { FC } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

type Props = {
  error: string
  field: { value?: string; onChange: (value: string | null) => void }
  textFieldProps?: TextFieldProps
}
const LIMIT = 30

export const FormPhoneField: FC<Props> = ({ error, field, textFieldProps }) => {
  const onChange = (value: string) => {
    let newValue = value.trim()
    newValue = value[0] === '+' ? `+${value.slice(1).replace(/\D/g, '')}` : value.replace(/\D/g, '')
    field.onChange(newValue.slice(0, LIMIT))
  }
  const onBlur = (value: string) => {
    field.onChange(value.trim())
  }

  return (
    <TextField
      {...field}
      {...textFieldProps}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur(e.target.value)}
      error={!!error}
      helperText={error}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => field.onChange('')}
            disabled={!field.value}
            sx={{ opacity: field.value ? 1 : 0 }}
          >
            <HighlightOffIcon />
          </IconButton>
        )
      }}
    />
  )
}
