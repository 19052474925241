export type UserGeolocation = Pick<GeolocationPosition['coords'], 'latitude' | 'longitude'> | null

let userGeolocation: UserGeolocation = null

const onSuccess = (position: GeolocationPosition) => {
  const latitude = position.coords.latitude
  const longitude = position.coords.longitude
  userGeolocation = {
    latitude,
    longitude
  }
}

const onError = (error: GeolocationPositionError) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      console.info('User Geolocation: User denied the request for Geolocation.')
      break
    case error.POSITION_UNAVAILABLE:
      console.info('User Geolocation: Location information is unavailable.')
      break
    case error.TIMEOUT:
      console.info('User Geolocation: The request to get user location timed out.')
      break
    default:
      console.info('User Geolocation: An unknown error occurred.')
      break
  }
}

navigator.geolocation.getCurrentPosition(onSuccess, onError)

export const getUserGeolocation = () => {
  return userGeolocation
}
