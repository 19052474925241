import React, { FC, CSSProperties } from 'react'
import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'

interface EmptyTableResultProps extends CSSProperties {
  title: string
  subtitle: string
}

const EmptyTableResult: FC<EmptyTableResultProps> = ({ title, subtitle, ...styles }) => (
  <Box sx={{ ...styles, width: 166, height: 166, textAlign: 'center' }}>
    <SvgIcon
      sx={{ width: 166, height: 166 }}
      width='166'
      height='166'
      viewBox='0 0 166 166'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_166_8356)'>
        <path
          d='M126.77 0H53.8203C45.7625 0 39.2305 6.53203 39.2305 14.5898V151.41C39.2305 159.468 45.7625 166 53.8203 166H151.086C159.144 166 165.676 159.468 165.676 151.41V38.9062L126.77 0Z'
          fill='#E1E6F0'
        />
        <path
          d='M126.77 0H102.453V166H151.086C159.144 166 165.676 159.468 165.676 151.41V38.9062L126.77 0Z'
          fill='#CFD5E3'
        />
        <path
          d='M126.77 0V34.043C126.77 36.7288 128.947 38.9062 131.633 38.9062H165.676L126.77 0Z'
          fill='#B0B4C8'
        />
        <path
          d='M138.117 97.9141H66.7891C64.1032 97.9141 61.9258 95.7366 61.9258 93.0508C61.9258 90.365 64.1032 88.1875 66.7891 88.1875H138.117C140.803 88.1875 142.98 90.365 142.98 93.0508C142.98 95.7366 140.803 97.9141 138.117 97.9141Z'
          fill='#B0B4C8'
        />
        <path
          d='M138.117 68.7344H66.7891C64.1032 68.7344 61.9258 66.5569 61.9258 63.8711C61.9258 61.1853 64.1032 59.0078 66.7891 59.0078H138.117C140.803 59.0078 142.98 61.1853 142.98 63.8711C142.98 66.5569 140.803 68.7344 138.117 68.7344Z'
          fill='#B0B4C8'
        />
        <path
          d='M138.117 127.094H66.7891C64.1032 127.094 61.9258 124.916 61.9258 122.23C61.9258 119.545 64.1032 117.367 66.7891 117.367H138.117C140.803 117.367 142.98 119.545 142.98 122.23C142.98 124.916 140.803 127.094 138.117 127.094Z'
          fill='#B0B4C8'
        />
        <path
          d='M142.98 93.0508C142.98 90.365 140.803 88.1875 138.117 88.1875H102.453V97.9141H138.117C140.803 97.9141 142.98 95.7366 142.98 93.0508Z'
          fill='#878B9F'
        />
        <path
          d='M142.98 63.8711C142.98 61.1853 140.803 59.0078 138.117 59.0078H102.453V68.7344H138.117C140.803 68.7344 142.98 66.5569 142.98 63.8711Z'
          fill='#878B9F'
        />
        <path
          d='M138.117 127.094C140.803 127.094 142.98 124.916 142.98 122.23C142.98 119.545 140.803 117.367 138.117 117.367H102.453V127.094H138.117Z'
          fill='#878B9F'
        />
        <path
          d='M44.0938 166C19.9592 166 0.324219 146.365 0.324219 122.23C0.324219 98.0959 19.9592 78.4609 44.0938 78.4609C68.2283 78.4609 87.8633 98.0959 87.8633 122.23C87.8633 146.365 68.2283 166 44.0938 166Z'
          fill='#0C64B3'
        />
        <path
          d='M44.0938 78.4609V166C68.2283 166 87.8633 146.365 87.8633 122.23C87.8633 98.0959 68.2283 78.4609 44.0938 78.4609Z'
          fill='#204A85'
        />
        <path
          d='M50.9716 122.23L62.1227 111.079C64.022 109.18 64.022 106.101 62.1227 104.202C60.2238 102.303 57.1444 102.303 55.2451 104.202L44.0939 115.353L32.9427 104.202C31.0438 102.303 27.9644 102.303 26.0651 104.202C24.1658 106.101 24.1658 109.18 26.0651 111.079L37.2163 122.23L26.0651 133.382C24.1658 135.281 24.1658 138.36 26.0651 140.259C27.0147 141.209 28.2594 141.684 29.5041 141.684C30.7487 141.684 31.9934 141.209 32.9427 140.259L44.0939 129.108L55.2451 140.259C56.1944 141.209 57.4391 141.684 58.6838 141.684C59.9284 141.684 61.1731 141.209 62.1224 140.259C64.0217 138.36 64.0217 135.281 62.1224 133.381L50.9716 122.23Z'
          fill='white'
        />
        <path
          d='M50.9714 122.23L62.1226 111.079C64.0219 109.18 64.0219 106.101 62.1226 104.202C60.2236 102.303 57.1442 102.303 55.2449 104.202L44.0938 115.353V129.108L55.2449 140.259C56.1942 141.209 57.4389 141.684 58.6836 141.684C59.9283 141.684 61.1729 141.209 62.1223 140.259C64.0215 138.36 64.0215 135.281 62.1223 133.381L50.9714 122.23Z'
          fill='#E1E6F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_166_8356'>
          <rect width='166' height='166' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
    <Typography sx={{ marginTop: '30px' }} variant='textBold'>
      {title}
    </Typography>
    <br />
    <Typography variant='text' color='secondary'>
      {subtitle}
    </Typography>
  </Box>
)

export default EmptyTableResult
