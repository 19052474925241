import React, { FC, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { FormControlLabel, Switch } from '@mui/material'

import * as z from 'zod'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { OperatorLookup } from '../lookups/OperatorLookup'
import { FormPhoneField } from '../UI/FormPhoneField'
import { FormTextField } from '../UI/FormTextField'

import { getErrorMessage } from '../../utils/reactHookForm'
import { defaultUser, User } from '../../models/user'
import { namesCheck, str, phonesCheck } from '../../assets/constans/formValidation'
import { COLORS } from '../../theme'

export const userSchema = z.object({
    id: z.number().optional(),
    firstName: namesCheck,
    lastName: namesCheck.or(str.length(0)),
    email: str.email('Invalid email').or(str.length(0)),
    mobile: phonesCheck.or(str.length(0)),
    uniqueId: str.min(14, 'At least 14 characters').or(str.length(0)),
    isNfc: z.boolean(),
    isAdmin: z.boolean(),
    customerName: z.string()
})

export type UserSchema = ReturnType<typeof userSchema['parse']>
const keys = userSchema.keyof().Values
type Keys = keyof typeof keys

interface UserFormProps {
    customer: string | undefined
    open: boolean
    onClose: () => void
    onSave: (user: UserSchema, id?: number) => void
    user: User
}

const defaultValues: UserSchema = defaultUser
const UserForm: FC<UserFormProps> = ({ customer, open, user, onClose, onSave }) => {
    const {
        handleSubmit,
        watch,
        control,
        resetField,
        reset,
        formState: { errors }
    } = useForm<UserSchema>({
        defaultValues,
        resolver: zodResolver(userSchema)
    })

    useEffect(() => {
        if (user) {
            const prefilledFields: Omit<UserSchema, 'id'> = {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                uniqueId: user.uniqueId,
                isNfc: user.isNfc,
                customerName: user.customerName,
                isAdmin: user.isAdmin,
                mobile: user.mobile || user.phone
            }
            reset(prefilledFields)
        }
    }, [user])

    useEffect(() => {
        resetField('email', { defaultValue: user.email })
        resetField('uniqueId', { defaultValue: user?.uniqueId || '' })
    }, [watch('isNfc')])

    useEffect(() => {
        if (watch('isAdmin')) {
            resetField('isNfc', { defaultValue: false })
        }
    }, [watch('isAdmin')])

    useEffect(() => {
        resetField('customerName', { defaultValue: watch('customerName') })
    }, [watch('customerName')])

    const onSubmit = handleSubmit((userForm) => {
        onSave(userForm, user?.id)
        onClose()
        return
    })
    const errText = (key: Keys) => (errors[key] ? getErrorMessage(errors[key]!) : '')

    return (
        <Dialog
            keepMounted={false}
            open={open}
            onClose={onClose}
            sx={{ '& .MuiPaper-root': { maxWidth: '950px', height: 'auto', padding: '24px' } }}
        >
            <Grid
                container
                justifyContent='space-around'
                direction='column'
                alignItems='center'
                height='100%'
            >
                <form onSubmit={onSubmit}>
                    <DialogTitle textAlign='center' mt='8px' mb='16px' variant='title1'>
                        <Typography variant='title1Bold'>{user.id ? 'Edit user' : 'Add a new user'}</Typography>
                    </DialogTitle>
                    <Grid container direction='column' justifyContent='space-between'>
                        <Grid container spacing={2} justifyContent='space-between'>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={'firstName'}
                                    render={({ field }) => (
                                        <FormTextField
                                            textFieldProps={{
                                                label: 'First name',
                                                required: true
                                            }}
                                            error={errText('firstName')}
                                            field={field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={'lastName'}
                                    render={({ field }) => (
                                        <FormTextField
                                            textFieldProps={{ label: 'Surname' }}
                                            error={errText('lastName')}
                                            field={field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt='8px' container spacing={2} justifyContent='space-between'>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={keys.mobile}
                                    render={({ field }) => (
                                        <FormPhoneField
                                            textFieldProps={{ label: 'Contact number' }}
                                            field={field}
                                            error={errText('mobile')}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item sx={{ width: '50%' }}>
                                <Controller
                                    control={control}
                                    name={keys.customerName}
                                    render={({ field: { value, onChange } }) => (
                                        <OperatorLookup value={value} onChange={onChange} />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt='8px' container spacing={2} justifyContent='space-between' alignItems='center'>
                            <Grid item sx={{ width: '50%' }}>
                                <Grid container justifyContent='space-between' ml='8px'>
                                    <FormControlLabel
                                        label='Admin'
                                        control={
                                            <Controller
                                                control={control}
                                                name={keys.isAdmin}
                                                render={({ field: { value, onChange } }) => (
                                                    <Switch checked={value} onChange={(e, value) => onChange(value)} />
                                                )}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='NFC card'
                                        control={
                                            <Controller
                                                control={control}
                                                name={keys.isNfc}
                                                render={({ field: { value, onChange } }) => (
                                                    <Switch
                                                        checked={watch(keys.isAdmin) ? false : value}
                                                        onChange={(e, value) => onChange(value)}
                                                        disabled={watch(keys.isAdmin)}
                                                    />
                                                )}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                {!watch(keys.isNfc) || watch(keys.isAdmin) ? (
                                    <Controller
                                        control={control}
                                        name={'email'}
                                        render={({ field }) => (
                                            <FormTextField
                                                textFieldProps={{
                                                    label: 'Email',
                                                    required: true
                                                }}
                                                error={errText('email')}
                                                field={field}
                                            />
                                        )}
                                    />
                                ) : (
                                    <Controller
                                        control={control}
                                        name={'uniqueId'}
                                        render={({ field }) => (
                                            <FormTextField
                                                textFieldProps={{
                                                    label: 'Unique identifier',
                                                    required: true
                                                }}
                                                limit={14}
                                                error={errText('uniqueId')}
                                                field={field}
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        mt='14px'
                        container
                        justifyContent='space-between'
                        alignItems='flex-end'
                        spacing={2}
                    >
                        <Grid item>
                            <Button sx={{
                                minWidth: '200px',
                            }} variant='outlined' onClick={onClose}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                disabled={
                                    !!Object.keys(errors).length ||
                                    (watch('isNfc') && !watch('uniqueId')) ||
                                    (!watch('isNfc') && !watch('email'))
                                }
                                sx={{
                                    minWidth: '200px',
                                    background: customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399'
                                }}
                                variant='contained'
                                type='submit'
                                autoFocus
                            >
                                {user.id ? 'Update' : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Dialog>
    )
}

export default UserForm
