import React, { FC } from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const StartWaypointIcon: FC<{ fill: string }> = ({ fill }) => (
  <SvgIcon
    sx={{ width: '26px', height: '38px', mr: '16px' }}
    width='26'
    height='38'
    viewBox='0 0 26 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <svg width='26' height='38' viewBox='0 0 26 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26 12.8955C26 24 15.3214 38 13 38C10.6786 38 0 24 0 12.8955C0 5.7735 5.8203 0 13 0C20.1797 0 26 5.7735 26 12.8955Z'
        fill={fill}
      />
      <circle cx='13' cy='13' r='11' fill='white' />
      <path
        d='M12.9575 8.55762L10.1206 17H8.13135L11.8501 7.04688H13.1216L12.9575 8.55762ZM15.3296 17L12.479 8.55762L12.3081 7.04688H13.5864L17.3257 17H15.3296ZM15.1997 13.3018V14.7852H9.86084V13.3018H15.1997Z'
        fill={fill}
      />
    </svg>
  </SvgIcon>
)
