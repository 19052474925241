export const defaultOperator = {
  id: 0,
  partnerName: '',
  customerName: '',
  mainContactNumber: '',
  emergencyContactNumber: '',
  mainContactFirstName: '',
  mainContactSurname: '',
  mainContactEmail: ''
}

export type Operator = typeof defaultOperator
