export type UserMe = {
  Customer: string
  EMail: string
  FirstName: string
  Id: number
  LastName: string
  Mobile: string
  Partner: string
  Phone: string
}
export type UserMeResult = {
  customer: string
  partner: string
  isPartner: boolean
  options: string
}

export const defaultUser = {
  id: 0,
  customerName: '',
  email: '',
  firstName: '',
  lastName: '',
  mobile: '',
  phone: '',
  partnerName: '',
  isAdmin: false,
  uniqueId: '',
  customerId: 0,
  partnerId: 0,
  isNfc: false
}
export type User = typeof defaultUser
export type UserPayload = Omit<User, 'uniqueId'>
