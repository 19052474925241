import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

//TODO: create normal types here
type Props = { search: string; limit?: number; data?: any[]; keysToSearch: string[] }

export const useTableData = ({ search, data, keysToSearch, limit = 10 }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [totalPages, setTotalPages] = useState(0)
  const [slicedData, setSlicedData] = useState<any[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const page = Number(searchParams.get('page') || 1)

  useEffect(() => {
    if (!data || !data.length) return
    const filteredData = data.filter((item) => {
      const field = keysToSearch.map((k) => item[k]).join(' ')
      return !search || field.toLowerCase().includes(search)
    })
    filteredData.length !== data.length && setSearchParams({ page: '1' })

    const slicedData = filteredData.slice((page - 1) * limit, page * limit)
    const totalPages = filteredData.length ? Math.ceil(filteredData.length / limit) : 0

    setSlicedData(slicedData)
    setTotalPages(totalPages)
    setTotalItems(filteredData.length)
  }, [page, search, data])

  return { totalPages, slicedData, totalItems, page }
}
