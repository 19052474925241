import React, { FC, useState, useEffect, useRef, useLayoutEffect } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import { SxProps, Theme } from '@mui/material/styles'

import { useSnackbar } from 'notistack'
import { throttle } from 'lodash'
import * as azureMapsControl from 'azure-maps-control'
import * as signalR from '@microsoft/signalr'

import Search from '../components/UI/Search'
import Backdrop from '../components/UI/Backdrop'
import Device from '../components/tracking/Device'
import Switch from '../components/UI/Switch'

import { createMapInstance, LONDON_BBOX } from '../utils/map'
import { selectedTrackingMarkerHTML, trackingMarkerHTML } from '../assets/htmlMarkers'
import { useDevireGroup, useFastTrackingInfoLatest } from '../queries/tracking'
import useTracking from '../hooks/useTracking'
import { TrackingMessage } from '../models/tracking'

const mapContainerStyle: SxProps<Theme> = {
  width: 'calc(100% - 312px)',
  height: '100%',
  '&.disabled': {
    '.disabled__background': {
      opacity: 0,
      cursor: 'not-allowed',
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: 'white',
      zIndex: 2
    },
    '& .mapboxgl-canvas-container': {
      '& .marker-container': {
        '& div': {
          '& div': {
            animation: 'unset !important'
          }
        }
      }
    }
  }
}

type TrackingNotReactiveData = {
  MAP: azureMapsControl.Map | null
}

const TRACKING_NOT_REACTIVE_DATA: TrackingNotReactiveData = {
  MAP: null
}

const routeUpdateFrequencyOptions = [
  {
    label: '10 seconds',
    value: 10
  },
  {
    label: '20 seconds',
    value: 20
  },
  {
    label: '30 seconds',
    value: 30
  },
  {
    label: '40 seconds',
    value: 40
  },
  {
    label: '50 seconds',
    value: 50
  },
  {
    label: '60 seconds',
    value: 60
  }
]

export type Device = {
  position: azureMapsControl.data.Position
  notes: string
  imei: string
  lastUpdated: string
  comment: string
  rcv: string
  visible: boolean
}

type DeviceMap = Record<string, Device>

const Tracking: FC = () => {
  const deviceContainerRef = useRef<HTMLDivElement | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [routeUpdateFrequency, setRouteUpdateFrequency] = useState<number>(
    routeUpdateFrequencyOptions[0].value
  )

  const [liveTracking, setLiveTracking] = useState(true)

  const mapRef = useRef<HTMLDivElement | null>(null)

  const { connection } = useTracking({})
  const { enqueueSnackbar } = useSnackbar()

  const { status, data: deviceGroup } = useDevireGroup()
  const { data: trackingInfoLatest, refetch } = useFastTrackingInfoLatest(deviceGroup?.Id)

  const [mapIsLoading, setMapIsLoading] = useState(true)
  const [openDeviceImei, setOpenDeviceImei] = useState<string | null>(null)

  const [devices, setDevices] = useState<DeviceMap>({})

  const onTrackingmessage = throttle((data: string) => {
    if (!liveTracking) return
    const {
      hdr: { imei, rcv },
      loc: {
        loc: { coordinates }
      }
    }: TrackingMessage = JSON.parse(data)

    setDevices((prev) => {
      if (prev[imei]) {
        return {
          ...prev,
          [imei]: {
            ...prev[imei],
            rcv,
            position: coordinates
          }
        }
      }
      return prev
    })
  }, routeUpdateFrequency * 1000)

  useEffect(() => {
    if (connection) {
      const start = async () => {
        try {
          if (connection.state === signalR.HubConnectionState.Disconnecting) {
            return
          }
          await connection.start()
        } catch (err: any) {
          if (
            (err instanceof signalR.AbortError &&
              err.message === 'The connection was stopped during negotiation.') ||
            err.message === 'Failed to start the HttpConnection before stop() was called.'
          ) {
            return
          }
          enqueueSnackbar('Something went wrong!', {
            variant: 'error'
          })
        }
      }
      start()
    }
  }, [connection])

  useEffect(() => {
    if (connection) {
      connection.off('trackingmessage')
      connection.on('trackingmessage', onTrackingmessage)
    }
  }, [connection, liveTracking, routeUpdateFrequency])

  useEffect(() => {
    if (mapRef.current) {
      liveTracking
        ? mapRef.current.classList.remove('disabled')
        : mapRef.current.classList.add('disabled')
    }
  }, [liveTracking, mapRef, mapRef.current])

  useEffect(() => {
    if (deviceGroup && deviceGroup.Id) {
      refetch()
    }
  }, [deviceGroup])

  useEffect(
    () => () => {
      TRACKING_NOT_REACTIVE_DATA.MAP = null
    },
    []
  )

  useEffect(() => {
    if (trackingInfoLatest && trackingInfoLatest.length && deviceGroup && deviceGroup.Members) {
      const lastTrackingDevicesByMap = trackingInfoLatest
        .map(
          ({
            hdr: { imei, rcv },
            loc: {
              loc: { coordinates }
            }
          }) => ({ imei, coordinates, rcv })
        )
        .reduce((acc: DeviceMap, { imei, coordinates, rcv }, index) => {
          const member = deviceGroup.Members.find(({ Member }) => Member.Imei === imei)
          const device: Device = {
            imei,
            position: coordinates,
            rcv,
            notes: member?.Member.Notes ?? '-',
            comment: member?.Member.Comment ?? '-',
            lastUpdated: member?.Member.LastUpdated ?? '-',
            visible: true
          }
          acc[imei] = device
          return acc
        }, {})

      setDevices(lastTrackingDevicesByMap)
    }
  }, [trackingInfoLatest, deviceGroup])

  useEffect(() => {
    if (TRACKING_NOT_REACTIVE_DATA.MAP) return
    TRACKING_NOT_REACTIVE_DATA.MAP = createMapInstance('trackingMap')

    TRACKING_NOT_REACTIVE_DATA.MAP.events.add('load', () => {
      if (!TRACKING_NOT_REACTIVE_DATA.MAP) return

      TRACKING_NOT_REACTIVE_DATA.MAP.setCamera({
        bounds: LONDON_BBOX,
        padding: 50
      })
      setMapIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!TRACKING_NOT_REACTIVE_DATA.MAP) return

    const markers = TRACKING_NOT_REACTIVE_DATA.MAP.markers.getMarkers()

    const markersForRemoving = markers.filter((marker) => {
      const { imei: markerImei } = marker.getOptions()
      return !Object.keys(devices).some((imei) => imei === markerImei)
    })

    const markersForAdding = Object.values(devices)
      .filter(({ imei }) => {
        return !markers.some((marker) => {
          const { imei: markerImei } = marker.getOptions()
          return markerImei === imei
        })
      })
      .map(({ imei, position }) => {
        const marker = new azureMapsControl.HtmlMarker({
          htmlContent: imei === openDeviceImei ? selectedTrackingMarkerHTML : trackingMarkerHTML,
          text: '',
          anchor: 'center',
          position
        })

        //@ts-ignore
        marker.options = {
          //@ts-ignore
          ...marker.options,
          imei
        }

        return marker
      })

    const markersForUpdating = markers.filter((marker) => {
      const { imei: markerImei } = marker.getOptions()
      return !markersForRemoving.some((markerForRemoving) => {
        const { imei: markerForRemovingImei } = markerForRemoving.getOptions()
        return markerImei === markerForRemovingImei
      })
    })

    markersForUpdating.forEach((markerForUpdating) => {
      const { imei: markerForUpdatingImei } = markerForUpdating.getOptions()
      markerForUpdating.setOptions({
        htmlContent:
          markerForUpdatingImei === openDeviceImei
            ? selectedTrackingMarkerHTML
            : trackingMarkerHTML,
        position: devices[markerForUpdatingImei].position
      })

      //@ts-ignore
      markerForUpdating.options = {
        //@ts-ignore
        ...markerForUpdating.options,
        imei: markerForUpdatingImei
      }
    })

    TRACKING_NOT_REACTIVE_DATA.MAP.markers.remove(markersForRemoving)

    TRACKING_NOT_REACTIVE_DATA.MAP.markers.add(markersForAdding)

    //@ts-ignore
    TRACKING_NOT_REACTIVE_DATA.MAP.events.add('click', markersForAdding, openCard)

    const markersForFiltering = TRACKING_NOT_REACTIVE_DATA.MAP.markers.getMarkers()

    markersForFiltering.forEach((marker) => {
      const { imei } = marker.getOptions()
      const visible = devices[imei].visible
      marker.setOptions({
        visible: visible
      })

      //@ts-ignore
      marker.options = {
        //@ts-ignore
        ...marker.options,
        imei
      }
    })
  }, [devices, mapIsLoading, openDeviceImei])

  const openCard = (
    e: { target: azureMapsControl.HtmlMarker } & azureMapsControl.TargetedEvent
  ) => {
    const { imei: markerImei }: azureMapsControl.HtmlMarkerOptions = e.target.getOptions()

    setOpenDeviceImei((prev) => {
      if (prev === markerImei) {
        return null
      }
      return markerImei
    })
  }

  useLayoutEffect(() => {
    if (openDeviceImei && deviceContainerRef && deviceContainerRef.current) {
      const deviceHTMLContainer = deviceContainerRef.current.querySelector(
        `[data-imei="${openDeviceImei}"]`
      )
      if (deviceHTMLContainer) {
        //A collapse transition is 400ms
        setTimeout(() => deviceHTMLContainer.scrollIntoView({ behavior: 'smooth' }), 400)
      }
    }
  }, [openDeviceImei])

  const selectDeviceFromCard = (imei: string) => {
    if (imei === openDeviceImei) {
      setOpenDeviceImei(null)
    } else {
      if (TRACKING_NOT_REACTIVE_DATA.MAP) {
        const markers = TRACKING_NOT_REACTIVE_DATA.MAP.markers.getMarkers()
        const marker = markers.find((marker) => {
          const { imei: markerImei } = marker.getOptions()
          return imei === markerImei
        })
        if (marker) {
          const { position } = marker.getOptions()
          TRACKING_NOT_REACTIVE_DATA.MAP.setCamera({ center: position, type: 'fly', zoom: 13 })
        }
      }
      setOpenDeviceImei(imei)
    }
  }

  const filterDevice = (imei: string, state: boolean) => {
    setDevices((prev) => ({ ...prev, [imei]: { ...prev[imei], visible: state } }))
  }

  return (
    <>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        <Search
          title='Tracking'
          onChangeSearch={(data: string) => {
            setSearchQuery(data.toLowerCase())
          }}
          textFieldProps={{
            placeholder: 'IMEI, Route Name, Route Number, Driver, Duty Number, Device Name'
          }}
        />

        <Grid
          container
          item
          direction='row'
          justifyContent='end'
          alignItems='center'
          width='auto'
          padding={'16px 12px'}
        >
          <Grid
            item
            container
            direction='row'
            justifyContent='start'
            alignItems='center'
            width='auto'
            mr='24px'
          >
            <Switch checked={liveTracking} onChange={() => setLiveTracking(!liveTracking)} />
            <Typography ml='16px' variant='textBold' color={liveTracking ? 'primary' : 'secondary'}>
              Freeze tracking
            </Typography>
          </Grid>

          <FormControl sx={{ width: '300px' }} fullWidth>
            <InputLabel id='route-update-frequency'>Route update frequency</InputLabel>
            <Select
              labelId='route-update-frequency'
              label='Route update frequency'
              IconComponent={ExpandMoreOutlinedIcon}
              value={`${routeUpdateFrequency}`}
              onChange={(e: SelectChangeEvent) => setRouteUpdateFrequency(+e.target.value)}
            >
              {routeUpdateFrequencyOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='start'
        px={'12px'}
        pt={'12px'}
        height='100%'
      >
        <Box id='trackingMap' borderRadius='8px' sx={mapContainerStyle} ref={mapRef}>
          <Box className='disabled__background' />
          {mapIsLoading && <Backdrop position='absolute' />}
        </Box>
        <Grid
          container
          sx={{ width: '300px', ml: '12px', maxHeight: 'calc(100vh - 230px)', overflowY: 'auto' }}
          direction='column'
          justifyContent='start'
          alignItems='center'
          flexWrap={'nowrap'}
          ref={deviceContainerRef}
        >
          {Object.values(devices).map((device) => (
            <Box data-imei={device.imei} key={device.imei}>
              <Device
                setOpen={selectDeviceFromCard}
                opened={openDeviceImei === device.imei}
                imei={device.imei}
                rcv={device.rcv}
                searchQuery={searchQuery}
                onShow={filterDevice}
                visible={device.visible}
                deviceName={device.notes}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default Tracking
