import React, { FC, useCallback, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CachedIcon from '@mui/icons-material/Cached'

import { useSnackbar } from 'notistack'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { FormTextField } from '../../UI/FormTextField'
import Stops from './StopsForm'
import VehicleLookup from '../../lookups/VehicleLookup'
import AddressPOILookup, { AddressPOILookupValue } from '../../lookups/AddressPOILookup'

import { WAYPOINT_TYPE } from '../../../models/route'
import { COLORS } from '../../../theme'
import { getErrorMessage } from '../../../utils/reactHookForm'
import { StartWaypointIcon } from '../../../assets/icons/StartWaypointIcon'
import { EndWaypointIcon } from '../../../assets/icons/EndWaypointIcon'
import { RouteFormSchema, keys, RouteGeoJson } from './routeFormModel'

export type RouteInList = RouteFormSchema &
  RouteGeoJson & {
    confirmed: boolean
  }

export type RoutesList = Record<string, RouteInList>

const AddEditRouteFormUI: FC = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext<RouteFormSchema>()

  const intermediateWaypoints = useWatch({ control, name: keys.intermediateWaypoints })
  const startWaypoint = useWatch({ control, name: keys.startWaypoint })
  const endWaypoint = useWatch({ control, name: keys.endWaypoint })

  const reverseRoute = () => {
    setValue(keys.startWaypoint, endWaypoint ? { ...endWaypoint, type: WAYPOINT_TYPE.START } : null)
    setValue(keys.endWaypoint, startWaypoint ? { ...startWaypoint, type: WAYPOINT_TYPE.END } : null)
    setValue(keys.intermediateWaypoints, intermediateWaypoints.reverse())
    enqueueSnackbar(`The direction was successfully changed.`, {
      variant: 'success'
    })
  }

  useEffect(() => {
    if (!endWaypoint || !startWaypoint) return
    if (endWaypoint.name === startWaypoint.name) {
      setError(keys.endWaypoint, {
        message: 'Has to be differrent from the start point'
      })
    } else {
      clearErrors(keys.endWaypoint)
    }
  }, [endWaypoint, startWaypoint])

  const getAddressPOILookupValue = useCallback(
    (
      value:
        | RouteFormSchema['startWaypoint']
        | RouteFormSchema['endWaypoint']
        | RouteFormSchema['intermediateWaypoints'][number]
    ): AddressPOILookupValue | null => (value ? { name: value.name, point: value.point } : null),
    []
  )

  return (
    <>
      <Grid
        container
        flexDirection='column'
        alignItems='start'
        justifyContent='start'
        rowSpacing={2}
        mt='12px'
      >
        <Typography variant='bodyBold' color='secondary'>
          General info
        </Typography>
        <Grid item width='100%'>
          <Controller
            control={control}
            name={keys.routeName}
            render={({ field }) => (
              <FormTextField
                textFieldProps={{
                  label: 'Route name',
                  fullWidth: true,
                  required: true
                }}
                error={errors[keys.routeName] ? getErrorMessage(errors[keys.routeName]!) : ''}
                field={field}
              />
            )}
          />
        </Grid>
        <Grid item width='100%'>
          <Controller
            control={control}
            name={keys.description}
            render={({ field }) => (
              <FormTextField
                textFieldProps={{
                  label: 'Route description',
                  fullWidth: true
                }}
                error={errors[keys.description] ? getErrorMessage(errors[keys.description]!) : ''}
                field={field}
              />
            )}
          />
        </Grid>
        <Grid item width='100%'>
          <Controller
            control={control}
            name={keys.routeNumber}
            render={({ field }) => (
              <FormTextField
                textFieldProps={{
                  label: 'Route number',
                  fullWidth: true,
                  required: true
                }}
                error={errors[keys.routeNumber] ? getErrorMessage(errors[keys.routeNumber]!) : ''}
                field={field}
              />
            )}
          />
        </Grid>
        <Grid item width='100%'>
          <Controller
            control={control}
            name={keys.vehicles}
            render={({ field: { onChange, value } }) => (
              <VehicleLookup
                textFeildProps={{
                  error: !!errors[keys.vehicles],
                  helperText:
                    //@ts-ignore
                    !!errors[keys.vehicles] && getErrorMessage(errors[keys.vehicles]!)
                }}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container flexDirection='column' alignItems='start' justifyContent='start' mt='24px'>
        <Typography variant='bodyBold' color='secondary'>
          Route info
        </Typography>

        <Grid container rowSpacing={2} mt='0'>
          <Grid
            item
            container
            justifyContent='start'
            alignItems='center'
            direction='row'
            whiteSpace='normal'
            wrap='nowrap'
          >
            <StartWaypointIcon fill={COLORS.MAIN_BLUE} />
            <Controller
              control={control}
              name={keys.startWaypoint}
              render={({ field: { onChange, value } }) => (
                <AddressPOILookup
                  textFeildProps={{
                    label: 'Start point',
                    placeholder: 'Search for address',
                    error: !!errors[keys.startWaypoint],
                    helperText:
                      !!errors[keys.startWaypoint] && getErrorMessage(errors[keys.startWaypoint]!),
                    required: true
                  }}
                  value={getAddressPOILookupValue(value)}
                  onChange={(waypoint) =>
                    waypoint ? onChange({ ...waypoint, type: WAYPOINT_TYPE.START }) : onChange(null)
                  }
                />
              )}
            />
          </Grid>

          <Grid
            item
            container
            justifyContent='start'
            alignItems='center'
            direction='row'
            whiteSpace='normal'
            wrap='nowrap'
            mb='8px'
          >
            <IconButton sx={{ padding: '0' }} onClick={reverseRoute} color='secondary'>
              <CachedIcon />
            </IconButton>

            <Box width='100%' height='1px' sx={{ background: COLORS.BORDER_GRAY, ml: '16px' }} />
          </Grid>
          <Grid
            item
            container
            justifyContent='start'
            alignItems='center'
            direction='row'
            whiteSpace='normal'
            wrap='nowrap'
          >
            <EndWaypointIcon fill={COLORS.MAIN_BLUE} />
            <Controller
              control={control}
              name={keys.endWaypoint}
              render={({ field: { onChange, value } }) => (
                <AddressPOILookup
                  textFeildProps={{
                    label: 'End point',
                    placeholder: 'Search for address',
                    error: !!errors[keys.endWaypoint],
                    helperText:
                      !!errors[keys.endWaypoint] && getErrorMessage(errors[keys.endWaypoint]!),
                    required: true
                  }}
                  value={getAddressPOILookupValue(value)}
                  onChange={(waypoint) =>
                    waypoint ? onChange({ ...waypoint, type: WAYPOINT_TYPE.END }) : onChange(null)
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Stops />
    </>
  )
}

export default AddEditRouteFormUI
