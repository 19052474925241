import React, { FC, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import PreviewIcon from '@mui/icons-material/Preview'
import Typography from '@mui/material/Typography'
import ButtonGroup from '@mui/material/ButtonGroup'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { COLORS } from '../theme'

import { useSnackbar } from 'notistack'

import ConfirmationDialog from '../components/UI/ConfirmationDialog'
import Switch from '../components/UI/Switch'
import TableWrapper from '../components/UI/TableWrapper'
import Search from '../components/UI/Search'
import RoutesUploadStepper from '../components/route/RoutesUploadStepper/RoutesUploadStepper'

import { Route, TABS, ToggleRoutePayload, WAYPOINT_TYPE, RemoveRoutePayload } from '../models/route'
import { useToggleRoute, useRoutes, useRemoveRoute } from '../queries/routes'
import { useTableData } from '../hooks/useTableData'
import { useUserMe } from '../queries/user'

const headers = [
    'Route number',
    'Route name',
    'Route description',
    'Start point',
    'End point',
    'Vehicle type',
    'Stops',
    'Route enabled',
    'Actions'
]

type RouteTableAggregation = Route & {
    startPoint: [string, string]
    endPoint: [string, string]
    stops: number
}
type TableData = { totalPages: number; slicedData: Route[]; totalItems: number; page: number }

const Routes: FC = () => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { data: userMe } = useUserMe()
    const { status, data: routes, isRefetching } = useRoutes()
    const [removeRoute, setRemoveRoute] = useState<null | Route>(null)
    const [search, setSearch] = useState('')
    const [isUpdating, setIsUpdating] = useState(false)
    const { totalPages, slicedData, totalItems, page }: TableData = useTableData({
        search,
        data: routes,
        keysToSearch: ['routeName', 'routeNumber', 'description']
    })

    const routesTableAggregation = useMemo(() => {
        return slicedData.map(({ waypoints, ...rest }) => {
            const firstWaypoint = waypoints.find(({ type }) => type === WAYPOINT_TYPE.START)
            const lastWaypoint = waypoints.find(({ type }) => type === WAYPOINT_TYPE.END)

            const routeTableAggregation: RouteTableAggregation = {
                ...rest,
                waypoints,
                startPoint: ['Empty', 'Empty'],
                endPoint: ['Empty', 'Empty'],
                stops: waypoints.filter(({ type }) => type === WAYPOINT_TYPE.STOP).length // - Only stops
            }

            if (firstWaypoint) {
                const firstWaypointAfterSplit = firstWaypoint.name.split(',')
                const startPoint: [string, string] = [
                    firstWaypointAfterSplit[0],
                    firstWaypointAfterSplit.slice(1).join(',')
                ]
                routeTableAggregation.startPoint = startPoint
            }
            if (lastWaypoint) {
                const lastWaypointAfterSplit = lastWaypoint.name.split(',')
                const endPoint: [string, string] = [
                    lastWaypointAfterSplit[0],
                    lastWaypointAfterSplit.slice(1).join(',')
                ]
                routeTableAggregation.endPoint = endPoint
            }

            return routeTableAggregation
        })
    }, [slicedData])
    const state = useMemo(() => `/routes?page=${page}`, [page])

    const { mutateAsync: toggleRouteAsync } = useToggleRoute()
    const { mutateAsync: removeRouteAsync } = useRemoveRoute()

    const toggleRoute = async ({ id, routeName, enabled }: Route) => {
        enqueueSnackbar(`The request is sent.`, { variant: 'info' })
        setIsUpdating(true)
        try {
            const toggleRoutePayload: ToggleRoutePayload = { id }
            await toggleRouteAsync(toggleRoutePayload)
            const state = !enabled ? 'enabled' : 'disabled'
            enqueueSnackbar(`Route has been successfully ${state}.`, {
                variant: 'success'
            })
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    const onRemoveConfirm = async () => {
        setIsUpdating(true)
        try {
            if (!removeRoute) throw new Error()
            const { id } = removeRoute
            const removeRoutePayload: RemoveRoutePayload = { id }
            await removeRouteAsync(removeRoutePayload)
            enqueueSnackbar(`Route has been successfully removed.`, {
                variant: 'success'
            })
            setRemoveRoute(null)
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    const rowsNode = routesTableAggregation.map((route) => (
        <TableRow
            key={route.id}
            sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                height: '70px',
                verticalAlign: 'top'
            }}
        >
            <TableCell component='th' scope='row'>
                <Typography variant='subheadline'>
                    {route.routeNumber ? `${route.routeNumber}` : '-'}
                </Typography>
            </TableCell>

            <TableCell align='left'>
                <Typography variant='subheadline'>{route.routeName}</Typography>
            </TableCell>
            <TableCell align='left' sx={{ maxWidth: '300px', wordWrap: 'break-word' }}>
                <Typography variant='subheadline'>{route.description}</Typography>
            </TableCell>
            <TableCell align='left'>
                <Typography variant='subheadline'>{route.startPoint[0]}</Typography>
                <br />
                <Typography variant='subheadline' color='secondary'>
                    {route.startPoint[1]}
                </Typography>
            </TableCell>
            <TableCell align='left'>
                <Typography variant='subheadline'>{route.endPoint[0]}</Typography>
                <br />
                <Typography variant='subheadline' color='secondary' paddingTop={'40px'}>
                    {route.endPoint[1]}
                </Typography>
            </TableCell>
            <TableCell align='left'>
                {route.vehicles.map(({ type, id }) => (
                    <Typography variant='subheadline' key={id}>
                        {type}
                        <br />
                    </Typography>
                ))}
            </TableCell>
            <TableCell align='left'>
                <Typography variant='subheadline'>{route.stops}</Typography>
            </TableCell>
            <TableCell align='left'>
                <Switch checked={route.enabled} onChange={() => toggleRoute(route)} />
            </TableCell>
            <TableCell>
                <ButtonGroup variant='text' aria-label='outlined primary button group'>
                    <IconButton color='primary' onClick={() => navigate(`/routes/${route.id}`, { state })}>
                        <PreviewIcon />
                    </IconButton>
                    <IconButton
                        color='primary'
                        onClick={() => navigate(`/routes/${route.id}/edit`, { state })}
                        disabled={isUpdating || isRefetching}
                    >
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                    <IconButton
                        color='error'
                        onClick={() => setRemoveRoute(route)}
                        disabled={isUpdating || isRefetching}
                    >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </ButtonGroup>
            </TableCell>
        </TableRow>
    ))

    return (
        <>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Search title='Routes' onChangeSearch={(data: string) => setSearch(data.toLowerCase())} />
                <Grid container item direction='row' alignItems='center' width='auto' padding={'16px 12px'}>
                    <RoutesUploadStepper>
                        <Button
                            sx={{ marginRight: '8px' }}
                            variant='outlined'
                            startIcon={<CloudUploadOutlinedIcon />}
                        >
                            Upload file with routes
                        </Button>
                    </RoutesUploadStepper>

                    <Button
                        sx={{ background: userMe?.customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399' }}
                        onClick={() => navigate('/routes/add', { state })}
                        variant='contained'
                        startIcon={<AddIcon />}
                    >
                        Add new route
                    </Button>
                </Grid>
            </Grid>
            <Tabs value={TABS.ALL_ROUTES}>
                <Tab
                    value={TABS.ALL_ROUTES}
                    label={<Typography variant='textBold'>All routes</Typography>}
                ></Tab>
                <Tab
                    value={TABS.ADHOC_HAZARDS}
                    label={<Typography variant='textBold'>Adhoc Hazards</Typography>}
                    onClick={() => navigate('/routes/hazards?page=1')}
                />
            </Tabs>
            <TableWrapper
                isSuccess={status === 'success'}
                emptyResultTitle='No routes found'
                totalPages={totalPages}
                totalItems={totalItems}
                isUpdating={isUpdating || isRefetching}
            >
                <>
                    <TableHead>
                        <TableRow sx={{ verticalAlign: 'bottom' }}>
                            {headers.map((label, i, arr) => (
                                <TableCell align={i === arr.length - 1 ? 'center' : 'left'} key={label}>
                                    <Typography variant='subheadlineBold' color='secondary'>
                                        {label}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rowsNode}</TableBody>
                </>
            </TableWrapper>
            <ConfirmationDialog
                customer={userMe?.customer}
                open={!!removeRoute}
                onClose={() => setRemoveRoute(null)}
                onConfirm={onRemoveConfirm}
            />
        </>
    )
}

export default Routes
