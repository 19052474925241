import React, { FC } from 'react'
import { useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { COLORS } from '../../theme'

type CloseSnackbarActionProps = {
  id: string | number
}

const CloseSnackbarAction: FC<CloseSnackbarActionProps> = ({ id }) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton
      onClick={() => {
        closeSnackbar(id)
      }}
    >
      <CloseIcon htmlColor={COLORS.WHITE} />
    </IconButton>
  )
}

export default CloseSnackbarAction
