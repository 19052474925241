import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { COLORS } from '../../../theme'

const circleStyles: React.CSSProperties = {
  background: COLORS.MAIN_BLUE,
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  textAlign: 'center',
  minWidth: '24px'
}

type StopProps = {
  label: [string, string]
  number: number
}

const Stop: FC<StopProps> = ({ label, number }) => (
  <Grid
    container
    justifyContent='start'
    alignItems='flex-start'
    direction='row'
    mb='12px'
    mr='32px'
    whiteSpace='normal'
    wrap='nowrap'
    width='300px'
  >
    <Box sx={circleStyles}>
      <Typography color='white' variant='subheadlineBold'>
        {number}
      </Typography>
    </Box>
    <Grid
      container
      justifyContent='start'
      alignItems='flex-start'
      direction='column'
      ml='16px'
      width='auto'
    >
      <Typography variant='textBold'>{label[0]}</Typography>
      <Typography mt='2px' variant='text' color='secondary'>
        {label[1]}
      </Typography>
    </Grid>
  </Grid>
)

export default Stop
