import React, { FC } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Autocomplete from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Vehicle } from '../../models/vehicle'
import { useVehicles } from '../../queries/vehicles'

type VehicleLookupProps = {
  value: Vehicle[]
  onChange: (vehicl: Vehicle[]) => void
  textFeildProps?: TextFieldProps
}

const VehicleLookup: FC<VehicleLookupProps> = ({ textFeildProps, onChange, value }) => {
  const { data: vehicles, isFetching } = useVehicles()

  return (
    <Autocomplete
      multiple
      fullWidth
      getOptionLabel={(option) => option.name ?? 'No name'}
      loading={isFetching}
      options={vehicles ?? []}
      clearIcon={<HighlightOffIcon />}
      popupIcon={null}
      value={value}
      onChange={(e, v) => {
        onChange(v)
      }}
      isOptionEqualToValue={({ id: optionId }, { id: valueId }) => optionId === valueId}
      renderOption={(props, { id, name }) => (
        <li {...props} key={id}>
          {name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} {...textFeildProps} required label='Vehicle type' />
      )}
    />
  )
}

export default VehicleLookup
