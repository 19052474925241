import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'

import { Route } from '../../../models/route'
import { COLORS } from '../../../theme'

type VehiclesProps = {
  vehicles: Route['vehicles']
}

const Vehicles: FC<VehiclesProps> = ({ vehicles }) => (
  <Grid container justifyContent='start' alignItems='center' direction='row'>
    <AirportShuttleIcon
      fontSize='large'
      sx={{
        padding: '5px',
        background: COLORS.MAIN_BLUE,
        color: COLORS.WHITE,
        borderRadius: '4px'
      }}
    />
    <Grid
      container
      justifyContent='start'
      alignItems='flex-start'
      direction='row'
      ml='16px'
      width='calc(100% - 60px)'
    >
      {vehicles.map(({ type, id }, index, arr) => (
        <Typography variant='textBold' key={id}>
          {type}
          {arr.length > 1 && index + 1 !== arr.length && <>,&nbsp;</>}
        </Typography>
      ))}
    </Grid>
  </Grid>
)

export default Vehicles
