import React, { FC, useState, useMemo, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import CheckIcon from '@mui/icons-material/Check'

import * as azureMapsRest from 'azure-maps-rest'

import { useSnackbar } from 'notistack'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useLocalStorage } from 'usehooks-ts'

import {
    defaultValuesRouteForm,
    keys,
    routeFormSchema,
    RouteFormSchema,
    RouteGeoJson,
    VehicleCharacteristicsForRoute
} from '../components/route/RouteAddEdit/routeFormModel'
import AddEditRouteFormUI from '../components/route/RouteAddEdit/AddEditRouteForm'
import AddEditMap, { AddEditMapProps } from '../components/route/RouteAddEdit/AddEditMap'
import { Actions } from '../components/route/RoutesUploadStepper/actionsModel'
//@ts-ignore
import CircularProgress from '../components/UI/CircularProgress'
import { drawerWidth, closedDrawerWidth, toolbarHeight } from '../layouts/General'

import { COLORS } from '../theme'
import { useUpdateRoute, useAddRoute } from '../queries/routes'
import { useUserMe } from '../queries/user'
import { useRoute } from '../queries/routes'
import { Waypoint, WAYPOINT_TYPE } from '../models/route'
import { pipeline } from '../setupPipeline'
import { useGoBack } from '../hooks/useGoBack'

const routeURL = new azureMapsRest.RouteURL(pipeline)
export const formWidth = 410

const RouteAddEdit: FC = () => {
    const [routeGeoJson, setRouteGeoJson] = useState<RouteGeoJson | null>(null)
    const [isLeftBarOpen] = useLocalStorage('OPEN_LEFT_BAR', false)
    const [countActiveMapActions, setCountActiveMapActions] = useState<number>(0) // if we have more than 0 active action we should show a linear loader
    const { data: userMe } = useUserMe()
    const { backPath } = useGoBack()
    const { enqueueSnackbar } = useSnackbar()
    const { id } = useParams()
    const { status, data: route, isFetching, refetch } = useRoute(id)
    const navigate = useNavigate()

    const methods = useForm<RouteFormSchema>({
        defaultValues: defaultValuesRouteForm,
        resolver: zodResolver(routeFormSchema)
    })

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors, isValidating, isSubmitting, isSubmitSuccessful }
    } = methods

    const arrayMethods = useFieldArray({
        control,
        name: keys.intermediateWaypoints
    })

    const { remove, insert, update } = arrayMethods

    const intermediateWaypoints = useWatch({ control, name: keys.intermediateWaypoints })
    const startWaypoint = useWatch({ control, name: keys.startWaypoint })
    const endWaypoint = useWatch({ control, name: keys.endWaypoint })
    const vehicles = useWatch({ control, name: keys.vehicles })

    useEffect(() => {
        if (id && userMe?.options) {
            refetch()
        }
    }, [id, userMe])

    useEffect(() => {
        let newRouteGeoJson: RouteGeoJson = {
            routePath: [],
            previewRouteGeoJson: null,
            currentRouteGeoJson: null
        }
        let routeForm: RouteFormSchema = {
            routeName: '',
            routeNumber: '',
            description: '',
            startWaypoint: null,
            endWaypoint: null,
            intermediateWaypoints: [],
            vehicles: []
        }

        if (id && route && +id === route.id) {
            const { waypoints, routeName, description, routeGeoJson, vehicles, routeNumber } = route

            const startWaypoint = waypoints.find(({ type }) => type === WAYPOINT_TYPE.START)
            const endWaypoint = waypoints.find(({ type }) => type === WAYPOINT_TYPE.END)
            const intermediateWaypoints: Waypoint[] = waypoints.filter(
                ({ type }) => type === WAYPOINT_TYPE.EXTRA || type === WAYPOINT_TYPE.STOP
            )

            routeForm = {
                routeName,
                routeNumber,
                description,
                startWaypoint: startWaypoint ?? null,
                endWaypoint: endWaypoint ?? null,
                intermediateWaypoints: intermediateWaypoints,
                vehicles
            }
            newRouteGeoJson = {
                routePath: [],
                previewRouteGeoJson: routeGeoJson,
                currentRouteGeoJson: routeGeoJson
            }
        }
        reset(routeForm)
        setRouteGeoJson(newRouteGeoJson)
    }, [route, id])

    const vehicleCharacteristicsForRoute: VehicleCharacteristicsForRoute = useMemo(() => {
        const {
            maxWeight: weight,
            maxWidth: width,
            maxHeight: height,
            maxLength: length
        } = vehicles.reduce(
            (acc, vehicle) => {
                if (!vehicle) return acc
                const { weight, width, height, length } = vehicle
                if (weight && acc.maxWeight < weight) {
                    acc.maxWeight = weight
                }
                if (width && acc.maxWidth < width) {
                    acc.maxWidth = width
                }
                if (height && acc.maxHeight < height) {
                    acc.maxHeight = height
                }
                if (length && acc.maxLength < length) {
                    acc.maxLength = length
                }
                return acc
            },
            { maxWeight: 0, maxWidth: 0, maxHeight: 0, maxLength: 0 }
        )

        return {
            weight,
            width,
            height,
            length
        }
    }, [vehicles, id])

    const { mutateAsync: updateRouteAsync } = useUpdateRoute()
    const { mutateAsync: addRouteAsync } = useAddRoute()

    useEffect(() => {
        const waypoints = [startWaypoint, ...intermediateWaypoints, endWaypoint].filter(
            (v): v is Waypoint => !!v
        )
        calculateRouteDirections(waypoints, vehicleCharacteristicsForRoute)
    }, [startWaypoint, endWaypoint, intermediateWaypoints, vehicleCharacteristicsForRoute])

    const mapRouteProps = useMemo(() => {
        if (routeGeoJson) {
            const { previewRouteGeoJson, currentRouteGeoJson, routePath } = routeGeoJson
            const props: Omit<AddEditMapProps, 'countActiveMapActions' | 'actionMapResolver'> = {
                previewRouteGeoJson,
                currentRouteGeoJson,
                routePath: [...routePath],
                waypoints: [startWaypoint, ...intermediateWaypoints, endWaypoint].filter(
                    (v): v is Waypoint => !!v
                )
            }
            return props
        }
        return null
    }, [routeGeoJson, startWaypoint, endWaypoint, intermediateWaypoints])

    const actionMapResolver = useCallback(
        (action: Actions) => {
            switch (action.type) {
                case 'StartActionActionType': {
                    setCountActiveMapActions((count) => count + 1)
                    break
                }
                case 'EndActionActionType': {
                    setCountActiveMapActions((count) => count - 1)
                    break
                }
                case 'UpdateStopActionType': {
                    const { intermediateWaypointIndex, waypoint } = action.payload
                    update(intermediateWaypointIndex, waypoint)
                    break
                }
                case 'UpdateStartActionType': {
                    const { waypoint } = action.payload
                    setValue(keys.startWaypoint, waypoint)
                    break
                }
                case 'UpdateEndActionType': {
                    const { waypoint } = action.payload
                    setValue(keys.endWaypoint, waypoint)
                    break
                }
                case 'UpdateExtraActionType': {
                    const { intermediateWaypointIndex, waypoint } = action.payload
                    update(intermediateWaypointIndex, waypoint)
                    break
                }
                case 'RemoveStartActionType': {
                    setValue(keys.startWaypoint, null)
                    break
                }
                case 'RemoveEndActionType': {
                    setValue(keys.endWaypoint, null)
                    break
                }
                case 'RemoveExtraActionType': {
                    const { intermediateWaypointIndex } = action.payload
                    remove(intermediateWaypointIndex)
                    break
                }
                case 'RemoveStopActionType': {
                    const { intermediateWaypointIndex } = action.payload
                    remove(intermediateWaypointIndex)
                    break
                }
                case 'InsertExtraActionType': {
                    const { intermediateWaypointIndex, waypoint } = action.payload
                    insert(intermediateWaypointIndex, waypoint)
                    break
                }
            }
        },
        [setCountActiveMapActions, insert, remove, update, setValue]
    )

    const calculateRouteDirections = async (
        waypoints: Waypoint[],
        { width, length, height, weight }: VehicleCharacteristicsForRoute
    ) => {
        if (!routeGeoJson) return
        if (waypoints.length <= 1) {
            const geoJson: RouteGeoJson = {
                previewRouteGeoJson: routeGeoJson.previewRouteGeoJson,
                currentRouteGeoJson: null,
                routePath: []
            }
            setRouteGeoJson(geoJson)
            return
        }
        setCountActiveMapActions((count) => count + 1)
        try {
            const response = await routeURL.calculateRouteDirections(
                azureMapsRest.Aborter.timeout(10000),
                waypoints.map(({ point }) => point),
                {
                    vehicleWidth: width ? width : undefined,
                    vehicleLength: length ? length : undefined,
                    vehicleHeight: height ? height : undefined,
                    vehicleWeight: weight ? weight : undefined,
                    instructionsType: azureMapsRest.Models.RouteInstructionsType.Text,
                    travelMode: azureMapsRest.Models.TravelMode.Bus
                }
            )

            const data = response.geojson.getFeatures()
            const routePath = data.features[0].geometry.coordinates.flatMap(
                (partRouteByLine: number[][]) => partRouteByLine
            )

            const instructions: azureMapsRest.Models.RouteResultInstruction[] =
                data.features[0].properties!.guidance.instructions

            const instructionsPointIndexForRemovingAsExtraWaypoint = instructions
                .filter(
                    ({ instructionType }) =>
                        instructionType === azureMapsRest.Models.GuidanceInstructionType.LOCATIONWAYPOINT
                )
                //The pointIndex is in response, but not in the model of RouteResultInstruction
                //@ts-ignore
                .map<number | null>(({ pointIndex }, index) => {
                    const onlyIntermediateWaypoint = waypoints.filter(
                        ({ type }) => type === WAYPOINT_TYPE.EXTRA || type === WAYPOINT_TYPE.STOP
                    )
                    const intermediateWaypoint = onlyIntermediateWaypoint[index]
                    return intermediateWaypoint && intermediateWaypoint.type === WAYPOINT_TYPE.EXTRA
                        ? pointIndex
                        : null
                })
                .filter((v): v is number => !!v)

            const instructionsWithoutExtraWaypointAsStop = instructions.filter(
                //The pointInfex is in response, but not in the model of RouteResultInstruction
                //@ts-ignore
                ({ pointIndex, instructionType }) =>
                    !(
                        instructionType === azureMapsRest.Models.GuidanceInstructionType.LOCATIONWAYPOINT &&
                        instructionsPointIndexForRemovingAsExtraWaypoint.some(
                            //The pointInfex is in response, but not in the model of RouteResultInstruction
                            //@ts-ignore
                            (pointIndexRemoving) => pointIndexRemoving === pointIndex
                        )
                    )
            )

            data.features[0].properties!.guidance.instructions = instructionsWithoutExtraWaypointAsStop

            const geoJson: RouteGeoJson = {
                previewRouteGeoJson: routeGeoJson.previewRouteGeoJson,
                currentRouteGeoJson: data,
                routePath
            }

            setRouteGeoJson(geoJson)
            setCountActiveMapActions((count) => count - 1)
        } catch {
            setCountActiveMapActions((count) => count - 1)
        }
    }

    const onSubmit = handleSubmit(async (routeForm: NonNullable<RouteFormSchema>) => {
        const {
            routeName,
            description,
            routeNumber,
            vehicles,
            startWaypoint,
            endWaypoint,
            intermediateWaypoints
        } = routeForm

        try {
            // const imageUri = await getImage()
            const waypointsJson = JSON.stringify([startWaypoint, ...intermediateWaypoints, endWaypoint])
            const geoJson = JSON.stringify(routeGeoJson!.currentRouteGeoJson)
            const payload = {
                routeName,
                description,
                routeNumber,
                vehicles: vehicles,
                waypoints: waypointsJson,
                routeGeoJson: geoJson,
                imageUri: ''
            }
            if (id && route) {
                await updateRouteAsync({ ...payload, id: +id })
            } else {
                await addRouteAsync(payload)
            }
            enqueueSnackbar(`Route has been successfully ${id ? 'updated' : 'added'}.`, {
                variant: 'success'
            })
            navigate(backPath)
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
    })

    // const getImage = async () => {
    //   const layersForHidding = ROUTE_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.layers.getLayers().filter(
    //     //@ts-ignore
    //     ({ id }) =>
    //       [hazardLayerId, oldRouteViewLayerId, hazardPointsId].some((layerId) => id === layerId)
    //   )

    //   await Promise.all(
    //     //@ts-ignore
    //     layersForHidding.map((l) => Promise.resolve(l.setOptions({ visible: false })))
    //   )
    //   ROUTE_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.setCamera({
    //     bounds: ROUTE_ADD_EDIT_NOT_REACTIVE_DATA.routeGeoJson!.bbox,
    //     padding: 50
    //   })

    //   return new Promise<string>((resolve) => {
    //     setTimeout(async () => {
    //       const imgUri = await MapImageExporter.getDataUri(ROUTE_ADD_EDIT_NOT_REACTIVE_DATA.MAP!)
    //       resolve(imgUri)
    //     }, 50)
    //   })
    // }

    return (
        <>
            {status === 'loading' && isFetching && id && (
                <Grid container height='100%' alignItems='center' justifyContent='center'>
                    <CircularProgress sx={{ marginBottom: '150px' }} size={70} thickness={5} disableShrink />
                </Grid>
            )}
            {((id && status === 'success') || !id) && (
                <>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{
                            background: 'white',
                            width: `calc(100% - ${isLeftBarOpen ? drawerWidth : closedDrawerWidth}px - 16px)`,
                            marginTop: '-16px',
                            right: 0,
                            top: `${toolbarHeight + 16}px`,
                            zIndex: 100,
                            position: 'fixed',
                            padding: '16px 12px',
                            transition: 'top 1s easy'
                        }}
                    >
                        <Grid
                            item
                            container
                            direction='row'
                            justifyContent='start'
                            alignItems='center'
                            width='auto'
                        >
                            <IconButton color='secondary' onClick={() => navigate(backPath)}>
                                <ArrowBackOutlinedIcon />
                            </IconButton>
                            <Typography variant='title1Bold' mr='24px' ml='20px'>
                                {id ? 'Edit route' : 'Add new route'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction='row'
                            justifyContent='end'
                            alignItems='center'
                            width='auto'
                        >
                            <Button
                                sx={{ background: userMe?.customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399' }}
                                disabled={
                                    !!Object.keys(errors).length || isValidating || isSubmitting || isSubmitSuccessful
                                }
                                onClick={onSubmit}
                                variant='contained'
                                type='submit'
                                startIcon={<CheckIcon />}
                            >
                                {id ? 'Save changes' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        height='100%'
                        padding={'8px 12px 0 12px'}
                        flexDirection='row'
                        marginTop={'72px'}
                    >
                        <FormProvider {...methods} {...arrayMethods}>
                            <Grid
                                container
                                flexDirection='column'
                                alignItems='start'
                                justifyContent='start'
                                p='24px 24px 0 16px'
                                sx={{
                                    width: `${formWidth}px`,
                                    background: COLORS.LIGHT_GRAY
                                }}
                            >
                                <AddEditRouteFormUI />
                            </Grid>
                        </FormProvider>
                        <Grid
                            sx={{
                                width: `calc(100% - ${isLeftBarOpen ? drawerWidth : closedDrawerWidth
                                    }px - ${formWidth}px - 48px)`,
                                height: `calc(100vh - ${toolbarHeight}px - 121px)`,
                                right: '16px',
                                position: 'fixed'
                            }}
                        >
                            <AddEditMap
                                waypoints={mapRouteProps?.waypoints ?? null}
                                previewRouteGeoJson={mapRouteProps?.previewRouteGeoJson ?? null}
                                currentRouteGeoJson={mapRouteProps?.currentRouteGeoJson ?? null}
                                routePath={mapRouteProps?.routePath ?? []}
                                countActiveMapActions={countActiveMapActions}
                                actionMapResolver={actionMapResolver}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}

export default RouteAddEdit
