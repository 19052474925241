import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { COLORS } from '../../../theme'
import { StartWaypointIcon } from '../../../assets/icons/StartWaypointIcon'
import { EndWaypointIcon } from '../../../assets/icons/EndWaypointIcon'

type StartOrEndWaypointProps = {
  label: [string, string]
  type: 'A' | 'B'
}

const StartOrEndWaypoint: FC<StartOrEndWaypointProps> = ({ label, type }) => (
  <Grid
    container
    justifyContent='start'
    alignItems='flex-start'
    direction='row'
    whiteSpace='normal'
    wrap='nowrap'
  >
    {type === 'A' && <StartWaypointIcon fill={COLORS.MAIN_BLUE} />}
    {type === 'B' && <EndWaypointIcon fill={COLORS.MAIN_BLUE} />}
    <Grid
      container
      justifyContent='start'
      alignItems='flex-start'
      direction='column'
      ml='16px'
      width='auto'
    >
      <Typography variant='textBold'>{label[0]}</Typography>
      <Typography mt='2px' variant='text' color='secondary'>
        {label[1]}
      </Typography>
    </Grid>
  </Grid>
)

export default StartOrEndWaypoint
