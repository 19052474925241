import React, { PropsWithChildren, FC } from 'react'
import EmptyTableResult from './EmptyTableResult'
import CircularProgress from './CircularProgress'
import Pagination from './Pagination'
import Table from '@mui/material/Table'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'

type TableProps = {
  isSuccess: boolean
  isUpdating: boolean
  totalPages: number
  emptyResultTitle: string
  totalItems: number
} & PropsWithChildren

const TableWrapper: FC<TableProps> = (props) => {
  const { isSuccess, emptyResultTitle, totalPages, children, totalItems, isUpdating } = props

  return totalPages ? (
    <>
      {isUpdating ? (
        <LinearProgress sx={{ margin: '16px 8px' }} />
      ) : (
        <Grid sx={{ margin: '18px 0px' }}></Grid>
      )}
      <Grid height='100%'>
        <Table sx={{ minWidth: 650, marginBottom: '40px' }} aria-label='simple table'>
          {children}
        </Table>
      </Grid>
      <Pagination count={totalPages} totalItems={totalItems} />
    </>
  ) : (
    <Grid container direction='row' justifyContent='center' alignContent='center' flexGrow='1'>
      {!isSuccess ? (
        <CircularProgress sx={{ marginBottom: '150px' }} size={70} thickness={5} disableShrink />
      ) : (
        <EmptyTableResult
          title={emptyResultTitle}
          subtitle='Add at least one new'
          marginBottom='150px'
        />
      )}
    </Grid>
  )
}

export default TableWrapper
