import React, { FC, useRef, useState, useEffect } from 'react'
import Box from '@mui/material/Box'

import * as azureMapsControl from 'azure-maps-control'

import { useLocalStorage } from 'usehooks-ts'

import { RouteGeoJson } from '../RouteAddEdit/routeFormModel'
import Backdrop from '../../UI/Backdrop'

import { createDatasourceInstance, createMapInstance, LONDON_BBOX } from '../../../utils/map'
import { hazardMarkerHTML, HTML_MARKER_BY_WAYPOINT_TYPE } from '../../../assets/htmlMarkers'
import { useHazards } from '../../../queries/hazard'
import { Route, WAYPOINT_TYPE } from '../../../models/route'
import { HAZARD_WAYPOINT_TYPE } from '../../../models/hazard'
import { COLORS } from '../../../theme'

enum LAYER_ID {
  LAYER_ROUTE_VIEW_ID = 'LAYER_NEW_ROUTE_VIEW_ID',
  LAYER_HAZARD_ID = 'LAYER_HAZARD_ID',
  LAYER_HAZARD_POINTS_ID = 'LAYER_HAZARD_POINTS_ID'
}

enum DATA_SOURCE_ID {
  DATA_SOURCE_ROUTE_ID = 'DATA_SOURCE_ROUTE_ID',
  DATA_SOURCE_HAZARDS_ID = 'DATA_SOURCE_HAZARDS_ID',
  DATA_SOURCE_HAZARD_POINTS_ID = 'DATA_SOURCE_HAZARD_POINTS_ID'
}

export type OverviewMapProps = {
  routeGeoJson: Route['routeGeoJson'] | RouteGeoJson['previewRouteGeoJson'] | null
  waypoints: Route['waypoints'] | null
}

const OverviewMap: FC<OverviewMapProps> = ({ routeGeoJson, waypoints }) => {
  const { data: AllHazards } = useHazards()
  const mapRef = useRef<HTMLDivElement>(null)
  const [mapIsLoading, setMapIsLoading] = useState(true)
  const [layersIsAdded, setLayersIsAdded] = useState(false)
  const [sourcesAreAdded, setSourcesAreAdded] = useState(false)
  const [map, setMap] = useState<azureMapsControl.Map | null>(null)
  const [routeDataSource, setRouteDataSource] = useState<azureMapsControl.source.DataSource | null>(
    null
  )
  const [hazardsDataSource, setHazardsDataSource] =
    useState<azureMapsControl.source.DataSource | null>(null)
  const [hazardPointsDataSource, setHazardPointsDataSource] =
    useState<azureMapsControl.source.DataSource | null>(null)
  const [isLeftBarOpen] = useLocalStorage('OPEN_LEFT_BAR', false)

  useEffect(() => {
    if (map) {
      map.resize()
    }
  }, [isLeftBarOpen])

  useEffect(() => {
    if (routeGeoJson && map) {
      const { bbox } = routeGeoJson
      map.setCamera({ bounds: bbox, padding: 50 })
    }
  }, [routeGeoJson, map])

  useEffect(() => {
    if (mapRef && mapRef.current) {
      const { current: mapContainer } = mapRef
      const newMapInstance = createMapInstance(mapContainer)

      newMapInstance.events.add('load', () => {
        const route = createDatasourceInstance(DATA_SOURCE_ID.DATA_SOURCE_ROUTE_ID)

        const hazards = createDatasourceInstance(DATA_SOURCE_ID.DATA_SOURCE_HAZARDS_ID)
        const hazardPoints = createDatasourceInstance(DATA_SOURCE_ID.DATA_SOURCE_HAZARD_POINTS_ID)

        newMapInstance.events.add('sourceadded', (source) => {
          const sources = newMapInstance.sources.getSources()

          const flag = Object.values(DATA_SOURCE_ID).every((id) =>
            sources.some((source) => source.getId() === id)
          )
          if (flag) {
            setRouteDataSource(route)
            setHazardsDataSource(hazards)
            setHazardPointsDataSource(hazardPoints)
          }
          setSourcesAreAdded(flag)
        })

        newMapInstance.sources.add([route, hazards, hazardPoints])
        //The creating data sources

        newMapInstance.setCamera({ bounds: LONDON_BBOX, padding: 50 })

        setMapIsLoading(false)
        setMap(newMapInstance)
      })
    }
  }, [mapRef.current])

  // Add the layers to the map
  useEffect(() => {
    if (
      !mapIsLoading &&
      sourcesAreAdded &&
      map &&
      routeDataSource &&
      hazardsDataSource &&
      hazardPointsDataSource
    ) {
      //The creating layers

      const routeViewLayer = new azureMapsControl.layer.LineLayer(
        routeDataSource,
        LAYER_ID.LAYER_ROUTE_VIEW_ID,
        {
          strokeColor: COLORS.MAIN_BLUE,
          strokeWidth: 10,
          lineJoin: 'round',
          lineCap: 'square'
        }
      )

      const hazardLayer = new azureMapsControl.layer.LineLayer(
        hazardsDataSource,
        LAYER_ID.LAYER_HAZARD_ID,
        {
          strokeColor: COLORS.RED,
          strokeWidth: 5,
          lineJoin: 'round',
          lineCap: 'round'
        }
      )

      const hazardPoints = new azureMapsControl.layer.SymbolLayer(
        hazardPointsDataSource,
        LAYER_ID.LAYER_HAZARD_POINTS_ID,
        {
          iconOptions: {
            image: 'hazard',
            anchor: 'center'
          }
        }
      )

      const layers = [routeViewLayer, hazardLayer, hazardPoints]

      //The creating layers
      map.events.add('layeradded', () => {
        const l = map.layers.getLayers()
        const flag = Object.values(LAYER_ID).every((id) => l.some((l1) => l1.getId() === id))
        setLayersIsAdded(flag)
      })

      map.layers.add(layers, 'labels')
    }
  }, [
    mapIsLoading,
    sourcesAreAdded,
    map,
    routeDataSource,
    hazardsDataSource,
    hazardPointsDataSource
  ])
  // Add the layers to the map

  // Add the hazards to the map
  useEffect(() => {
    if (
      !mapIsLoading &&
      AllHazards &&
      AllHazards.length &&
      layersIsAdded &&
      map &&
      hazardPointsDataSource &&
      hazardsDataSource
    ) {
      map.imageSprite.add('hazard', hazardMarkerHTML).then(() => {
        const hazards = AllHazards.filter(({ enabled }) => enabled)
        hazards
          .map(({ waypoints }) =>
            waypoints.filter(
              ({ type }) => type === HAZARD_WAYPOINT_TYPE.END || type === HAZARD_WAYPOINT_TYPE.START
            )
          )
          .flatMap((v) => v)
          .forEach(({ point }) =>
            hazardPointsDataSource!.add(
              new azureMapsControl.data.Feature(new azureMapsControl.data.Point(point))
            )
          )

        hazards.forEach(({ routeGeoJson }) => {
          hazardsDataSource!.add(routeGeoJson)
        })
      })
    }
  }, [mapIsLoading, AllHazards, layersIsAdded, hazardsDataSource, hazardPointsDataSource, map])
  // Add the hazards to the map

  // Add markers(Waypoints) to the map
  useEffect(() => {
    if (!mapIsLoading && map) {
      map.markers.clear()
      if (waypoints && waypoints.length) {
        const intermediateWaypoints = waypoints.filter(
          ({ type }) => type === WAYPOINT_TYPE.EXTRA || type === WAYPOINT_TYPE.STOP
        )
        const markers = waypoints.map(({ point, type, name }, index) => {
          let text = ''

          if (type === WAYPOINT_TYPE.STOP) {
            const stopOrderNumber = waypoints
              .filter(({ type }) => type === WAYPOINT_TYPE.STOP)
              .findIndex(
                ({ name: stopName, point: stopPoint }) =>
                  `${stopPoint[0]}_${stopPoint[1]}` === `${point[0]}_${point[1]}`
              )
            text = `${stopOrderNumber + 1}`
          } else if (type === WAYPOINT_TYPE.EXTRA) {
            const lastNotExtraBeforeIndex = waypoints
              .slice(0, index)
              //@ts-ignore
              .findLastIndex(({ type }) => type !== WAYPOINT_TYPE.EXTRA)
            if (lastNotExtraBeforeIndex === -1) {
              text = `0-${index + 1}`
            } else {
              if (waypoints[lastNotExtraBeforeIndex]!.type === WAYPOINT_TYPE.START) {
                text = `A-${index}`
              } else {
                const stops = intermediateWaypoints.filter(
                  (intermediateWaypoint) => intermediateWaypoint.type === WAYPOINT_TYPE.STOP
                )
                const stopDirectionNumber = stops.findIndex(
                  ({ name, point: stopPoint }) =>
                    `${stopPoint[0]}_${stopPoint[1]}` ===
                    `${waypoints[lastNotExtraBeforeIndex]!.point[0]}_${
                      waypoints[lastNotExtraBeforeIndex]!.point[1]
                    }`
                )

                text = `${stopDirectionNumber + 1}-${index - lastNotExtraBeforeIndex}`
              }
            }
          }

          const marker = new azureMapsControl.HtmlMarker({
            htmlContent: HTML_MARKER_BY_WAYPOINT_TYPE[type],
            text: text,
            anchor: 'center',
            position: point,
            draggable: false
          })

          return marker
        })

        map.markers.add(markers)
      }
    }
  }, [mapIsLoading, waypoints, map])
  // Add markers(Waypoints) to the map

  //Add route to the map
  useEffect(() => {
    if (!mapIsLoading && map && routeDataSource) {
      routeDataSource.clear()
      if (routeGeoJson) {
        routeDataSource.add(routeGeoJson)
      }
    }
  }, [mapIsLoading, routeGeoJson, map])
  //Add route to the map

  return (
    <Box
      ref={mapRef}
      borderRadius='8px'
      sx={{ position: 'relative', height: '100%', width: '100%' }}
    >
      {mapIsLoading && <Backdrop position='absolute' />}
    </Box>
  )
}

export default OverviewMap
