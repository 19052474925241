import React, { FC, useState, useEffect, useRef, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import CheckIcon from '@mui/icons-material/Check'

import * as azureMapsControl from 'azure-maps-control'
import * as azureMapsRest from 'azure-maps-rest'
import * as z from 'zod'

import { useSnackbar } from 'notistack'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import CircularProgress from '../components/UI/CircularProgress'
import Backdrop from '../components/UI/Backdrop'
import AddressPOILookup, { AddressPOILookupValue } from '../components/lookups/AddressPOILookup'
import { ProcessDraggingRoutePayload } from '../components/route/RouteAddEdit/AddEditMap'
import { FormTextField } from '../components/UI/FormTextField'
import { toolbarHeight } from '../layouts/General'

import { COLORS } from '../theme'
import { getErrorMessage } from '../utils/reactHookForm'
import { pipeline } from '../setupPipeline'
import { createMapInstance, createDatasourceInstance, LONDON_BBOX } from '../utils/map'
import {
    HazardWaypointSchema,
    HazardWaypoint,
    Hazard,
    HAZARD_WAYPOINT_TYPE
} from '../models/hazard'
import { searchReverse } from '../queries/azureMaps'
import { useHazard, useAddHazard, useUpdateHazard } from '../queries/hazard'
import { useUserMe } from '../queries/user'
import { EndWaypointIcon } from '../assets/icons/EndWaypointIcon'
import { StartWaypointIcon } from '../assets/icons/StartWaypointIcon'
import { namesCheck, str, textCheck } from '../assets/constans/formValidation'
import { HTML_MARKER_HAZARD_BY_WAYPOINT_TYPE } from '../assets/htmlMarkers'
import { useGoBack } from '../hooks/useGoBack'

const mapContainerStyle: React.CSSProperties = {
    width: `calc(100% - 426px)`,
    height: `calc(100vh - ${toolbarHeight}px - 121px)`,
    position: 'sticky',
    top: '121px'
}

const waypointFormFieldSchema = HazardWaypointSchema.strict()
    .nullable()
    .transform((value, ctx) => {
        if (value === null) {
            ctx.addIssue({
                code: 'custom',
                message: 'Required'
            })
        }
        return value
    })

const hazardFormSchema = z.object({
    name: namesCheck,
    description: textCheck.max(255, 'No more 255 characters').or(str.length(0)),
    startWaypoint: waypointFormFieldSchema,
    endWaypoint: waypointFormFieldSchema,
    intermediateWaypoints: z.array(waypointFormFieldSchema)
})

const keys = hazardFormSchema.keyof().Values

const routeURL = new azureMapsRest.RouteURL(pipeline)

export type HazardFormSchema = Pick<
    ReturnType<typeof hazardFormSchema['parse']>,
    'name' | 'description'
> & {
    startWaypoint: HazardWaypoint | null
    endWaypoint: HazardWaypoint | null
    intermediateWaypoints: HazardWaypoint[]
}

type HazardAddEditNotReactiveData = {
    MAP: azureMapsControl.Map | null
    DATA_SOURCE_HAZARD: azureMapsControl.source.DataSource | null
    DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING: azureMapsControl.source.DataSource | null
    routePath: azureMapsControl.data.Position[]
    processDraggingRoutePayload: ProcessDraggingRoutePayload | null
    routeGeoJson: Hazard['routeGeoJson'] | null
}

const HAZARD_ADD_EDIT_NOT_REACTIVE_DATA: HazardAddEditNotReactiveData = {
    MAP: null,
    DATA_SOURCE_HAZARD: null,
    DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING: null,
    routePath: [],
    processDraggingRoutePayload: null,
    routeGeoJson: null
}

const defaultValuesRouteForm: HazardFormSchema = {
    name: '',
    description: '',
    startWaypoint: null,
    endWaypoint: null,
    intermediateWaypoints: []
}

const HazardAddEdit: FC = () => {
    const mapRef = useRef<HTMLDivElement>(null)

    const [mapIsLoading, setMapIsLoading] = useState(true)
    const [isDataRefetched, setIsDataRefetched] = useState<boolean | null>(null)
    const [countActiveMapActions, setCountActiveMapActions] = useState<number>(0) // if we have more than 0 active action we should show a linear loader

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { id } = useParams()
    const { status, data: hazard, isFetching, refetch, isRefetching } = useHazard(id)
    const { data: userMe } = useUserMe()

    const {
        handleSubmit,
        setValue,
        reset,
        control,
        formState: { errors, isSubmitting, isValidating, isSubmitSuccessful }
    } = useForm<HazardFormSchema>({
        defaultValues: defaultValuesRouteForm,
        resolver: zodResolver(hazardFormSchema)
    })

    const { fields, append, prepend, remove, move, insert, update } = useFieldArray({
        control,
        name: keys.intermediateWaypoints
    })

    const startWaypoint = useWatch({ control, name: keys.startWaypoint })
    const endWaypoint = useWatch({ control, name: keys.endWaypoint })
    const intermediateWaypoints = useWatch({ control, name: keys.intermediateWaypoints })

    useEffect(
        () => () => {
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD?.clear()
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING?.clear()

            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD = null
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING = null
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload = null
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.routePath = []
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP = null
        },
        []
    )

    useEffect(() => {
        if (id && userMe?.options) {
            refetch()
        }
    }, [id, userMe])

    useEffect(() => {
        if (isFetching || isRefetching) {
            setIsDataRefetched(false)
        } else if (!isFetching && !isRefetching && isDataRefetched === false) {
            setIsDataRefetched(true)
        }
    }, [isFetching, isRefetching])

    useEffect(() => {
        if (!HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP) {
            if (id && hazard && +id === hazard.id && isDataRefetched) {
                if (hazard && mapRef && mapRef.current) {
                    //set form values

                    const { waypoints, name, description, routeGeoJson } = hazard

                    const startWaypoint = waypoints.find(({ type }) => type === HAZARD_WAYPOINT_TYPE.START)
                    const endWaypoint = waypoints.find(({ type }) => type === HAZARD_WAYPOINT_TYPE.END)

                    const intermediateWaypoints: HazardWaypoint[] = waypoints.filter(
                        ({ type }) => type === HAZARD_WAYPOINT_TYPE.EXTRA
                    )

                    const hazardFormState: HazardFormSchema = {
                        name,
                        description,
                        startWaypoint: startWaypoint ?? null,
                        endWaypoint: endWaypoint ?? null,
                        intermediateWaypoints
                    }

                    reset(hazardFormState)
                    //set form values

                    //init map
                    const { current: mapContainer } = mapRef
                    const newMapInstance = createMapInstance(mapContainer)

                    newMapInstance.events.add('ready', () => {
                        //The creating data sources
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD = createDatasourceInstance()
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING =
                            createDatasourceInstance()
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD.add(routeGeoJson)

                        newMapInstance.sources.add([
                            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD,
                            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING
                        ])
                        //The creating data sources

                        //The creating layers

                        const hazardViewLayer = new azureMapsControl.layer.LineLayer(
                            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD,
                            null!,
                            {
                                strokeColor: COLORS.RED,
                                strokeWidth: 5,
                                lineJoin: 'round',
                                lineCap: 'square'
                            }
                        )

                        const additionLineExtraCreatingLayer = new azureMapsControl.layer.LineLayer(
                            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING,
                            null!,
                            {
                                strokeColor: COLORS.RED,
                                strokeWidth: 5,
                                lineJoin: 'round',
                                lineCap: 'round'
                            }
                        )

                        newMapInstance.layers.add([hazardViewLayer, additionLineExtraCreatingLayer], 'labels')

                        //The creating layers

                        //The additing map events
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.events.add(
                            'mousedown',
                            hazardViewLayer,
                            hazardMouseDown
                        )
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.events.add('mousemove', mouseMoved)
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.events.add('mouseup', mouseUp)
                        //The additing map events

                        newMapInstance.setCamera({ bounds: routeGeoJson.bbox, padding: 50 })
                        setMapIsLoading(false)
                    })
                    HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP = newMapInstance
                    //init map
                }
            } else if (!id) {
                if (mapRef.current) {
                    //init map
                    const { current: mapContainer } = mapRef
                    const newMapInstance = createMapInstance(mapContainer)

                    newMapInstance.events.add('ready', () => {
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD = createDatasourceInstance()
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING =
                            createDatasourceInstance()

                        newMapInstance.sources.add([
                            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD,
                            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING
                        ])
                        //The creating data sources

                        //The creating layers
                        const hazardViewLayer = new azureMapsControl.layer.LineLayer(
                            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD,
                            null!,
                            {
                                strokeColor: COLORS.RED,
                                strokeWidth: 5,
                                lineJoin: 'round',
                                lineCap: 'square'
                            }
                        )

                        const additionLineExtraCreatingLayer = new azureMapsControl.layer.LineLayer(
                            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING,
                            null!,
                            {
                                strokeColor: COLORS.RED,
                                strokeWidth: 5,
                                lineJoin: 'round',
                                lineCap: 'round'
                            }
                        )

                        newMapInstance.layers.add([hazardViewLayer, additionLineExtraCreatingLayer], 'labels')

                        //The creating layers

                        //The additing map events
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.events.add(
                            'mousedown',
                            hazardViewLayer,
                            hazardMouseDown
                        )
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.events.add('mousemove', mouseMoved)
                        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.events.add('mouseup', mouseUp)
                        //The additing map events

                        newMapInstance.setCamera({ bounds: LONDON_BBOX, padding: 50 })
                        setMapIsLoading(false)
                    })
                    HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP = newMapInstance
                    //init map
                }
            }
        }
    }, [hazard, mapRef.current, isDataRefetched])

    useEffect(() => {
        if (
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP &&
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD &&
            !HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload &&
            !mapIsLoading
        ) {
            const waypoints = [startWaypoint, ...intermediateWaypoints, endWaypoint].filter(
                (stop): stop is HazardWaypoint => !!stop
            )

            calculateRouteDirections(waypoints)
        }
    }, [intermediateWaypoints, mapIsLoading])

    useEffect(() => {
        if (
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP &&
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD &&
            !HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload &&
            !mapIsLoading
        ) {
            const waypoints = [startWaypoint, ...intermediateWaypoints, endWaypoint].filter(
                (stop): stop is HazardWaypoint => !!stop
            )

            calculateRouteDirections(waypoints, true)
        }
    }, [startWaypoint, endWaypoint])

    const calculateRouteDirections = useCallback(
        async (waypoints: HazardWaypoint[], isNeedZoom = false) => {
            if (waypoints.length < 2) {
                const markers = buildMarkers(waypoints)
                HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.markers.clear()
                HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.markers.add(markers)
                HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD!.clear()

                return
            }
            setCountActiveMapActions((count) => count + 1)
            const response = await routeURL.calculateRouteDirections(
                azureMapsRest.Aborter.timeout(10000),
                waypoints.map(({ point }) => point)
            )

            const data = response.geojson.getFeatures()
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.routePath = data.features[0].geometry.coordinates.flatMap(
                (partRouteByLine: number[][]) => partRouteByLine
            )

            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.routeGeoJson = data

            const markers = buildMarkers(waypoints)
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.markers.clear()
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.markers.add(markers)

            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD!.clear()
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_HAZARD!.add(data)
            isNeedZoom &&
                HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.setCamera({
                    bounds: HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.routeGeoJson!.bbox,
                    padding: 50
                })
            setCountActiveMapActions((count) => count - 1)
        },
        []
    )

    const getNearestRouteIdx = (
        position: HazardWaypoint['point'] | azureMapsControl.data.Position
    ) => {
        const { routeIdx } = HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.routePath.reduce(
            (acc, point, index) => {
                const { maxDistance } = acc
                const d = azureMapsControl.math.getDistanceTo(position, point)
                if (d < maxDistance) {
                    acc.routeIdx = index
                    acc.maxDistance = d
                }
                return { ...acc }
            },
            { maxDistance: Infinity, routeIdx: 0 }
        )
        return routeIdx
    }

    const markerDragEnd = async (
        e: { target: azureMapsControl.HtmlMarker } & azureMapsControl.TargetedEvent
    ) => {
        setCountActiveMapActions((count) => count + 1)

        const { type, position, intermediateWaypointIndex }: azureMapsControl.HtmlMarkerOptions =
            e.target.getOptions()

        const waypoint: HazardWaypoint = {
            name: '',
            point: [position![0], position![1]],
            type: HAZARD_WAYPOINT_TYPE.START //default value
        }

        const response = await searchReverse(position!)
        const data = response.geojson.getFeatures()

        if (
            data.features.length > 0 &&
            data.features[0].properties &&
            data.features[0].properties.address &&
            data.features[0].properties.address.freeformAddress
        ) {
            //
            const {
                features: [
                    {
                        properties: {
                            address: { freeformAddress }
                        }
                    }
                ]
            } = data

            waypoint.name = freeformAddress
        }

        switch (type) {
            case HAZARD_WAYPOINT_TYPE.START: {
                waypoint.type = HAZARD_WAYPOINT_TYPE.START
                setValue(keys.startWaypoint, waypoint)
                break
            }
            case HAZARD_WAYPOINT_TYPE.END: {
                waypoint.type = HAZARD_WAYPOINT_TYPE.END
                setValue(keys.endWaypoint, waypoint)
                break
            }
            case HAZARD_WAYPOINT_TYPE.EXTRA: {
                waypoint.type = HAZARD_WAYPOINT_TYPE.EXTRA
                update(intermediateWaypointIndex, waypoint)
                break
            }
        }
        setCountActiveMapActions((count) => count - 1)
    }

    const buildMarkers = (waypoints: HazardWaypoint[]): azureMapsControl.HtmlMarker[] => {
        const intermediateWaypoints = waypoints.filter(
            ({ type }) => type === HAZARD_WAYPOINT_TYPE.EXTRA
        )
        const markers = waypoints.map(({ point, type }) => {
            const intermediateWaypointIndex = intermediateWaypoints.findIndex(
                ({ point: intermediateWaypointPoint }) =>
                    `${intermediateWaypointPoint[0]}_${intermediateWaypointPoint[1]}` ===
                    `${point[0]}_${point[1]}`
            )

            const routeIdx = getNearestRouteIdx(point)

            const additionMarkerOptions = {
                intermediateWaypointIndex:
                    intermediateWaypointIndex !== -1 ? intermediateWaypointIndex : undefined,
                type,
                routeIdx
            }

            const marker = new azureMapsControl.HtmlMarker({
                htmlContent: HTML_MARKER_HAZARD_BY_WAYPOINT_TYPE[type],
                anchor: 'center',
                position: point,
                draggable: true
            })
            //@ts-ignore
            marker.options = {
                //@ts-ignore
                ...marker.options,
                ...additionMarkerOptions
            }

            return marker
        })

        //@ts-ignore
        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.events.add('dragend', markers, markerDragEnd)

        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.events.add(
            //@ts-ignore
            'contextmenu',
            markers,
            removeHazardWaypointByMarkerRightClick
        )

        return markers
    }

    const removeHazardWaypointByMarkerRightClick = (
        e: { target: azureMapsControl.HtmlMarker } & azureMapsControl.TargetedEvent
    ) => {
        setCountActiveMapActions((count) => count + 1)

        const { type, intermediateWaypointIndex }: azureMapsControl.HtmlMarkerOptions =
            e.target.getOptions()

        switch (type) {
            case HAZARD_WAYPOINT_TYPE.START: {
                setValue(keys.startWaypoint, null)
                break
            }
            case HAZARD_WAYPOINT_TYPE.END: {
                setValue(keys.endWaypoint, null)
                break
            }
            case HAZARD_WAYPOINT_TYPE.EXTRA: {
                remove(intermediateWaypointIndex)
                break
            }
        }
        setCountActiveMapActions((count) => count - 1)
    }

    const getAddressPOILookupValue = useCallback(
        (
            value: HazardFormSchema['startWaypoint'] | HazardFormSchema['endWaypoint']
        ): AddressPOILookupValue | null => (value ? { name: value.name, point: value.point } : null),
        []
    )

    // The creating new extra waypoint methods
    const drawDirectionLinesForNewExtraWaypoint = () => {
        if (
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload &&
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING
        ) {
            const {
                leftWaypoint: { position: leftWaypointPosition },
                draggingMousePointPosition,
                rightWaypoint: { position: rightWaypointPosition }
            } = HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload

            const multilinePoints = [
                leftWaypointPosition,
                draggingMousePointPosition,
                rightWaypointPosition
            ]

            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING.clear()
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING.setShapes([
                new azureMapsControl.data.LineString(multilinePoints)
            ])
        }
    }

    const hazardMouseDown = (e: azureMapsControl.MapMouseEvent) => {
        const { position, originalEvent } = e

        if (
            position &&
            originalEvent &&
            //@ts-ignore
            originalEvent.target &&
            //@ts-ignore
            originalEvent.target.tagName === 'CANVAS'
        ) {
            //skip if it is marker 'DIV'
            const markers = HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.markers.getMarkers()
            const routeIdx = getNearestRouteIdx(position!)

            const markersInRightOrderByRoute = [...markers].sort((a, b) => {
                const aRouteIdx = a.getOptions().routeIdx
                const bRouteIdx = b.getOptions().routeIdx
                return aRouteIdx - bRouteIdx
            })

            const rightMarkerIndex = markersInRightOrderByRoute.findIndex(
                (marker) => marker.getOptions().routeIdx > routeIdx
            )

            const leftMarkerIndex = rightMarkerIndex - 1

            const rightMarker = markersInRightOrderByRoute[rightMarkerIndex]

            const leftMarker = markersInRightOrderByRoute[leftMarkerIndex]

            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload = {
                leftWaypoint: {
                    routeIdx: leftMarker.getOptions().routeIdx,
                    position: leftMarker.getOptions().position!
                },
                draggingMousePointPosition: position!,
                rightWaypoint: {
                    routeIdx: rightMarker.getOptions().routeIdx,
                    position: rightMarker.getOptions().position!
                }
            }

            //Disable the maps dragging capabilities.
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.setUserInteraction({ dragPanInteraction: false })
        }
    }

    const mouseMoved = (e: azureMapsControl.MapMouseEvent) => {
        const { position } = e
        if (HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload && position) {
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload.draggingMousePointPosition =
                position
            drawDirectionLinesForNewExtraWaypoint()
        }
    }

    const mouseUp = (e: azureMapsControl.MapMouseEvent) => {
        const { position } = e
        if (
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload &&
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING &&
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP &&
            position
        ) {
            const {
                leftWaypoint: { routeIdx: leftWaypointRouteIdx }
            } = HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload

            const markers = HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP.markers.getMarkers()

            const markersInRightOrderByRoute = [...markers].sort((a, b) => {
                const aRouteIdx = a.getOptions().routeIdx
                const bRouteIdx = b.getOptions().routeIdx
                return aRouteIdx - bRouteIdx
            })

            const leftMarkerIndex = markersInRightOrderByRoute.findIndex(
                (marker) => marker.getOptions().routeIdx === leftWaypointRouteIdx
            )

            const leftMarker = markersInRightOrderByRoute[leftMarkerIndex]

            const type: HAZARD_WAYPOINT_TYPE = leftMarker.getOptions().type

            const intermediateWaypoint: HazardWaypoint = {
                name: `The extra waypoint random id ${Math.random() * 100}`,
                point: position as HazardWaypoint['point'],
                type: HAZARD_WAYPOINT_TYPE.EXTRA
            }

            switch (type) {
                case HAZARD_WAYPOINT_TYPE.START: {
                    prepend(intermediateWaypoint)
                    break
                }
                case HAZARD_WAYPOINT_TYPE.END: {
                    append(intermediateWaypoint)
                    break
                }
                case HAZARD_WAYPOINT_TYPE.EXTRA: {
                    const intermediateWaypointIndex = leftMarker.getOptions().intermediateWaypointIndex
                    insert(intermediateWaypointIndex + 1, intermediateWaypoint)
                    break
                }
            }
            HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING.clear()
        }
        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.processDraggingRoutePayload = null

        HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.MAP!.setUserInteraction({ dragPanInteraction: true })
    }
    // The Creating new extra waypoint methods

    const { mutateAsync: updateHazardAsync } = useUpdateHazard()
    const { mutateAsync: addHazardAsync } = useAddHazard()
    const { backPath } = useGoBack()

    const onSubmit = handleSubmit(async (hazardForm: NonNullable<HazardFormSchema>) => {
        const { name, description, startWaypoint, endWaypoint } = hazardForm
        try {
            const waypointsJson = JSON.stringify([startWaypoint, ...intermediateWaypoints, endWaypoint])
            const routeGeoJson = JSON.stringify(HAZARD_ADD_EDIT_NOT_REACTIVE_DATA.routeGeoJson)
            const payload = {
                name,
                description,
                waypoints: waypointsJson,
                routeGeoJson: routeGeoJson
            }
            if (id && hazard) {
                await updateHazardAsync({ ...payload, id: +id })
            } else {
                await addHazardAsync(payload)
            }
            enqueueSnackbar(`Hazard has been successfully ${id ? 'updated' : 'added'}.`, {
                variant: 'success'
            })
            navigate(backPath)
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
    })

    return (
        <>
            {status === 'loading' && isFetching && id && (
                <Grid container height='100%' alignItems='center' justifyContent='center'>
                    <CircularProgress sx={{ marginBottom: '150px' }} size={70} thickness={5} disableShrink />
                </Grid>
            )}
            {((id && status === 'success') || !id) && (
                <>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        padding={'16px 12px'}
                        position='sticky'
                    >
                        <Grid
                            item
                            container
                            direction='row'
                            justifyContent='start'
                            alignItems='center'
                            width='auto'
                        >
                            <IconButton color='secondary' onClick={() => navigate(backPath)}>
                                <ArrowBackOutlinedIcon />
                            </IconButton>
                            <Typography variant='title1Bold' mr='24px' ml='20px'>
                                {id ? 'Edit hazard' : 'Add new hazard'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction='row'
                            justifyContent='end'
                            alignItems='center'
                            width='auto'
                        >
                            <Button
                                sx={{ background: userMe?.customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399' }}
                                disabled={
                                    !!Object.keys(errors).length || isValidating || isSubmitting || isSubmitSuccessful
                                }
                                onClick={onSubmit}
                                variant='contained'
                                type='submit'
                                startIcon={<CheckIcon />}
                            >
                                {id ? 'Save changes' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container height='100%' padding={'16px 12px 0 12px'} flexDirection='row'>
                        <Grid
                            container
                            flexDirection='column'
                            alignItems='start'
                            justifyContent='start'
                            p='24px 24px 0 16px'
                            sx={{
                                width: '410px',
                                background: COLORS.LIGHT_GRAY
                            }}
                        >
                            <Grid
                                container
                                flexDirection='column'
                                alignItems='start'
                                justifyContent='start'
                                rowSpacing={2}
                                mt='0'
                            >
                                <Typography variant='bodyBold' color='secondary'>
                                    General info
                                </Typography>
                                <Grid item width='100%'>
                                    <Controller
                                        control={control}
                                        name={keys.name}
                                        render={({ field }) => (
                                            <FormTextField
                                                textFieldProps={{ label: 'Hazard name', required: true, fullWidth: true }}
                                                error={errors[keys.name] ? getErrorMessage(errors[keys.name]!) : ''}
                                                field={field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item width='100%'>
                                    <Controller
                                        control={control}
                                        name={keys.description}
                                        render={({ field }) => (
                                            <FormTextField
                                                textFieldProps={{ label: 'Hazard description', fullWidth: true }}
                                                error={
                                                    errors[keys.description] ? getErrorMessage(errors[keys.description]!) : ''
                                                }
                                                field={field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    flexDirection='column'
                                    alignItems='start'
                                    justifyContent='start'
                                    mt='24px'
                                >
                                    <Typography variant='bodyBold' color='secondary'>
                                        Hazard info
                                    </Typography>
                                    <Grid container rowSpacing={2} mt='0'>
                                        <Grid
                                            item
                                            container
                                            justifyContent='start'
                                            alignItems='center'
                                            direction='row'
                                            whiteSpace='normal'
                                            wrap='nowrap'
                                        >
                                            <StartWaypointIcon fill={COLORS.RED} />
                                            <Controller
                                                control={control}
                                                name={keys.startWaypoint}
                                                render={({ field: { onChange, value } }) => (
                                                    <AddressPOILookup
                                                        textFeildProps={{
                                                            label: 'Start point',
                                                            error: !!errors[keys.startWaypoint],
                                                            helperText:
                                                                !!errors[keys.startWaypoint] &&
                                                                getErrorMessage(errors[keys.startWaypoint]!),
                                                            required: true,
                                                            placeholder: 'Search for address'
                                                        }}
                                                        value={getAddressPOILookupValue(value)}
                                                        onChange={(waypoint) =>
                                                            waypoint
                                                                ? onChange({ ...waypoint, type: HAZARD_WAYPOINT_TYPE.START })
                                                                : onChange(null)
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            justifyContent='start'
                                            alignItems='center'
                                            direction='row'
                                            whiteSpace='normal'
                                            wrap='nowrap'
                                        >
                                            <Box
                                                width='100%'
                                                height='1px'
                                                sx={{ background: COLORS.BORDER_GRAY, m: '8px 0' }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            justifyContent='start'
                                            alignItems='center'
                                            direction='row'
                                            whiteSpace='normal'
                                            wrap='nowrap'
                                        >
                                            <EndWaypointIcon fill={COLORS.RED} />

                                            <Controller
                                                control={control}
                                                name={keys.endWaypoint}
                                                render={({ field: { onChange, value } }) => (
                                                    <AddressPOILookup
                                                        textFeildProps={{
                                                            label: 'End point',
                                                            error: !!errors[keys.endWaypoint],
                                                            helperText:
                                                                !!errors[keys.endWaypoint] &&
                                                                getErrorMessage(errors[keys.endWaypoint]!),
                                                            required: true,
                                                            placeholder: 'Search for address'
                                                        }}
                                                        value={getAddressPOILookupValue(value)}
                                                        onChange={(waypoint) =>
                                                            waypoint
                                                                ? onChange({ ...waypoint, type: HAZARD_WAYPOINT_TYPE.END })
                                                                : onChange(null)
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sx={mapContainerStyle}>
                            <Box
                                id='map'
                                ref={mapRef}
                                ml='16px'
                                borderRadius='8px'
                                sx={{ position: 'relative', height: '100%' }}
                            >
                                {!mapIsLoading && !!countActiveMapActions && <LinearProgress sx={{ zIndex: 1 }} />}
                                {mapIsLoading && <Backdrop position='absolute' />}
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )
            }
        </>
    )
}

export default HazardAddEdit
