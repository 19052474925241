import React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import {
  Route,
  ToggleRoutePayload,
  RouteDBModel,
  UpdateRoutePayload,
  AddRoutePayload,
  RemoveRoutePayload
} from '../models/route'
import { useUserMe } from './user'

const ROUTES = 'routes'
const ROUTE = 'route'
const STALE = 20 * 60 * 1000

const getParsedJSONRoute = ({ waypoints, routeGeoJson, ...rest }: RouteDBModel) => ({
  ...rest,
  waypoints: JSON.parse(waypoints),
  routeGeoJson: JSON.parse(routeGeoJson)
})

export const useRoutes = () => {
  const { data: userMe } = useUserMe()
  return useQuery(
    [ROUTES],
    async (): Promise<Route[]> => {
      const { data } = await axios.get<RouteDBModel[]>(
        `${process.env.REACT_APP_ROUTING_API_URL}/Route?enriched=true&${userMe?.options}`
      )
      const parsedJSONRoutes = data.map((route) => getParsedJSONRoute(route))
      return parsedJSONRoutes.reverse()
    },
    {
      enabled: !!userMe?.options,
      initialDataUpdatedAt: Date.now(),
      staleTime: STALE
    }
  )
}

export const useToggleRoute = () => {
  const queryClient = useQueryClient()
  const { data: userMe } = useUserMe()
  return useMutation(
    async (toggleRoutePayload: ToggleRoutePayload): Promise<Route> => {
      const { data } = await axios.put(
        `${process.env.REACT_APP_ROUTING_API_URL}/route/toggle/${toggleRoutePayload.id}?${userMe?.options}&customer=${userMe?.customer}`
      )
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData([ROUTES], (oldData?: Route[]) =>
          oldData?.map((item) => ({
            ...item,
            enabled: item.id === id ? data.enabled : item.enabled
          }))
        )
      }
    }
  )
}

export const useRoute = (id: string | undefined) => {
  return useQuery(
    [ROUTE],
    async (): Promise<Route> => {
      const { data } = await axios.get<RouteDBModel>(
        `${process.env.REACT_APP_ROUTING_API_URL}/Route/${id}?enriched=true`
      )

      return getParsedJSONRoute(data)
    },
    {
      enabled: false
    }
  )
}

export const useUpdateRoute = () => {
  const queryClient = useQueryClient()
  const { data: userMe } = useUserMe()
  return useMutation(
    async (updateRoutePayload: UpdateRoutePayload) => {
      const { data } = await axios.put(`${process.env.REACT_APP_ROUTING_API_URL}/Route`, {
        ...updateRoutePayload,
        partnerName: userMe?.partner,
        customerName: userMe?.customer,
        partnerId: 0,
        customerId: 0
      })
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData([ROUTES], (oldData?: Route[]) =>
          oldData?.map((item) => (item.id === id ? getParsedJSONRoute(data) : item))
        )
      }
    }
  )
}

export const useAddRoute = () => {
  const { data: userMe } = useUserMe()
  const queryClient = useQueryClient()
  return useMutation(
    async (addRoutePayload: AddRoutePayload) => {
      const { data } = await axios.post(`${process.env.REACT_APP_ROUTING_API_URL}/Route`, {
        ...addRoutePayload,
        partnerName: userMe?.partner,
        customerName: userMe?.customer,
        partnerId: 0,
        customerId: 0
      })
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          [ROUTES],
          (oldData?: Route[]) => oldData && [getParsedJSONRoute(data), ...oldData]
        )
      }
    }
  )
}

export const useRemoveRoute = () => {
  const queryClient = useQueryClient()
  const { data: userMe } = useUserMe()

  return useMutation(
    async (removeRoutePayload: RemoveRoutePayload) => {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_ROUTING_API_URL}/route/${removeRoutePayload.id}?${userMe?.options}&customer=${userMe?.customer}`
      )
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData(
          [ROUTES],
          (oldData?: Route[]) => oldData && [...oldData.filter((item) => item.id !== id)]
        )
      }
    }
  )
}

export const useAddRoutes = () => {
  const { data: userMe } = useUserMe()
  const queryClient = useQueryClient()
  return useMutation(
    async (addRoutesPayload: AddRoutePayload[]) => {
      const routes = addRoutesPayload.map((route) => ({
        ...route,
        partnerName: userMe?.partner,
        customerName: userMe?.customer,
        partnerId: 0,
        customerId: 0
      }))
      const { data } = await axios.post(
        `${process.env.REACT_APP_ROUTING_API_URL}/Route/bulk`,
        routes
      )
      return data
    },
    {
      onSuccess: ({ successfull }: { successfull: RouteDBModel[]; failed: RouteDBModel[] }) => {
        queryClient.setQueryData(
          [ROUTES],
          (oldData?: Route[]) =>
            oldData && [...successfull.map((route) => getParsedJSONRoute(route)), ...oldData]
        )
      }
    }
  )
}
