import * as z from 'zod'

export const VehicleSchema = z.object({
    id: z.number().positive().int(),
    createdBy: z.string().optional(),
    created: z.string().optional(),
    updatedBy: z.string().optional(),
    updated: z.string().optional(),
    partnerName: z.string(),
    partnerId: z.number().positive().int(),
    customerName: z.string(),
    customerId: z.number().positive().int(),
    name: z.string().min(3).max(255),
    license: z.string().nullable(),
    type: z.string().min(3).max(255),
    width: z.number().positive().nullable(),
    height: z.number().positive().nullable(),
    length: z.number().positive().nullable(),
    weight: z.number().positive().nullable()
})

export type Vehicle = ReturnType<typeof VehicleSchema['parse']>

export type AddVehiclePayload = Pick<
    Vehicle,
    | 'name'
    | 'width'
    | 'height'
    | 'length'
    | 'weight'
    | 'type'
    | 'customerName'
    | 'partnerName'
    | 'license'
    | 'partnerId'
    | 'customerId'
>

export type UpdateVehiclePayload = AddVehiclePayload & Pick<Vehicle, 'id'>

export type RemoveVehiclePayload = Pick<
    Vehicle,
    | 'id'
    | 'name'
    | 'type'
    | 'customerName'
    | 'license'
    | 'partnerName'
    | 'customerName'
    | 'partnerId'
    | 'customerId'
>
