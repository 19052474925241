import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'

type Props = {
  title: string
  onChangeSearch: (data: string) => void
  textFieldProps?: TextFieldProps
}

const Search: FC<Props> = ({ title, onChangeSearch, textFieldProps = {} }) => (
  <Grid
    container
    direction='row'
    justifyContent='start'
    alignItems='center'
    padding={'16px 12px'}
    width='auto'
  >
    <Typography variant='title1Bold' mr='24px'>
      {title}
    </Typography>
    <TextField
      {...textFieldProps}
      sx={{ minWidth: '320px' }}
      label='Search'
      onChange={(e) => onChangeSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  </Grid>
)

export default Search
