import React from 'react'
import styled from '@emotion/styled'
import CircularProgressMUI, { CircularProgressProps } from '@mui/material/CircularProgress'

const CircularProgress = styled((props: CircularProgressProps) => (
  <CircularProgressMUI {...props} />
))(() => ({
  '& .MuiCircularProgress-circle': {
    strokeLinecap: 'round'
  }
}))

export default CircularProgress
