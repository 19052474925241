import React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import {
  AddHazardPayload,
  Hazard,
  HazardDBModel,
  RemoveHazardPayload,
  ToggleHazardPayload,
  UpdateHazardPayload
} from '../models/hazard'
import { useUserMe } from './user'

const HAZARDS = 'hazards'
const HAZARD = 'hazard'
const STALE = 20 * 60 * 1000

const getParsedJSONHazard = ({ waypoints, routeGeoJson, ...rest }: HazardDBModel) => ({
  ...rest,
  waypoints: JSON.parse(waypoints),
  routeGeoJson: JSON.parse(routeGeoJson)
})

export const useHazards = () => {
  const { data: userMe } = useUserMe()
  return useQuery(
    [HAZARDS],
    async (): Promise<Hazard[]> => {
      const { data } = await axios.get<HazardDBModel[]>(
        `${process.env.REACT_APP_ROUTING_API_URL}/Hazard?enriched=true&${userMe?.options}`
      )
      const parsedJSONHazards = data.map((hazard) => getParsedJSONHazard(hazard))
      return parsedJSONHazards.reverse()
    },
    {
      enabled: !!userMe?.options,
      initialDataUpdatedAt: Date.now(),
      staleTime: STALE
    }
  )
}

export const useHazard = (id: string | undefined) => {
  return useQuery(
    [HAZARD],
    async (): Promise<Hazard> => {
      const { data } = await axios.get<HazardDBModel>(
        `${process.env.REACT_APP_ROUTING_API_URL}/Hazard/${id}?enriched=true`
      )
      return getParsedJSONHazard(data)
    },
    {
      enabled: false
    }
  )
}

export const useUpdateHazard = () => {
  const queryClient = useQueryClient()
  const { data: userMe } = useUserMe()
  return useMutation(
    async (updateHazardPayload: UpdateHazardPayload) => {
      const { data } = await axios.put(`${process.env.REACT_APP_ROUTING_API_URL}/Hazard`, {
        ...updateHazardPayload,
        partnerName: userMe?.partner,
        customerName: userMe?.customer,
        partnerId: 0,
        customerId: 0
      })
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData([HAZARDS], (oldData?: Hazard[]) =>
          oldData?.map((item) => (item.id === id ? getParsedJSONHazard(data) : item))
        )
      }
    }
  )
}

export const useAddHazard = () => {
  const { data: userMe } = useUserMe()
  const queryClient = useQueryClient()
  return useMutation(
    async (addHazardPayload: AddHazardPayload) => {
      const { data } = await axios.post(`${process.env.REACT_APP_ROUTING_API_URL}/Hazard`, {
        ...addHazardPayload,
        partnerName: userMe?.partner,
        customerName: userMe?.customer,
        partnerId: 0,
        customerId: 0
      })
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          [HAZARDS],
          (oldData?: Hazard[]) => oldData && [getParsedJSONHazard(data), ...oldData]
        )
      }
    }
  )
}

export const useRemoveHazard = () => {
  const queryClient = useQueryClient()
  const { data: userMe } = useUserMe()

  return useMutation(
    async (removeHazardPayload: RemoveHazardPayload) => {
      const { data } = await axios.delete(`${process.env.REACT_APP_ROUTING_API_URL}/Hazard`, {
        data: {
          ...removeHazardPayload,
          partnerName: userMe?.partner,
          customerName: userMe?.customer,
          partnerId: 0,
          customerId: 0
        }
      })
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData(
          [HAZARDS],
          (oldData?: Hazard[]) => oldData && [...oldData.filter((item) => item.id !== id)]
        )
      }
    }
  )
}

export const useToggleHazard = () => {
  const queryClient = useQueryClient()
  const { data: userMe } = useUserMe()
  return useMutation(
    async (toggleHazardPayload: ToggleHazardPayload): Promise<Hazard> => {
      const { data } = await axios.put(`${process.env.REACT_APP_ROUTING_API_URL}/Hazard/toggle`, {
        ...toggleHazardPayload,
        partnerName: userMe?.partner,
        customerName: userMe?.customer,
        partnerId: 0,
        customerId: 0
      })
      return data
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.setQueryData([HAZARDS], (oldData?: Hazard[]) =>
          oldData?.map((item) => ({
            ...item,
            enabled: item.id === id ? data.enabled : item.enabled
          }))
        )
      }
    }
  )
}
