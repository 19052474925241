import React, { FC } from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const EndWaypointIcon: FC<{ fill: string }> = ({ fill }) => (
  <SvgIcon
    sx={{ width: '26px', height: '38px', mr: '16px' }}
    width='26'
    height='38'
    viewBox='0 0 26 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <svg width='26' height='38' viewBox='0 0 26 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.84 12.8955C25.84 24 15.2271 38 12.92 38C10.6129 38 0 24 0 12.8955C0 5.7735 5.78448 0 12.92 0C20.0555 0 25.84 5.7735 25.84 12.8955Z'
        fill={fill}
      />
      <circle cx='12.9198' cy='12.92' r='10.64' fill='white' />
      <path
        d='M12.7646 12.5635H10.2217L10.208 11.2373H12.3887C12.7624 11.2373 13.0745 11.1849 13.3252 11.0801C13.5758 10.9753 13.765 10.8226 13.8926 10.6221C14.0247 10.4215 14.0908 10.1777 14.0908 9.89062C14.0908 9.57161 14.0293 9.31185 13.9062 9.11133C13.7878 8.91081 13.6009 8.76497 13.3457 8.67383C13.0905 8.57812 12.7669 8.53027 12.375 8.53027H10.8301V17H8.9502V7.04688H12.375C12.9401 7.04688 13.4437 7.10156 13.8857 7.21094C14.3324 7.31576 14.7106 7.47982 15.0205 7.70312C15.3304 7.92643 15.5651 8.20671 15.7246 8.54395C15.8887 8.88118 15.9707 9.28223 15.9707 9.74707C15.9707 10.1572 15.875 10.5355 15.6836 10.8818C15.4967 11.2236 15.2074 11.5016 14.8154 11.7158C14.4281 11.93 13.9359 12.0531 13.3389 12.085L12.7646 12.5635ZM12.6826 17H9.66797L10.4541 15.5234H12.6826C13.0563 15.5234 13.3639 15.4619 13.6055 15.3389C13.8516 15.2158 14.0339 15.0472 14.1523 14.833C14.2754 14.6143 14.3369 14.3636 14.3369 14.0811C14.3369 13.7712 14.2822 13.5023 14.1729 13.2744C14.068 13.0465 13.8994 12.8711 13.667 12.748C13.4391 12.625 13.1383 12.5635 12.7646 12.5635H10.8096L10.8232 11.2373H13.3115L13.7422 11.75C14.3164 11.7546 14.7835 11.8685 15.1436 12.0918C15.5081 12.3151 15.777 12.6022 15.9502 12.9531C16.1234 13.304 16.21 13.6823 16.21 14.0879C16.21 14.7259 16.071 15.2614 15.793 15.6943C15.5195 16.1273 15.1185 16.4531 14.5898 16.6719C14.0658 16.8906 13.43 17 12.6826 17Z'
        fill={fill}
      />
    </svg>
  </SvgIcon>
)
