import React, { FC, useState, useMemo } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Autocomplete from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import * as z from 'zod'
import { useSearchFuzzy } from '../../queries/azureMaps'

export const AddressPOILookupValueSchema = z.object({
  name: z.string(),
  point: z.array(z.number()).length(2)
})

export type AddressPOILookupValue = Omit<
  ReturnType<typeof AddressPOILookupValueSchema['parse']>,
  'point'
> & {
  point: [number, number] //[lon, lat]
}

type AddressPOILookupProps = {
  value: AddressPOILookupValue | null
  onChange: (v: AddressPOILookupValue | null) => void
  textFeildProps?: TextFieldProps
}

const AddressPOILookup: FC<AddressPOILookupProps> = ({ textFeildProps, onChange, value }) => {
  const [query, setQuery] = useState('')
  const { data, isFetching } = useSearchFuzzy(query)

  const addresses = useMemo(() => {
    if (!data?.results) return []

    const { results } = data
    return results
      .reduce((acc, result) => {
        if (
          result.address &&
          result.address.freeformAddress &&
          result.position &&
          result.position.lat &&
          result.position.lon
        ) {
          const {
            address: { freeformAddress },
            position: { lat, lon }
          } = result
          const name = result.poi ? `${result.poi.name}, ` : ''
          const step: AddressPOILookupValue = {
            name: `${name}${freeformAddress}`,
            point: [lon, lat]
          }
          acc.push(step)
        }
        return acc
      }, [] as AddressPOILookupValue[])
      .filter((s, i, arr) => {
        const isRepeat = arr.find(({ name }, inx) => name === s.name && i !== inx)
        return !isRepeat
      })
  }, [data, isFetching])

  return (
    <Autocomplete
      fullWidth
      options={addresses}
      freeSolo={true}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      filterOptions={(options) => options}
      clearIcon={<HighlightOffIcon />}
      // isOptionEqualToValue={(option, value) => option.name === value.name}
      popupIcon={null}
      loading={isFetching}
      value={value}
      onChange={(e, v) => {
        if (typeof v !== 'string') onChange(v)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFeildProps}
          onChange={({ target: { value } }) => {
            setQuery(value.trim().replace(/[^\w ,]+/g, ''))
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon />
          }}
        />
      )}
    />
  )
}

export default AddressPOILookup
