import React, { PropsWithChildren, FC, useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { useTheme, Theme, CSSObject } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import SvgIcon from '@mui/material/SvgIcon'

import ShareLocationIcon from '@mui/icons-material/ShareLocation'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined'
import DirectionsBusOutlinedIcon from '@mui/icons-material/DirectionsBusOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import Button from '@mui/material/Button/Button'
import Backdrop from '../components/UI/Backdrop'
import Typography from '@mui/material/Typography'

import { COLORS } from '../theme'
import Grid from '@mui/material/Grid'

import { useAuth0 } from '@auth0/auth0-react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { useLocalStorage } from 'usehooks-ts'
import { useUserMe } from '../queries/user'

export const drawerWidth = 236
export const closedDrawerWidth = 74
export const toolbarHeight = 88

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: closedDrawerWidth
})

const NAVIGATION = [
    {
        to: '/tracking',
        icon: <ShareLocationIcon />,
        label: 'Tracking'
    },
    {
        to: '/routes?page=1',
        icon: <RouteOutlinedIcon />,
        label: 'Routes'
    },
    {
        to: '/vehicles?page=1',
        icon: <DirectionsBusOutlinedIcon />,
        label: 'Vehicles'
    },
    {
        to: '/operators?page=1',
        icon: <ManageAccountsOutlinedIcon />,
        label: 'Operators'
    },
    {
        to: '/users?page=1',
        icon: <ContactPageOutlinedIcon />,
        label: 'Users'
    }
]

const General: FC<PropsWithChildren> = ({ children }) => {
    const theme = useTheme()
    const { pathname } = useLocation()
    const { logout, user } = useAuth0()
    const { data: userMe } = useUserMe()

    const [open, setOpen] = useLocalStorage('OPEN_LEFT_BAR', false)
    const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState(false)

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', position: 'relative' }}>
            <CssBaseline />
            <AppBar
                position='fixed'
                sx={{
                    width: `calc(100% - ${open ? drawerWidth : closedDrawerWidth}px)`,
                    ml: `${open ? drawerWidth : closedDrawerWidth}px`,
                    height: `${toolbarHeight}px`,
                    background: COLORS.WHITE,
                    boxShadow: 'none'
                }}
            >
                <Toolbar
                    sx={{
                        height: '100%',
                        borderBottom: `solid 1px ${COLORS.BORDER_GRAY}`,
                        padding: '0',
                        pl: '0!important',
                        pr: '0!important',
                        marginX: '24px',
                        justifyContent: 'end'
                    }}
                >
                    {!!user && !!user.name && (
                        <Grid container width='auto' alignItems='center'>
                            <Avatar alt={user.name} src={user.picture} />
                            <Typography ml='8px' variant='text' sx={{ color: COLORS.MAIN_DARK }}>
                                {user.name}
                            </Typography>
                        </Grid>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    flexShrink: 0,
                    ...(open && {
                        ...openedMixin(theme)
                    }),
                    ...(!open && {
                        ...closedMixin(theme)
                    }),
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        background: userMe?.customer === "Go Ahead London" ?
                            COLORS.MAIN_BLUE : "#667399",

                        padding: '10px',
                        justifyContent: 'space-between',
                        ...(open ? openedMixin(theme) : closedMixin(theme))
                    }
                }}
                variant='permanent'
                anchor='left'
                open={open}
            >
                <Grid container flexDirection='column' justifyContent='end'>
                    <Box sx={open ? { padding: '14px' } : { pt: '30px' }}>
                        {open ? (
                            userMe?.customer === "Go Ahead London" ?
                                <SvgIcon
                                    sx={{ width: 188, height: 66 }}
                                    width='188'
                                    height='66'
                                    viewBox='0 0 188 66'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <g clipPath='url(#clip0_426_798)'>
                                        <path
                                            d='M79.5383 30.1781C68.42 30.1781 60.4784 38.1531 60.4784 49.3181C60.4784 59.1335 66.9539 65.6361 77.0947 65.6361C88.7017 65.6361 96.5211 57.4158 96.5211 46.6188C96.5211 36.6808 89.9235 30.1781 79.5383 30.1781ZM79.6604 38.8892C84.1811 38.8892 86.869 41.7111 86.869 46.7415C86.869 52.8761 82.9593 56.925 77.4612 56.925C72.9406 56.925 70.2527 53.9804 70.2527 49.1954C70.2527 43.1835 73.918 38.8892 79.6604 38.8892Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M59.7454 13.86C54.6139 5.14885 46.0614 0.854614 34.3322 0.854614C23.8248 0.854614 15.028 4.53538 9.04122 11.4062C3.29882 18.0315 0 26.8654 0 35.6992C0 53.6123 11.7292 65.2681 30.4225 65.2681C48.8715 65.2681 60.6006 53.1215 61.8224 31.5277H34.0879L32.7439 42.3246H47.161C44.5952 49.9315 38.6085 54.2258 31.0334 54.2258C20.2817 54.2258 12.9509 47.2323 12.9509 35.6992C12.9509 21.835 21.87 11.7742 34.0879 11.7742C40.6855 11.6515 46.3057 14.9642 49.8489 20.9762L59.7454 13.86Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M34.5766 30.5461L31.7665 41.9565L61.5781 31.0369L34.5766 30.5461Z'
                                            fill='#DA353A'
                                        />
                                        <path
                                            d='M118.025 28.215H127.066L127.555 26.2519H121.323L124.378 13.7373L121.323 14.5962L118.025 28.215Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M134.763 18.8904C130.853 18.8904 128.165 21.5896 128.165 24.6569C128.165 26.8654 129.998 28.3377 132.808 28.3377C136.718 28.3377 139.406 25.6385 139.406 22.5711C139.528 20.6081 137.94 18.8904 134.763 18.8904ZM134.519 20.6081C136.229 20.6081 136.718 21.7123 136.718 22.6938C136.718 24.5342 135.374 26.7427 133.297 26.7427C131.953 26.7427 131.098 25.8838 131.098 24.6569C130.976 22.8165 132.319 20.6081 134.519 20.6081Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M142.827 28.215L143.804 24.0435C144.293 21.9577 145.881 20.7308 147.103 20.7308C148.203 20.7308 148.569 21.3442 148.569 22.0804C148.569 22.4485 148.447 22.8165 148.447 23.1846L147.225 28.3377H149.913L151.135 23.0619C151.257 22.5712 151.379 21.9577 151.379 21.5896C151.379 19.8719 150.035 19.1358 148.447 19.1358C146.859 19.1358 145.393 19.7492 144.293 20.9762L144.537 19.3812L142.094 19.8719C141.972 20.6081 141.849 21.0989 141.483 22.2031L140.017 28.4604H142.827'
                                            fill='white'
                                        />
                                        <path
                                            d='M162.375 16.0685L161.52 19.1358C161.032 19.0131 160.298 18.8904 159.688 18.8904C155.411 18.8904 152.479 21.835 152.479 25.025C152.479 27.1108 153.945 28.3377 156.022 28.3377C157.488 28.3377 158.954 27.7242 159.932 26.4973L159.688 28.0923H162.253C162.375 27.1108 162.498 25.8838 162.742 24.9023L165.186 14.9642L162.375 16.0685ZM160.421 23.6754C159.932 25.5158 158.466 26.62 157.244 26.62C155.9 26.62 155.411 25.7612 155.411 24.6569C155.411 22.5712 157.122 20.6081 159.565 20.6081C160.298 20.6081 160.787 20.7308 161.154 20.9762L160.421 23.6754Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M171.539 18.8904C167.629 18.8904 164.819 21.5896 164.819 24.6569C164.819 26.8654 166.652 28.3377 169.462 28.3377C173.371 28.3377 176.182 25.6385 176.182 22.5711C176.182 20.6081 174.593 18.8904 171.539 18.8904ZM171.172 20.6081C172.883 20.6081 173.371 21.7123 173.371 22.6938C173.371 24.5342 172.028 26.7427 169.95 26.7427C168.607 26.7427 167.751 25.8838 167.751 24.6569C167.751 22.8165 168.973 20.6081 171.172 20.6081Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M179.48 28.215L180.458 24.0435C180.947 21.9577 182.535 20.7308 183.757 20.7308C184.856 20.7308 185.223 21.3442 185.223 22.0804C185.223 22.4485 185.101 22.8165 185.101 23.1846L183.879 28.3377H186.567L187.789 23.0619C187.911 22.5712 188.033 21.9577 188.033 21.5896C188.033 19.8719 186.689 19.1358 185.101 19.1358C183.512 19.1358 182.046 19.7492 180.947 20.9762L181.191 19.3812L178.747 19.8719C178.625 20.6081 178.503 21.0988 178.136 22.2031L176.67 28.4604H179.48'
                                            fill='white'
                                        />
                                        <path
                                            d='M59.2566 23.1846H63.1664L62.6777 20.1173C62.5555 19.5038 62.5555 18.5223 62.4333 17.7861C61.9446 18.5223 61.578 19.3811 61.0893 20.1173L59.2566 23.1846ZM63.7773 23.6754L57.6683 26.0065L57.424 26.3746L56.2022 28.3377H53.2699L61.0893 16.0685H64.5103L66.4652 28.3377H63.6551L63.2885 24.1661L64.5103 23.6754H63.7773Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M69.764 28.3377L70.7414 24.0434C71.2301 22.2031 72.6963 20.9761 74.0402 20.9761C75.0176 20.9761 75.5064 21.4669 75.5064 22.2031C75.5064 22.6938 75.3842 23.0619 75.3842 23.43L74.1624 28.4604H76.8503L78.0721 23.3073C78.1943 22.8165 78.3165 22.2031 78.3165 21.7123C78.3165 20.1173 77.0947 19.2584 75.5064 19.2584C73.918 19.2584 72.6963 19.8719 71.7188 20.8534L73.0628 15.455L70.1305 15.8231L66.9539 28.3377H69.764Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M87.3577 26.0065C86.5025 26.3746 85.525 26.62 84.181 26.62C83.0814 26.62 82.3484 26.2519 81.8597 25.7612C81.6153 25.3931 81.4931 24.7796 81.6153 24.5342C86.0137 24.6569 89.0682 23.9208 89.0682 21.5896C89.0682 20.1173 87.6021 19.1358 85.4028 19.1358C81.4931 19.1358 78.9274 22.0804 78.9274 24.7796C78.9274 26.9881 80.3935 28.4604 83.5702 28.4604C84.9141 28.4604 86.5024 28.215 87.6021 27.7242L87.3577 26.0065ZM86.5024 21.4669C86.5024 22.5712 84.6698 22.8165 81.8597 22.8165C82.2262 21.7123 83.3258 20.6081 85.0363 20.6081C85.8916 20.6081 86.5024 20.9762 86.5024 21.4669Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M98.7204 28.0923C98.7204 26.9881 98.8425 25.3931 99.2091 24.0434L100.431 19.3811C99.5756 19.1358 98.2316 19.0131 97.0099 19.0131C91.7562 19.0131 89.0682 22.2031 89.0682 25.2704C89.0682 27.1108 90.4122 28.3377 92.3671 28.3377C93.8332 28.3377 95.2994 27.7242 96.399 26.1292H96.5211C96.399 26.8654 96.2768 27.6015 96.2768 28.0923H98.7204ZM96.6433 23.0619C96.1546 25.2704 94.5663 26.62 93.3445 26.62C92.1227 26.62 91.7562 25.8838 91.7562 24.9023C91.7562 22.8165 93.711 20.6081 96.1546 20.6081C96.6433 20.6081 97.0099 20.6081 97.2542 20.7308L96.6433 23.0619Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M109.716 16.0685L108.739 19.2585C108.25 19.1358 107.517 19.0131 107.028 19.0131C102.874 19.0131 99.9421 21.835 99.9421 25.025C99.9421 26.9881 101.408 28.3377 103.363 28.3377C104.829 28.3377 106.173 27.7242 107.273 26.4973L107.028 28.0923H109.472C109.594 27.1108 109.716 26.0065 109.961 24.9023L112.282 15.0869L109.716 16.0685ZM107.639 23.6754C107.151 25.5158 105.685 26.62 104.585 26.62C103.363 26.62 102.752 25.7611 102.752 24.6569C102.752 22.6938 104.463 20.6081 106.784 20.6081C107.517 20.6081 108.006 20.7308 108.372 20.9761L107.639 23.6754Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M111.549 24.2888L118.269 22.4485H112.038L111.549 24.2888Z'
                                            fill='white'
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id='clip0_426_798'>
                                            <rect width='188' height='66' fill='white' />
                                        </clipPath>
                                    </defs>
                                </SvgIcon>
                                : <img width="188" height="66"
                                    src="https://afastrax.blob.core.windows.net/bannerlogos/forerouting.png" />
                        ) : (
                            userMe?.customer === "Go Ahead London" ?
                                <SvgIcon
                                    sx={{ width: 54, height: 37 }}
                                    width='54'
                                    height='37'
                                    viewBox='0 0 54 37'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <g clipPath='url(#clip0_2189_13238)'>
                                        <path
                                            d='M44.4983 16.7867C38.278 16.7867 33.835 21.2414 33.835 27.4779C33.835 32.9606 37.4577 36.5929 43.1312 36.5929C49.6248 36.5929 53.9995 32.0011 53.9995 25.9702C53.9995 20.419 50.3084 16.7867 44.4983 16.7867ZM44.5666 21.6526C47.0957 21.6526 48.5995 23.2289 48.5995 26.0387C48.5995 29.4654 46.4122 31.727 43.3362 31.727C40.8071 31.727 39.3033 30.0822 39.3033 27.4094C39.3033 24.0513 41.3539 21.6526 44.5666 21.6526Z'
                                            fill='white'
                                        />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M19.2076 0.407227C25.7696 0.407227 30.5544 2.80589 33.4253 7.67175L27.8886 11.6467C25.9063 8.28855 22.762 6.43815 19.0709 6.50669C12.2354 6.50669 7.24557 12.1264 7.24557 19.8707C7.24557 26.3128 11.3468 30.2192 17.362 30.2192C21.6 30.2192 24.9494 27.8205 26.3848 23.5715H17.9215L17.9227 23.5652L34.5423 17.5405H34.5873C33.9038 29.6024 27.3418 36.3872 17.0202 36.3872C6.56202 36.3872 0 29.8765 0 19.8707C0 14.9363 1.84557 10.0019 5.05823 6.30109C8.40759 2.46322 13.3291 0.407227 19.2076 0.407227Z'
                                            fill='white'
                                        />
                                        <path
                                            d='M19.0032 16.686L17.9219 23.0596L34.5734 17.0234L19.0032 16.686Z'
                                            fill='#DA353A'
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id='clip0_2189_13238'>
                                            <rect
                                                width='53.9999'
                                                height='36.1856'
                                                fill='white'
                                                transform='translate(0 0.407227)'
                                            />
                                        </clipPath>
                                    </defs>
                                </SvgIcon>
                                : <img width="37" height="37" style={{ marginLeft: "10px" }}
                                    src="https://afastrax.blob.core.windows.net/bannerlogos/forerouting_small.png" />
                        )}
                    </Box>
                    <List>
                        {NAVIGATION.map(({ to, icon, label }) => (
                            <NavLink
                                key={to}
                                to={to}
                                style={{ textDecoration: 'unset' }}
                                onClick={() => (document.title = `Bus routing - ${label}`)}
                            >
                                <ListItem
                                    sx={{
                                        width: open ? '216px' : '54px',
                                        height: '54px',
                                        borderRadius: '8px',
                                        background: to.includes(pathname.split('/')[1]) ?
                                            userMe?.customer === "Go Ahead London" ?
                                                'hwb(208deg 24% 24%)' : 'hwb(208deg 40% 40%)'
                                            : ''
                                    }}
                                    disablePadding
                                >
                                    <ListItemButton
                                        sx={{ padding: '16px 14px', height: '54px', borderRadius: '8px' }}
                                    >
                                        <ListItemIcon sx={{
                                            color: COLORS.WHITE
                                        }}>{icon}</ListItemIcon>


                                        <ListItemText primary={label} sx={{
                                            color: COLORS.WHITE
                                        }} />
                                    </ListItemButton>
                                </ListItem>
                            </NavLink>
                        ))}
                    </List>
                </Grid>

                <Grid container>
                    <ListItem
                        sx={{
                            height: '54px',
                            borderRadius: '8px',
                            background: userMe?.customer === "Go Ahead London" ? 'hwb(208deg 24% 24%)' : 'hwb(208deg 40% 40%)',
                            mb: '34px'
                        }}
                        disablePadding
                        onClick={() => setIsOpenLogoutDialog(true)}
                    >
                        <ListItemButton
                            sx={{ padding: '16px 14px', height: '54px', borderRadius: '8px', justifySelf: 'end' }}
                        >
                            <ListItemIcon sx={{
                                color: COLORS.WHITE
                            }}>
                                <LogoutOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Sign Out'} sx={{
                                color: COLORS.WHITE
                            }} />
                        </ListItemButton>
                    </ListItem>

                    <IconButton
                        sx={{
                            border: `1px solid ${COLORS.WHITE}`,
                            background: userMe?.customer === "Go Ahead London"
                                ? COLORS.MAIN_BLUE : '#667399',
                            width: '28px',
                            height: '28px',
                            alignSelf: 'center',
                            margin: '0 auto'
                        }}
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardDoubleArrowLeftIcon htmlColor={COLORS.WHITE} />
                        ) : (
                            <KeyboardDoubleArrowRightIcon htmlColor={COLORS.WHITE} />
                        )}
                    </IconButton>
                </Grid>
            </Drawer>

            <Box
                component='main'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'stretch',
                    p: '16px 12px 25px 12px',
                    marginTop: `${toolbarHeight}px`,
                    width: '100%',
                    minHeight: `calc(100vh - ${toolbarHeight}px)`
                }}
            >
                {children}
            </Box>

            {/* a logout dialog */}
            <Dialog
                open={isOpenLogoutDialog}
                onClose={() => setIsOpenLogoutDialog(false)}
                sx={{ '& .MuiPaper-root': { minWidth: '670px', height: '305px' } }}
            >
                <IconButton
                    onClick={() => setIsOpenLogoutDialog(false)}
                    sx={{ position: 'absolute', right: '10px', top: '10px' }}
                >
                    <CloseIcon fontSize='small' />
                </IconButton>
                <Grid
                    container
                    justifyContent='space-around'
                    direction='column'
                    alignItems='center'
                    height='100%'
                >
                    <DialogTitle textAlign='center' mt='32px' mb='8px' width='400px' variant='title1'>
                        <Typography variant='title1Bold'>Are you sure you want to sign out?</Typography>
                    </DialogTitle>
                    <Box sx={{ padding: '0 24px 48px 24px', textAlign: 'center' }}>
                        <DialogActions sx={{ padding: 'unset' }} disableSpacing>
                            <Button
                                sx={{ width: '50%' }}
                                variant='outlined'
                                onClick={() => setIsOpenLogoutDialog(false)}
                            >
                                Cancel
                            </Button>
                            <Box width='16px'></Box>
                            <Button
                                sx={{
                                    width: '50%',
                                    background: userMe?.customer === "Go Ahead London" ?
                                        COLORS.MAIN_BLUE : '#667399'
                                }}
                                variant='contained'
                                onClick={() => logout({ returnTo: window.location.origin })}
                                autoFocus
                            >
                                Sign out
                            </Button>
                        </DialogActions>
                    </Box>
                </Grid>
            </Dialog>
            {/* a logout dialog */}
        </Box>
    )
}

export default General
