import { WAYPOINT_TYPE } from '../models/route'
import { COLORS } from '../theme'

const startMarkerHTML = (fill = COLORS.MAIN_BLUE) =>
  `<div style="height: 102px">
    <svg width="26" height="59" viewBox="0 0 26 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="51" r="8" fill="${fill}"/>
      <circle cx="13" cy="51" r="5" fill="white"/>
      <path d="M26 12.8955C26 24 15.3214 38 13 38C10.6786 38 0 24 0 12.8955C0 5.7735 5.8203 0 13 0C20.1797 0 26 5.7735 26 12.8955Z" fill="${fill}"/>
      <circle cx="13" cy="13" r="11" fill="white"/>
      <path d="M12.9577 8.55762L10.1208 17H8.13156L11.8503 7.04688H13.1218L12.9577 8.55762ZM15.3298 17L12.4792 8.55762L12.3083 7.04688H13.5866L17.3259 17H15.3298ZM15.1999 13.3018V14.7852H9.86105V13.3018H15.1999Z" fill="${fill}"/>
    </svg>
  </div>
`
const endMarkerHTML = (fill = COLORS.MAIN_BLUE) => `
  <div style="height: 102px">
    <svg width="26" height="59" viewBox="0 0 26 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="51" r="8" fill="${fill}"/>
      <circle cx="13" cy="51" r="5" fill="white"/>
      <path d="M26 12.8955C26 24 15.3214 38 13 38C10.6786 38 0 24 0 12.8955C0 5.7735 5.8203 0 13 0C20.1797 0 26 5.7735 26 12.8955Z" fill="${fill}"/>
      <circle cx="13" cy="13" r="11" fill="white"/>
      <path d="M12.8142 12.5635H10.2712L10.2576 11.2373H12.4382C12.8119 11.2373 13.1241 11.1849 13.3748 11.0801C13.6254 10.9753 13.8145 10.8226 13.9421 10.6221C14.0743 10.4215 14.1404 10.1777 14.1404 9.89062C14.1404 9.57161 14.0789 9.31185 13.9558 9.11133C13.8373 8.91081 13.6505 8.76497 13.3953 8.67383C13.1401 8.57812 12.8165 8.53027 12.4246 8.53027H10.8796V17H8.99976V7.04688H12.4246C12.9897 7.04688 13.4932 7.10156 13.9353 7.21094C14.3819 7.31576 14.7602 7.47982 15.0701 7.70312C15.38 7.92643 15.6147 8.20671 15.7742 8.54395C15.9382 8.88118 16.0203 9.28223 16.0203 9.74707C16.0203 10.1572 15.9246 10.5355 15.7332 10.8818C15.5463 11.2236 15.2569 11.5016 14.865 11.7158C14.4776 11.93 13.9854 12.0531 13.3884 12.085L12.8142 12.5635ZM12.7322 17H9.71753L10.5037 15.5234H12.7322C13.1059 15.5234 13.4135 15.4619 13.655 15.3389C13.9011 15.2158 14.0834 15.0472 14.2019 14.833C14.325 14.6143 14.3865 14.3636 14.3865 14.0811C14.3865 13.7712 14.3318 13.5023 14.2224 13.2744C14.1176 13.0465 13.949 12.8711 13.7166 12.748C13.4887 12.625 13.1879 12.5635 12.8142 12.5635H10.8591L10.8728 11.2373H13.3611L13.7917 11.75C14.366 11.7546 14.8331 11.8685 15.1931 12.0918C15.5577 12.3151 15.8266 12.6022 15.9998 12.9531C16.1729 13.304 16.2595 13.6823 16.2595 14.0879C16.2595 14.7259 16.1205 15.2614 15.8425 15.6943C15.5691 16.1273 15.1681 16.4531 14.6394 16.6719C14.1153 16.8906 13.4796 17 12.7322 17Z" fill="${fill}"/>
    </svg>  
  </div>
`
const intermediateMarkerHTML = (fill = COLORS.MAIN_BLUE) => `
  <div style="
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 4px solid ${fill};
    text-align: center;
    color: ${fill};
    font-weight: 600;
    font-size: 11px;
    padding-top: 3px;
    background: white
    "
  >
    {text}
  </div>
`
export const hazardMarkerHTML = `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_140_6731)">
<circle cx="14" cy="14" r="10" fill="${COLORS.RED}"/>
</g>
<g filter="url(#filter1_d_140_6731)">
<circle cx="14" cy="14" r="5" fill="white"/>
</g>
<defs>
<filter id="filter0_d_140_6731" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_140_6731"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_140_6731" result="shape"/>
</filter>
<filter id="filter1_d_140_6731" x="5" y="5" width="18" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_140_6731"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_140_6731" result="shape"/>
</filter>
</defs>
</svg>
`
const startMarkerRouteHTML = startMarkerHTML()
const endMarkerRouteHTML = endMarkerHTML()
const intermediateMarkerRoute = intermediateMarkerHTML()

const startMarkerHazardHTML = startMarkerHTML(COLORS.RED)
const endMarkerHazardHTML = endMarkerHTML(COLORS.RED)

export const HTML_MARKER_BY_WAYPOINT_TYPE: { [key in WAYPOINT_TYPE]: string } = {
  [WAYPOINT_TYPE.START]: startMarkerRouteHTML,
  [WAYPOINT_TYPE.END]: endMarkerRouteHTML,
  [WAYPOINT_TYPE.STOP]: intermediateMarkerRoute,
  [WAYPOINT_TYPE.EXTRA]: intermediateMarkerRoute
}

export const HTML_MARKER_HAZARD_BY_WAYPOINT_TYPE: { [key in WAYPOINT_TYPE]: string } = {
  [WAYPOINT_TYPE.START]: startMarkerHazardHTML,
  [WAYPOINT_TYPE.END]: endMarkerHazardHTML,
  [WAYPOINT_TYPE.STOP]: hazardMarkerHTML,
  [WAYPOINT_TYPE.EXTRA]: hazardMarkerHTML
}

export const trackingMarkerHTML = `
<div style="position: relative; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
  <div style="width: 40px; height: 40px; background: #b7d1e8; border-radius: 50%;  animation: tracking 2s linear infinite;" />
  <div style="width: 24px; height: 24px; background: ${COLORS.MAIN_BLUE}; border-radius: 50%; position: absolute; left: calc(50% - 12px); top: calc(50% - 12px)" />
  <div style="width: 12px; height: 12px; background: ${COLORS.WHITE}; border-radius: 50%; position: absolute; left: calc(50% - 6px); top: calc(50% - 6px);" />
</div>
`

export const selectedTrackingMarkerHTML = `
<div  style="position: relative; width: 62px; height: 62px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
  <div style="width: 62px; height: 62px; background: #b7d1e8; border-radius: 50%; border: solid 1px ${COLORS.MAIN_BLUE}; animation: selectedTracking 2s linear infinite;" />
  <div style="width: 36px; height: 36px; background: ${COLORS.MAIN_BLUE}; border-radius: 50%; position: absolute; left: calc(50% - 18px); top: calc(50% - 18px)" />
  <div style="width: 18px; height: 18px; background: ${COLORS.WHITE}; border-radius: 50%; position: absolute; left: calc(50% - 9px); top: calc(50% - 9px);" />
</div>
`
