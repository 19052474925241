import React, { FC, useRef, useState, useEffect, useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

import * as azureMapsControl from 'azure-maps-control'

import { useLocalStorage } from 'usehooks-ts'

import Backdrop from '../../UI/Backdrop'
import { Actions } from '../RoutesUploadStepper/actionsModel'

import { RouteGeoJson } from './routeFormModel'
import { useHazards } from '../../../queries/hazard'
import { COLORS } from '../../../theme'
import { hazardMarkerHTML, HTML_MARKER_BY_WAYPOINT_TYPE } from '../../../assets/htmlMarkers'
import { HAZARD_WAYPOINT_TYPE } from '../../../models/hazard'
import { Route, Waypoint, WAYPOINT_TYPE } from '../../../models/route'
import { searchReverse } from '../../../queries/azureMaps'
import {
  createDatasourceInstance,
  createMapInstance,
  getNearestRouteIdx,
  LONDON_BBOX
} from '../../../utils/map'
type AddEditMapNotReactiveData = {
  processDraggingRoutePayload: ProcessDraggingRoutePayload | null
  routePath: azureMapsControl.data.Position[]
}

const ADD_EDIT_MAP_NOT_REACTIVE_DATA: AddEditMapNotReactiveData = {
  processDraggingRoutePayload: null,
  routePath: []
}

enum LAYER_ID {
  LAYER_OLD_ROUTE_VIEW_ID = 'LAYER_OLD_ROUTE_VIEW_ID',
  LAYER_NEW_ROUTE_VIEW_ID = 'LAYER_NEW_ROUTE_VIEW_ID',
  LAYER_ADDITION_LINE_EXTRA_CREAING_ID = 'LAYER_ADDITION_LINE_EXTRA_CREAING_ID',
  LAYER_HAZARD_ID = 'LAYER_HAZARD_ID',
  LAYER_HAZARD_POINTS_ID = 'LAYER_HAZARD_POINTS_ID'
}

enum DATA_SOURCE_ID {
  DATA_SOURCE_NEW_ROUTE_ID = 'DATA_SOURCE_NEW_ROUTE_ID',
  DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING_ID = 'DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING_ID',
  DATA_SOURCE_HAZARDS_ID = 'DATA_SOURCE_HAZARDS_ID',
  DATA_SOURCE_OLD_ROUTE_ID = 'DATA_SOURCE_OLD_ROUTE_ID',
  DATA_SOURCE_HAZARD_POINTS_ID = 'DATA_SOURCE_HAZARD_POINTS_ID'
}

export type AddEditMapProps = {
  previewRouteGeoJson: Route['routeGeoJson'] | RouteGeoJson['previewRouteGeoJson'] | null
  currentRouteGeoJson: Route['routeGeoJson'] | RouteGeoJson['previewRouteGeoJson'] | null
  waypoints: Route['waypoints'] | null
  routePath: azureMapsControl.data.Position[]
  countActiveMapActions: number
  actionMapResolver: (action: Actions) => void
}

export type ProcessDraggingRoutePayload = {
  leftWaypoint: {
    position: azureMapsControl.data.Position
    routeIdx: number
  }
  draggingMousePointPosition: azureMapsControl.data.Position
  rightWaypoint: {
    position: azureMapsControl.data.Position
    routeIdx: number
  }
}

const AddEditMap: FC<AddEditMapProps> = ({
  waypoints,
  currentRouteGeoJson,
  previewRouteGeoJson,
  routePath,
  countActiveMapActions,
  actionMapResolver
}) => {
  const { data: AllHazards } = useHazards()
  const mapRef = useRef<HTMLDivElement>(null)

  const [mapIsLoading, setMapIsLoading] = useState(true)
  const [layersIsAdded, setLayersIsAdded] = useState(false)
  const [sourcesAreAdded, setSourcesAreAdded] = useState(false)
  const [map, setMap] = useState<azureMapsControl.Map | null>(null)
  const [oldRouteDataSource, setOldRouteDataSource] =
    useState<azureMapsControl.source.DataSource | null>(null)
  const [newRouteDataSource, setNewRouteDataSource] =
    useState<azureMapsControl.source.DataSource | null>(null)
  const [additionLineExtraCreatingDataSource, setAdditionLineExtraCreatingDataSource] =
    useState<azureMapsControl.source.DataSource | null>(null)
  const [hazardsDataSource, setHazardsDataSource] =
    useState<azureMapsControl.source.DataSource | null>(null)
  const [hazardPointsDataSource, setHazardPointsDataSource] =
    useState<azureMapsControl.source.DataSource | null>(null)
  const [isLeftBarOpen] = useLocalStorage('OPEN_LEFT_BAR', false)

  const startWayPoint = useMemo(() => {
    return waypoints?.find(({ type }) => type === WAYPOINT_TYPE.START)
  }, [currentRouteGeoJson])

  const endWayPoint = useMemo(() => {
    return waypoints?.find(({ type }) => type === WAYPOINT_TYPE.END)
  }, [currentRouteGeoJson])

  useEffect(() => {
    ADD_EDIT_MAP_NOT_REACTIVE_DATA.routePath = routePath
  }, [routePath])

  useEffect(() => {
    if (map) {
      map.resize()
    }
  }, [isLeftBarOpen])

  useEffect(() => {
    if (mapRef && mapRef.current) {
      const { current: mapContainer } = mapRef
      const newMapInstance = createMapInstance(mapContainer)

      newMapInstance.events.add('load', () => {
        const oldRoute = createDatasourceInstance(DATA_SOURCE_ID.DATA_SOURCE_OLD_ROUTE_ID)
        const newRoute = createDatasourceInstance(DATA_SOURCE_ID.DATA_SOURCE_NEW_ROUTE_ID)
        const additionLineExtraCreating = createDatasourceInstance(
          DATA_SOURCE_ID.DATA_SOURCE_ADDITION_LINE_EXTRA_CREATING_ID
        )
        const hazards = createDatasourceInstance(DATA_SOURCE_ID.DATA_SOURCE_HAZARDS_ID)
        const hazardPoints = createDatasourceInstance(DATA_SOURCE_ID.DATA_SOURCE_HAZARD_POINTS_ID)

        newMapInstance.events.add('sourceadded', (source) => {
          const sources = newMapInstance.sources.getSources()

          const flag = Object.values(DATA_SOURCE_ID).every((id) =>
            sources.some((source) => source.getId() === id)
          )
          if (flag) {
            setOldRouteDataSource(oldRoute)
            setNewRouteDataSource(newRoute)
            setAdditionLineExtraCreatingDataSource(additionLineExtraCreating)
            setHazardsDataSource(hazards)
            setHazardPointsDataSource(hazardPoints)
          }
          setSourcesAreAdded(flag)
        })

        newMapInstance.sources.add([
          oldRoute,
          newRoute,
          additionLineExtraCreating,
          hazards,
          hazardPoints
        ])
        //The creating data sources

        newMapInstance.setCamera({ bounds: LONDON_BBOX, padding: 50 })

        setMapIsLoading(false)
        setMap(newMapInstance)
      })
    }
  }, [mapRef.current])

  useEffect(
    () => () => {
      ADD_EDIT_MAP_NOT_REACTIVE_DATA.processDraggingRoutePayload = null
      ADD_EDIT_MAP_NOT_REACTIVE_DATA.routePath = []
    },
    []
  )

  useEffect(() => {
    if (currentRouteGeoJson && map) {
      const { bbox } = currentRouteGeoJson
      bbox && map.setCamera({ bounds: bbox, padding: 50 })
    }
  }, [startWayPoint, endWayPoint, map])

  // Add the layers to the map
  useEffect(() => {
    if (
      !mapIsLoading &&
      sourcesAreAdded &&
      map &&
      oldRouteDataSource &&
      newRouteDataSource &&
      additionLineExtraCreatingDataSource &&
      hazardsDataSource &&
      hazardPointsDataSource
    ) {
      //The creating layers
      const oldRouteViewLayer = new azureMapsControl.layer.LineLayer(
        oldRouteDataSource,
        LAYER_ID.LAYER_OLD_ROUTE_VIEW_ID,
        {
          strokeColor: COLORS.MAIN_GRAY,
          strokeWidth: 5,
          lineJoin: 'round',
          lineCap: 'square'
        }
      )

      const newRouteViewLayer = new azureMapsControl.layer.LineLayer(
        newRouteDataSource,
        LAYER_ID.LAYER_NEW_ROUTE_VIEW_ID,
        {
          strokeColor: COLORS.MAIN_BLUE,
          strokeWidth: 10,
          lineJoin: 'round',
          lineCap: 'square'
        }
      )

      const additionLineExtraCreatingLayer = new azureMapsControl.layer.LineLayer(
        additionLineExtraCreatingDataSource,
        LAYER_ID.LAYER_ADDITION_LINE_EXTRA_CREAING_ID,
        {
          strokeColor: COLORS.MAIN_BLUE,
          strokeWidth: 5,
          lineJoin: 'round',
          lineCap: 'round'
        }
      )

      const hazardLayer = new azureMapsControl.layer.LineLayer(
        hazardsDataSource,
        LAYER_ID.LAYER_HAZARD_ID,
        {
          strokeColor: COLORS.RED,
          strokeWidth: 5,
          lineJoin: 'round',
          lineCap: 'round'
        }
      )
      const hazardPoints = new azureMapsControl.layer.SymbolLayer(
        hazardPointsDataSource,
        LAYER_ID.LAYER_HAZARD_POINTS_ID,
        {
          minZoom: 1,
          maxZoom: 0,
          iconOptions: {
            image: 'hazard',
            anchor: 'center',
            allowOverlap: false
          }
        }
      )

      const layers = [
        oldRouteViewLayer,
        newRouteViewLayer,
        additionLineExtraCreatingLayer,
        hazardLayer,
        hazardPoints
      ]

      map.events.add('zoom', (b) => {
        const zoom = map.getCamera().zoom ?? 0
        hazardPoints.setOptions({
          minZoom: 1,
          maxZoom: 0,
          iconOptions: {
            image: 'hazard',
            anchor: 'center',
            allowOverlap: zoom > 10
          }
        })
      })

      //The creating layers
      map.events.add('layeradded', (b) => {
        const l = map.layers.getLayers()
        const flag = Object.values(LAYER_ID).every((id) => l.some((l1) => l1.getId() === id))
        setLayersIsAdded(flag)
      })

      map.layers.add(layers, 'labels')
    }
  }, [
    mapIsLoading,
    sourcesAreAdded,
    map,
    oldRouteDataSource,
    newRouteDataSource,
    additionLineExtraCreatingDataSource,
    hazardsDataSource,
    hazardPointsDataSource
  ])
  // Add the layers to the map

  const routeMouseDown = useCallback(
    (e: azureMapsControl.MapMouseEvent) => {
      const { position, originalEvent } = e

      if (
        position &&
        originalEvent &&
        //@ts-ignore
        originalEvent.target &&
        //@ts-ignore
        originalEvent.target.tagName === 'CANVAS' &&
        map
      ) {
        //skip if it is marker 'DIV'
        const markers = map.markers.getMarkers()

        const routeIdx = getNearestRouteIdx(position!, ADD_EDIT_MAP_NOT_REACTIVE_DATA.routePath)

        const markersInRightOrderByRoute = [...markers].sort((a, b) => {
          const aRouteIdx = a.getOptions().routeIdx
          const bRouteIdx = b.getOptions().routeIdx
          return aRouteIdx - bRouteIdx
        })

        const rightMarkerIndex = markersInRightOrderByRoute.findIndex(
          (marker) => marker.getOptions().routeIdx > routeIdx
        )

        const leftMarkerIndex = rightMarkerIndex - 1

        const rightMarker = markersInRightOrderByRoute[rightMarkerIndex]

        const leftMarker = markersInRightOrderByRoute[leftMarkerIndex]

        ADD_EDIT_MAP_NOT_REACTIVE_DATA.processDraggingRoutePayload = {
          leftWaypoint: {
            routeIdx: leftMarker.getOptions().routeIdx,
            position: leftMarker.getOptions().position!
          },
          draggingMousePointPosition: position!,
          rightWaypoint: {
            routeIdx: rightMarker.getOptions().routeIdx,
            position: rightMarker.getOptions().position!
          }
        }

        //Disable the maps dragging capabilities.
        map.setUserInteraction({ dragPanInteraction: false })
      }
    },
    [map, routePath]
  )

  // Add to the map route event listeners
  useEffect(() => {
    if (map && !mapIsLoading && layersIsAdded) {
      const newRouteViewLayer = map.layers.getLayerById(LAYER_ID.LAYER_NEW_ROUTE_VIEW_ID)
      map.events.remove(
        'mousedown',
        //@ts-ignore
        newRouteViewLayer
      )
      //@ts-ignore
      map.events.remove('mousemove', mouseMoved)
      //@ts-ignore
      map.events.remove('mouseup', mouseUp)
      // //The additing map events
      map.events.add('mousedown', newRouteViewLayer, routeMouseDown)
      map.events.add('mousemove', mouseMoved)
      map.events.add('mouseup', mouseUp)
      // //The additing map events
    }
  }, [mapIsLoading, layersIsAdded, map, currentRouteGeoJson, routeMouseDown, routePath])
  // Add to the map route event listeners

  // Add the hazards to the map
  useEffect(() => {
    if (
      !mapIsLoading &&
      AllHazards &&
      AllHazards.length &&
      layersIsAdded &&
      map &&
      hazardPointsDataSource &&
      hazardsDataSource
    ) {
      map.imageSprite.add('hazard', hazardMarkerHTML).then(() => {
        const hazards = AllHazards.filter(({ enabled }) => enabled)
        hazards
          .map(({ waypoints }) =>
            waypoints.filter(
              ({ type }) => type === HAZARD_WAYPOINT_TYPE.END || type === HAZARD_WAYPOINT_TYPE.START
            )
          )
          .flatMap((v) => v)
          .forEach(({ point }) =>
            hazardPointsDataSource!.add(
              new azureMapsControl.data.Feature(new azureMapsControl.data.Point(point))
            )
          )

        hazards.forEach(({ routeGeoJson }) => {
          hazardsDataSource!.add(routeGeoJson)
        })
      })
    }
  }, [mapIsLoading, AllHazards, layersIsAdded, hazardsDataSource, hazardPointsDataSource, map])
  // Add the hazards to the map

  // Add markers(Waypoints) to the map
  useEffect(() => {
    if (!mapIsLoading && map) {
      map.markers.clear()
      if (waypoints && waypoints.length) {
        const intermediateWaypoints = waypoints.filter(
          ({ type }) => type === WAYPOINT_TYPE.EXTRA || type === WAYPOINT_TYPE.STOP
        )
        const markers = waypoints.map(({ point, type, name }, index) => {
          let text = ''

          if (type === WAYPOINT_TYPE.STOP) {
            const stopOrderNumber = waypoints
              .filter(({ type }) => type === WAYPOINT_TYPE.STOP)
              .findIndex(
                ({ name: stopName, point: stopPoint }) =>
                  `${stopPoint[0]}_${stopPoint[1]}` === `${point[0]}_${point[1]}`
              )
            text = `${stopOrderNumber + 1}`
          } else if (type === WAYPOINT_TYPE.EXTRA) {
            const lastNotExtraBeforeIndex = waypoints
              .slice(0, index)
              //@ts-ignore
              .findLastIndex(({ type }) => type !== WAYPOINT_TYPE.EXTRA)
            if (lastNotExtraBeforeIndex === -1) {
              text = `0-${index + 1}`
            } else {
              if (waypoints[lastNotExtraBeforeIndex]!.type === WAYPOINT_TYPE.START) {
                text = `A-${index}`
              } else {
                const stops = intermediateWaypoints.filter(
                  (intermediateWaypoint) => intermediateWaypoint.type === WAYPOINT_TYPE.STOP
                )
                const stopDirectionNumber = stops.findIndex(
                  ({ name, point: stopPoint }) =>
                    `${stopPoint[0]}_${stopPoint[1]}` ===
                    `${waypoints[lastNotExtraBeforeIndex]!.point[0]}_${
                      waypoints[lastNotExtraBeforeIndex]!.point[1]
                    }`
                )

                text = `${stopDirectionNumber + 1}-${index - lastNotExtraBeforeIndex}`
              }
            }
          }

          const intermediateWaypointIndex = intermediateWaypoints.findIndex(
            ({ name: intermediateWaypointName, point: intermediateWaypointPoint }) =>
              `${intermediateWaypointPoint[0]}_${intermediateWaypointPoint[1]}` ===
              `${point[0]}_${point[1]}`
          )

          const routeIdx = getNearestRouteIdx(point, ADD_EDIT_MAP_NOT_REACTIVE_DATA.routePath)

          const additionMarkerOptions = {
            intermediateWaypointIndex:
              intermediateWaypointIndex !== -1 ? intermediateWaypointIndex : undefined,
            type,
            routeIdx
          }

          const marker = new azureMapsControl.HtmlMarker({
            htmlContent: HTML_MARKER_BY_WAYPOINT_TYPE[type],
            text: text,
            anchor: 'center',
            position: point,
            draggable: true
          })

          //@ts-ignore
          marker.options = {
            //@ts-ignore
            ...marker.options,
            ...additionMarkerOptions
          }

          return marker
        })

        //@ts-ignore
        map.events.add('dragend', markers, markerDragEnd)
        map.events.add(
          //@ts-ignore
          'contextmenu',
          markers,
          removeWaypointByMarkerRightClick
        )
        map.markers.add(markers)
      }
    }
  }, [mapIsLoading, waypoints, routePath, map])
  // Add markers(Waypoints) to the map

  //Add preview route to the map
  useEffect(() => {
    if (!mapIsLoading && map && oldRouteDataSource) {
      oldRouteDataSource.clear()
      if (previewRouteGeoJson) {
        oldRouteDataSource.add(previewRouteGeoJson)
      }
    }
  }, [mapIsLoading, previewRouteGeoJson, oldRouteDataSource, map])
  //Add preview route to the map

  //Add route to the map
  useEffect(() => {
    if (!mapIsLoading && map && newRouteDataSource) {
      newRouteDataSource.clear()
      if (currentRouteGeoJson) {
        newRouteDataSource.add(currentRouteGeoJson)
      }
    }
  }, [mapIsLoading, currentRouteGeoJson, newRouteDataSource, map])
  //Add route to the map

  // Map marker event listeners
  const markerDragEnd = async (
    e: { target: azureMapsControl.HtmlMarker } & azureMapsControl.TargetedEvent
  ) => {
    actionMapResolver({ type: 'StartActionActionType', payload: {} })

    const { type, intermediateWaypointIndex, position }: azureMapsControl.HtmlMarkerOptions =
      e.target.getOptions()

    const waypoint: Waypoint = {
      name: '',
      point: [position![0], position![1]],
      type: WAYPOINT_TYPE.START //default value
    }

    const response = await searchReverse(position!)
    const data = response.geojson.getFeatures()

    if (
      data.features.length > 0 &&
      data.features[0].properties &&
      data.features[0].properties.address &&
      data.features[0].properties.address.freeformAddress
    ) {
      //
      const {
        features: [
          {
            properties: {
              address: { freeformAddress }
            }
          }
        ]
      } = data

      waypoint.name = freeformAddress
    }

    switch (type) {
      case WAYPOINT_TYPE.START: {
        waypoint.type = WAYPOINT_TYPE.START
        const action: Actions = {
          type: 'UpdateStartActionType',
          payload: {
            waypoint
          }
        }
        actionMapResolver(action)
        break
      }
      case WAYPOINT_TYPE.END: {
        waypoint.type = WAYPOINT_TYPE.END
        const action: Actions = {
          type: 'UpdateEndActionType',
          payload: {
            waypoint
          }
        }
        actionMapResolver(action)
        break
      }
      case WAYPOINT_TYPE.STOP: {
        waypoint.type = WAYPOINT_TYPE.STOP
        const action: Actions = {
          type: 'UpdateStopActionType',
          payload: {
            waypoint,
            intermediateWaypointIndex
          }
        }
        actionMapResolver(action)
        break
      }
      case WAYPOINT_TYPE.EXTRA: {
        waypoint.type = WAYPOINT_TYPE.EXTRA
        const action: Actions = {
          type: 'UpdateExtraActionType',
          payload: {
            waypoint,
            intermediateWaypointIndex
          }
        }
        actionMapResolver(action)
        break
      }
    }

    setTimeout(() => {
      actionMapResolver({ type: 'EndActionActionType', payload: {} })
    }, 2000)
  }

  const removeWaypointByMarkerRightClick = (
    e: { target: azureMapsControl.HtmlMarker } & azureMapsControl.TargetedEvent
  ) => {
    actionMapResolver({ type: 'StartActionActionType', payload: {} })

    const { type, intermediateWaypointIndex }: azureMapsControl.HtmlMarkerOptions =
      e.target.getOptions()

    switch (type) {
      case WAYPOINT_TYPE.START: {
        actionMapResolver({ type: 'RemoveStartActionType', payload: {} })
        break
      }
      case WAYPOINT_TYPE.END: {
        actionMapResolver({ type: 'RemoveEndActionType', payload: {} })
        break
      }
      case WAYPOINT_TYPE.STOP: {
        actionMapResolver({ type: 'RemoveStopActionType', payload: { intermediateWaypointIndex } })
        break
      }
      case WAYPOINT_TYPE.EXTRA: {
        actionMapResolver({ type: 'RemoveExtraActionType', payload: { intermediateWaypointIndex } })
        break
      }
    }
    actionMapResolver({ type: 'EndActionActionType', payload: {} })
  }
  // Map marker event listeners

  // Map route event listeners
  const drawDirectionLinesForNewExtraWaypoint = () => {
    if (
      ADD_EDIT_MAP_NOT_REACTIVE_DATA.processDraggingRoutePayload &&
      additionLineExtraCreatingDataSource
    ) {
      const {
        leftWaypoint: { position: leftWaypointPosition },
        draggingMousePointPosition,
        rightWaypoint: { position: rightWaypointPosition }
      } = ADD_EDIT_MAP_NOT_REACTIVE_DATA.processDraggingRoutePayload

      const multilinePoints = [
        leftWaypointPosition,
        draggingMousePointPosition,
        rightWaypointPosition
      ]

      additionLineExtraCreatingDataSource.clear()
      additionLineExtraCreatingDataSource.setShapes([
        new azureMapsControl.data.LineString(multilinePoints)
      ])
    }
  }

  const mouseMoved = (e: azureMapsControl.MapMouseEvent) => {
    const { position } = e
    if (ADD_EDIT_MAP_NOT_REACTIVE_DATA.processDraggingRoutePayload && position) {
      ADD_EDIT_MAP_NOT_REACTIVE_DATA.processDraggingRoutePayload.draggingMousePointPosition =
        position
      drawDirectionLinesForNewExtraWaypoint()
    }
  }

  const mouseUp = (e: azureMapsControl.MapMouseEvent) => {
    const { position } = e
    if (
      ADD_EDIT_MAP_NOT_REACTIVE_DATA.processDraggingRoutePayload &&
      additionLineExtraCreatingDataSource &&
      map &&
      position
    ) {
      const {
        leftWaypoint: { routeIdx: leftWaypointRouteIdx }
      } = ADD_EDIT_MAP_NOT_REACTIVE_DATA.processDraggingRoutePayload

      const markers = map.markers.getMarkers()

      const markersInRightOrderByRoute = [...markers].sort((a, b) => {
        const aRouteIdx = a.getOptions().routeIdx
        const bRouteIdx = b.getOptions().routeIdx
        return aRouteIdx - bRouteIdx
      })

      const leftMarkerIndex = markersInRightOrderByRoute.findIndex(
        (marker) => marker.getOptions().routeIdx === leftWaypointRouteIdx
      )

      const leftMarker = markersInRightOrderByRoute[leftMarkerIndex]

      const type: WAYPOINT_TYPE = leftMarker.getOptions().type

      const intermediateWaypoint: Waypoint = {
        name: `The extra waypoint random id ${Math.random() * 100}`,
        point: position as Waypoint['point'],
        type: WAYPOINT_TYPE.EXTRA
      }

      switch (type) {
        case WAYPOINT_TYPE.START: {
          actionMapResolver({
            type: 'InsertExtraActionType',
            payload: {
              intermediateWaypointIndex: 0,
              waypoint: intermediateWaypoint
            }
          })
          break
        }
        case WAYPOINT_TYPE.STOP: {
          const intermediateWaypointIndex = leftMarker.getOptions().intermediateWaypointIndex
          actionMapResolver({
            type: 'InsertExtraActionType',
            payload: {
              intermediateWaypointIndex: intermediateWaypointIndex + 1,
              waypoint: intermediateWaypoint
            }
          })
          break
        }
        case WAYPOINT_TYPE.EXTRA: {
          const intermediateWaypointIndex = leftMarker.getOptions().intermediateWaypointIndex
          actionMapResolver({
            type: 'InsertExtraActionType',
            payload: {
              intermediateWaypointIndex: intermediateWaypointIndex + 1,
              waypoint: intermediateWaypoint
            }
          })
          break
        }
      }
      additionLineExtraCreatingDataSource.clear()
    }
    ADD_EDIT_MAP_NOT_REACTIVE_DATA.processDraggingRoutePayload = null

    map!.setUserInteraction({ dragPanInteraction: true })
  }
  // Map route event listeners

  return (
    <Box
      ref={mapRef}
      borderRadius='8px'
      sx={{ position: 'relative', height: '100%', width: '100%' }}
    >
      {!mapIsLoading && !!countActiveMapActions && <LinearProgress sx={{ zIndex: 1 }} />}
      {mapIsLoading && <Backdrop position='absolute' />}
    </Box>
  )
}

export default AddEditMap
