import React, { FC, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ButtonGroup from '@mui/material/ButtonGroup'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { useSnackbar } from 'notistack'
import ConfirmationDialog from '../components/UI/ConfirmationDialog'
import Switch from '../components/UI/Switch'
import TableWrapper from '../components/UI/TableWrapper'
import Search from '../components/UI/Search'

import { TABS } from '../models/route'
import { Hazard, RemoveHazardPayload, ToggleHazardPayload } from '../models/hazard'
import { useHazards, useRemoveHazard, useToggleHazard } from '../queries/hazard'
import { useTableData } from '../hooks/useTableData'
import { COLORS } from '../theme'
import { useUserMe } from '../queries/user'

const headers = [
    'Hazard name',
    'Hazard description',
    'Start point',
    'End point',
    'Hazard enabled',
    'Actions'
]

type HazardTableAggregation = Hazard & {
    startPoint: [string, string]
    endPoint: [string, string]
}
type TableData = { totalPages: number; slicedData: Hazard[]; totalItems: number; page: number }

const Hazards: FC = () => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { data: userMe } = useUserMe()
    const { status, data: hazards, isRefetching } = useHazards()
    const [isUpdating, setIsUpdating] = useState(false)
    const [removeHazard, setRemoveHazard] = useState<null | Hazard>(null)
    const [search, setSearch] = useState('')
    const { totalPages, slicedData, totalItems, page }: TableData = useTableData({
        search,
        data: hazards,
        keysToSearch: ['name']
    })

    const hazardsTableAggregation = useMemo(() => {
        return slicedData.map(({ waypoints, ...rest }) => {
            const firstWaypoint = waypoints[0]
            const lastWaypoint = waypoints.at(-1)

            const routeTableAggregation: HazardTableAggregation = {
                ...rest,
                waypoints,
                startPoint: ['Empty', 'Empty'],
                endPoint: ['Empty', 'Empty']
                // vehicleType
            }

            if (firstWaypoint) {
                const firstWaypointAfterSplit = firstWaypoint.name.split(',')
                const startPoint: [string, string] = [
                    firstWaypointAfterSplit[0],
                    firstWaypointAfterSplit.slice(1).join(',')
                ]
                routeTableAggregation.startPoint = startPoint
            }
            if (lastWaypoint) {
                const lastWaypointAfterSplit = lastWaypoint.name.split(',')
                const endPoint: [string, string] = [
                    lastWaypointAfterSplit[0],
                    lastWaypointAfterSplit.slice(1).join(',')
                ]
                routeTableAggregation.endPoint = endPoint
            }

            return routeTableAggregation
        })
    }, [slicedData])
    const state = useMemo(() => `/routes/hazards?page=${page}`, [page])
    const { mutateAsync: toggleHazardAsync } = useToggleHazard()
    const { mutateAsync: removeHazardAsync } = useRemoveHazard()

    const toggleHazard = async ({
        id,
        name,
        description,
        enabled,
        waypoints,
        routeGeoJson
    }: Hazard) => {
        enqueueSnackbar(`The request is sent.`, { variant: 'info' })
        setIsUpdating(true)
        try {
            const toggleHazardPayload: ToggleHazardPayload = {
                id,
                enabled,
                description,
                waypoints: JSON.stringify(waypoints),
                routeGeoJson: JSON.stringify(routeGeoJson),
                name
            }

            await toggleHazardAsync(toggleHazardPayload)
            const state = !enabled ? 'enabled' : 'disabled'
            enqueueSnackbar(`Hazard has been successfully ${state}.`, {
                variant: 'success'
            })
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    const onRemoveConfirm = async () => {
        setIsUpdating(true)
        try {
            if (!removeHazard) throw new Error()
            const { id, name, description, waypoints, routeGeoJson } = removeHazard
            const removeVehiclePayload: RemoveHazardPayload = {
                id,
                description,
                waypoints: JSON.stringify(waypoints),
                routeGeoJson: JSON.stringify(routeGeoJson),
                name
            }
            await removeHazardAsync(removeVehiclePayload)
            setRemoveHazard(null)
            enqueueSnackbar(`Hazard has been successfully removed.`, {
                variant: 'success'
            })
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    const rowsNode = hazardsTableAggregation.map((hazard) => (
        <TableRow
            key={hazard.id}
            sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                height: '70px',
                verticalAlign: 'top'
            }}
        >
            <TableCell align='left'>
                <Typography variant='subheadline'>{hazard.name}</Typography>
            </TableCell>
            <TableCell align='left' sx={{ maxWidth: '250px', wordWrap: 'break-word' }}>
                <Typography variant='subheadline'>{hazard.description || '-'}</Typography>
            </TableCell>
            <TableCell align='left'>
                <Typography variant='subheadline'>{hazard.startPoint[0]}</Typography>
                <br />
                <Typography variant='subheadline' color='secondary'>
                    {hazard.startPoint[1]}
                </Typography>
            </TableCell>
            <TableCell align='left'>
                <Typography variant='subheadline'>{hazard.endPoint[0]}</Typography>
                <br />
                <Typography variant='subheadline' color='secondary' paddingTop={'40px'}>
                    {hazard.endPoint[1]}
                </Typography>
            </TableCell>
            <TableCell align='left'>
                <Switch checked={hazard.enabled} onChange={() => toggleHazard(hazard)} />
            </TableCell>
            <TableCell align='left'>
                <ButtonGroup variant='text' aria-label='outlined primary button group'>
                    <IconButton
                        color='primary'
                        onClick={() => navigate(`/routes/hazards/${hazard.id}`, { state })}
                        disabled={isUpdating || isRefetching}
                    >
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                    <IconButton
                        color='error'
                        onClick={() => setRemoveHazard(hazard)}
                        disabled={isUpdating || isRefetching}
                    >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </ButtonGroup>
            </TableCell>
        </TableRow>
    ))

    return (
        <>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Search title='Hazards' onChangeSearch={(data: string) => setSearch(data.toLowerCase())} />
                <Grid padding={'16px 12px'}>
                    <Button
                        sx={{ background: userMe?.customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399' }}
                        onClick={() => navigate('/routes/hazards/add', { state })}
                        variant='contained'
                        startIcon={<AddIcon />}
                    >
                        Add hazard
                    </Button>
                </Grid>
            </Grid>
            <Tabs value={TABS.ADHOC_HAZARDS}>
                <Tab
                    value={TABS.ALL_ROUTES}
                    label={<Typography variant='textBold'>All routes</Typography>}
                    onClick={() => navigate('/routes?page=1')}
                ></Tab>
                <Tab
                    value={TABS.ADHOC_HAZARDS}
                    label={<Typography variant='textBold'>Adhoc Hazards</Typography>}
                />
            </Tabs>
            <TableWrapper
                isSuccess={status === 'success'}
                emptyResultTitle='No hazards found'
                totalPages={totalPages}
                totalItems={totalItems}
                isUpdating={isUpdating || isRefetching}
            >
                <>
                    <TableHead>
                        <TableRow sx={{ verticalAlign: 'bottom' }}>
                            {headers.map((label, i) => (
                                <TableCell align='left' key={i}>
                                    <Typography variant='subheadlineBold' color='secondary'>
                                        {label}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rowsNode}</TableBody>
                </>
            </TableWrapper>
            <ConfirmationDialog
                customer={userMe?.customer}
                open={!!removeHazard}
                onClose={() => setRemoveHazard(null)}
                onConfirm={onRemoveConfirm}
            />
        </>
    )
}

export default Hazards
