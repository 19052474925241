import React, { FC, useState, useCallback, PropsWithChildren, ReactElement } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { COLORS } from '../../theme'
import LinearProgress from '@mui/material/LinearProgress'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CloseIcon from '@mui/icons-material/Close'
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import FileUploaderArea, { STEPS, CustomFile, FileUploadAreaProps } from './FileUploadArea'

interface FileUploaderProps {
  onSave: (file: CustomFile) => void
  title: string
  accept: FileUploadAreaProps['accept']
}

const FileUploader: FC<PropsWithChildren<FileUploaderProps>> = ({
  onSave,
  title,
  accept,
  children
}) => {
  const [fileUploaderAreaStep, setFileUploaderAreaStep] = useState<STEPS>(
    STEPS.AWAITING_DROP_OR_UPLOAD
  )

  const [file, setFile] = useState<CustomFile | null>(null)
  const [open, setOpen] = useState(false)

  const [errorText, setErrorText] = useState<string | null>(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const save = () => {
    onSave(file!)
    handleClose()
  }

  return (
    <>
      {React.cloneElement(children as ReactElement, { onClick: handleOpen })}
      <Dialog open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { minWidth: '670px' } }}>
        {fileUploaderAreaStep === STEPS.AWAITING_DROP_OR_UPLOAD && (
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', right: '10px', top: '10px' }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        )}
        <DialogTitle textAlign={'center'} mt='32px' mb='8px' variant='title1'>
          {title}
        </DialogTitle>
        <DialogContent>
          <FileUploaderArea
            accept={accept}
            onStepChange={(step: STEPS) => setFileUploaderAreaStep(step)}
            onLoad={(file) => setFile(file)}
            onError={(errorText) => setErrorText(errorText)}
          />
        </DialogContent>
        <Box sx={{ padding: '0 24px 48px 24px', textAlign: 'center' }}>
          {(fileUploaderAreaStep === STEPS.START_READING ||
            fileUploaderAreaStep === STEPS.FINISH_READING) && (
            <DialogActions sx={{ padding: 'unset' }} disableSpacing>
              <Button sx={{ width: '50%' }} variant='outlined' onClick={handleClose}>
                Cancel
              </Button>
              <Box width='16px'></Box>
              <Button
                disabled={fileUploaderAreaStep !== STEPS.FINISH_READING}
                sx={{ width: '50%' }}
                variant='contained'
                onClick={save}
                autoFocus
              >
                Save
              </Button>
            </DialogActions>
          )}
          {fileUploaderAreaStep === STEPS.ERROR_READING && (
            <Typography variant='subheadline' color='error'>
              {errorText}
            </Typography>
          )}
        </Box>
      </Dialog>
    </>
  )
}

export default FileUploader
