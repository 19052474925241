import React, { FC, useState } from 'react'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import IconButton from '@mui/material/IconButton'
import ButtonGroup from '@mui/material/ButtonGroup'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddIcon from '@mui/icons-material/Add'

import { useSnackbar } from 'notistack'

import TableWrapper from '../components/UI/TableWrapper'
import Search from '../components/UI/Search'
import ConfirmationDialog from '../components/UI/ConfirmationDialog'
import UserForm, { UserSchema } from '../components/user/UserForm'
import UserFileUploader, { BulkUserPayload } from '../components/user/UserFileUploader'

import { useUser, useAddUser, useUpdateUser, useRemoveUser, useAddBulkUser, useUserMe } from '../queries/user'
import { useOperator } from '../queries/operator'
import { defaultUser, User as UserType } from '../models/user'
import { useTableData } from '../hooks/useTableData'
import { COLORS } from '../theme'

const headerColumn = [
    'Name',
    'Email',
    'Contact number',
    'Operator name',
    'Unique Identifier',
    'NFC card',
    'Admin',
    'Actions'
]
const widthStyle = { maxWidth: '250px', wordWrap: 'break-word' }

const User: FC = () => {
    const { status, data: users, isRefetching } = useUser()
    const { data: operators } = useOperator()
    const { data: userMe } = useUserMe()
    const [search, setSearch] = useState('')
    const { enqueueSnackbar } = useSnackbar()
    const [isUpdating, setIsUpdating] = useState(false)
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [editUser, setEditUser] = useState<UserType>(defaultUser)
    const [removeUser, setRemoveUser] = useState<null | UserType>(null)
    const { totalPages, slicedData, totalItems } = useTableData({
        search,
        data: users,
        keysToSearch: ['firstName', 'lastName']
    })

    const { mutateAsync: addUserAsync } = useAddUser()
    const { mutateAsync: updateUserAsync } = useUpdateUser()
    const { mutateAsync: removeUserAsync } = useRemoveUser()
    const { mutateAsync: addBulkUserAsync } = useAddBulkUser()

    const onSave = async (user: UserSchema, id?: number) => {
        setIsUpdating(true)
        try {
            let email = user?.email || ''
            if (user.isNfc && !user.isAdmin) {
                email = `${user.uniqueId}@${user.customerName.toLowerCase().replace(/ /g, '')}.com`
            }
            const payload = {
                id: id || 0,
                customerName: user?.customerName,
                email,
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                mobile: user?.mobile || '',
                phone: '',
                partnerName: '',
                isAdmin: user.isAdmin,
                isNfc: user.isNfc,
                partnerId: 0,
                customerId: 0
            }
            if (id) {
                await updateUserAsync(payload)
            } else {
                await addUserAsync(payload)
            }
            enqueueSnackbar(`User has been successfully ${id ? 'updated' : 'added'}.`, {
                variant: 'success'
            })
            setEditUser(defaultUser)
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    type OnFileSave = { payload: BulkUserPayload[]; customer: string }
    const onFileSave = async ({ payload, customer }: OnFileSave) => {
        setIsUpdating(true)

        try {
            const newPayload = payload.map((item) => ({
                id: 0,
                customerName: customer,
                email: item.email,
                firstName: item.name,
                lastName: item?.surname || '',
                mobile: item?.mobile || '',
                phone: item?.phone || '',
                partnerName: '',
                isAdmin: item.admin,
                isNfc: item.nfc,
                partnerId: 0,
                customerId: 0
            }))
            const { successful, failed } = await addBulkUserAsync(newPayload)
            let message = `You successfully added ${successful.length} users`
            if (successful.length && !failed.length) {
                enqueueSnackbar(message, { variant: 'success' })
            } else if (successful.length && failed.length) {
                message += `, but ${failed.length} were failed for some reasons`
                enqueueSnackbar(message, { variant: 'info' })
            } else if (!successful.length && failed.length) {
                enqueueSnackbar(
                    `Adding of ${failed.length} users were failed for some reasons. Try to change data`,
                    {
                        variant: 'error'
                    }
                )
            }
        } catch (err) {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    const onRemoveConfirm = async () => {
        setIsUpdating(true)
        try {
            if (removeUser) {
                const payload = {
                    ...removeUser,
                    partnerId: 0,
                    customerId: 0
                }
                await removeUserAsync(payload)
            }
            setRemoveUser(null)
            enqueueSnackbar(`User has been successfully removed.`, {
                variant: 'success'
            })
        } catch {
            enqueueSnackbar(`Something went wrong!`, { variant: 'error' })
        }
        setIsUpdating(false)
    }

    const rowsNode = (
        <>
            {slicedData.map((user, i) => (
                <TableRow sx={{ height: '52px', verticalAlign: 'middle' }} key={i}>
                    <TableCell component='th' scope='row'>
                        <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
                    </TableCell>
                    <TableCell component='th' scope='row' sx={widthStyle}>
                        <Typography>{user.email}</Typography>
                    </TableCell>
                    <TableCell component='th' scope='row' sx={widthStyle}>
                        <Typography>{user.mobile || user.phone || '-'}</Typography>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                        <Typography>{user.customerName}</Typography>
                    </TableCell>
                    <TableCell component='th' scope='row' sx={widthStyle}>
                        <Typography>{user.uniqueId || '-'}</Typography>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                        <Typography>{user.isNfc ? 'Yes' : '-'}</Typography>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                        <Typography>{user.isAdmin ? 'Yes' : '-'}</Typography>
                    </TableCell>
                    <TableCell align='left'>
                        <ButtonGroup variant='text' aria-label='outlined primary button group'>
                            <IconButton
                                color='primary'
                                onClick={() => {
                                    setEditUser(user)
                                    setIsFormOpen(true)
                                }}
                                disabled={isUpdating || isRefetching}
                            >
                                <ModeEditOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                                color='error'
                                onClick={() => setRemoveUser(user)}
                                disabled={isUpdating || isRefetching}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
            ))}
        </>
    )

    const headNode = (
        <>
            {headerColumn.map((label) => (
                <TableCell align='left' key={label}>
                    <Typography variant='subheadlineBold' color='secondary'>
                        {label}
                    </Typography>
                </TableCell>
            ))}
        </>
    )

    return (
        <>
            <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='auto'
            >
                <Search title='Users' onChangeSearch={(data: string) => setSearch(data.toLowerCase())} />
                <Grid padding={'16px 12px'}>
                    <UserFileUploader onSave={onFileSave} />
                    <Button
                        sx={{ background: userMe?.customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399' }}
                        onClick={() => {
                            setEditUser(defaultUser)
                            setIsFormOpen(true)
                        }}
                        variant='contained'
                        startIcon={<AddIcon />}
                    >
                        Add new User
                    </Button>
                </Grid>
            </Grid >
            <TableWrapper
                isSuccess={status === 'success'}
                emptyResultTitle='No users found'
                totalPages={totalPages}
                totalItems={totalItems}
                isUpdating={isUpdating || isRefetching}
            >
                <>
                    <TableHead>
                        <TableRow>{headNode}</TableRow>
                    </TableHead>
                    <TableBody>{rowsNode}</TableBody>
                </>
            </TableWrapper>
            {
                isFormOpen && (
                    <UserForm
                        customer={userMe?.customer}
                        open={isFormOpen}
                        onClose={() => {
                            setEditUser(defaultUser)
                            setIsFormOpen(false)
                        }}
                        onSave={onSave}
                        user={editUser}
                    />
                )
            }
            <ConfirmationDialog
                customer={userMe?.customer}
                open={!!removeUser}
                onClose={() => setRemoveUser(null)}
                onConfirm={onRemoveConfirm}
            />
        </>
    )
}

export default User
