import React, { FC, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

import * as z from 'zod'
import { NumericFormat } from 'react-number-format'
import { useForm, Controller } from 'react-hook-form'

import { FormTextField } from '../UI/FormTextField'

import { zodResolver } from '@hookform/resolvers/zod'
import { getErrorMessage } from '../../utils/reactHookForm'
import { Vehicle } from '../../models/vehicle'
import { str, namesCheck } from '../../assets/constans/formValidation'
import { COLORS } from '../../theme'

const numPreprocess = (n = 9.9, text = 'Too large') =>
    z.preprocess((val) => Number(`${val}`.replaceAll(' ', '')), z.number().max(n, text).positive())

const vehicleSchemaForm = z.object({
    name: namesCheck,
    width: str.min(1, 'Expected number'),
    height: str.min(1, 'Expected number'),
    length: str.min(1, 'Expected number'),
    weight: str.min(1, 'Expected number')
})
const vehicleTransformSchema = z.object({
    name: namesCheck,
    width: numPreprocess(),
    height: numPreprocess(),
    length: numPreprocess(15),
    weight: numPreprocess(99999, 'Too heavy')
})
const keys = vehicleSchemaForm.keyof().Values

type VehicleSchemaForm = ReturnType<typeof vehicleSchemaForm['parse']>
type Keys = keyof typeof keys

export type VehicleTransformSchema = ReturnType<typeof vehicleTransformSchema['parse']>

interface VehicleFormProps {
    customer: string | undefined
    open: boolean
    onClose: () => void
    onSave: (vehicle: VehicleTransformSchema, id?: number) => void
    vehicle: Vehicle | null
}

const defaultValues: VehicleSchemaForm = {
    name: '',
    width: '',
    height: '',
    length: '',
    weight: ''
}

const VehicleForm: FC<VehicleFormProps> = ({ customer, open, vehicle, onClose, onSave }) => {
    const {
        handleSubmit,
        watch,
        setError,
        reset,
        control,
        formState: { errors }
    } = useForm<VehicleSchemaForm>({
        defaultValues,
        resolver: zodResolver(vehicleSchemaForm)
    })

    useEffect(() => {
        if (vehicle) {
            const prefilledFields: VehicleSchemaForm = {
                name: vehicle.name,
                height: `${vehicle.height}`,
                width: `${vehicle.width}`,
                length: `${vehicle.length}`,
                weight: `${vehicle.weight}`
            }
            reset(prefilledFields)
        }
    }, [vehicle])

    const onSubmit = handleSubmit((vehicleForm) => {
        const transformResult = vehicleTransformSchema.safeParse(vehicleForm)
        if (transformResult.success) {
            onSave(transformResult.data, vehicle?.id)
            close()
            return
        }
        transformResult.error.issues.forEach((error) => {
            const message = error.message
            const key = error.path[0] as keyof typeof keys
            setError(key, { type: 'custom', message })
        })
        return
    })

    const close = () => {
        reset(defaultValues, { keepDefaultValues: true })
        onClose()
    }

    const errText = (key: Keys) => (errors[key] ? getErrorMessage(errors[key]!) : '')

    return (
        <Dialog
            keepMounted={false}
            open={open}
            onClose={close}
            sx={{ '& .MuiPaper-root': { width: '470px', height: 'auto', padding: '24px' } }}
        >
            <Grid
                container
                justifyContent='space-around'
                direction='column'
                alignItems='center'
                height='100%'
            >
                <form onSubmit={onSubmit}>
                    <DialogTitle textAlign='center' mt='8px' mb='16px' width='400px' variant='title1'>
                        <Typography variant='title1Bold'>
                            {vehicle ? 'Edit vehicle' : 'Add new vehicle'}
                        </Typography>
                    </DialogTitle>
                    <Grid container direction='column'>
                        <Typography mb='16px' component={'div'} variant='subheadlineBold' color='secondary'>
                            Main information
                        </Typography>
                        <Controller
                            control={control}
                            name={keys.name}
                            render={({ field }) => (
                                <FormTextField
                                    textFieldProps={{
                                        label: 'Vehicle name',
                                        required: true
                                    }}
                                    error={errText('name')}
                                    field={field}
                                />
                            )}
                        />
                        <Typography mt='32px' mb='24px' variant='subheadlineBold' color='secondary'>
                            Vehicle dimensions
                        </Typography>
                        <Grid container spacing={2} justifyContent='space-between'>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={keys.height}
                                    render={({ field }) => {
                                        return (
                                            <NumericFormat
                                                label='Height'
                                                sx={{ width: '200px' }}
                                                decimalScale={2}
                                                placeholder='0.0'
                                                allowNegative={false}
                                                thousandSeparator=' '
                                                error={!!errors[keys.height]}
                                                helperText={errText('height')}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position='start'>m</InputAdornment>
                                                }}
                                                customInput={TextField}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={keys.length}
                                    render={({ field }) => {
                                        return (
                                            <NumericFormat
                                                label='Length'
                                                sx={{ width: '200px' }}
                                                decimalScale={2}
                                                placeholder='0.0'
                                                allowNegative={false}
                                                thousandSeparator=' '
                                                error={!!errors[keys.length]}
                                                helperText={errText('length')}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position='start'>m</InputAdornment>
                                                }}
                                                customInput={TextField}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt='8px' container spacing={2} justifyContent='space-between'>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={keys.width}
                                    render={({ field }) => {
                                        return (
                                            <NumericFormat
                                                label='Width'
                                                sx={{ width: '200px' }}
                                                decimalScale={2}
                                                placeholder='0.0'
                                                allowNegative={false}
                                                thousandSeparator=' '
                                                error={!!errors[keys.width]}
                                                helperText={errText('width')}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position='start'>m</InputAdornment>
                                                }}
                                                customInput={TextField}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <Controller
                                    control={control}
                                    name={keys.weight}
                                    render={({ field }) => {
                                        return (
                                            <NumericFormat
                                                label='Weight'
                                                sx={{ width: '200px' }}
                                                decimalScale={0}
                                                placeholder='0'
                                                allowNegative={false}
                                                thousandSeparator=' '
                                                error={!!errors[keys.weight]}
                                                helperText={errText('weight')}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position='start'>kg</InputAdornment>
                                                }}
                                                customInput={TextField}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        mt='14px'
                        container
                        justifyContent='space-between'
                        alignItems='flex-end'
                        spacing={2}
                    >
                        <Grid item>
                            <Button sx={{ minWidth: '200px' }} variant='outlined' onClick={close}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                disabled={!!Object.keys(errors).length || !watch('name')}
                                sx={{
                                    minWidth: '200px',
                                    background: customer === "Go Ahead London" ? COLORS.MAIN_BLUE : '#667399'
                                }}
                                variant='contained'
                                type='submit'
                                autoFocus
                            >
                                {vehicle ? 'Update' : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Dialog>
    )
}

export default VehicleForm
