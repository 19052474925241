import React, { FC, useCallback, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddIcon from '@mui/icons-material/Add'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import { DragAndDropItemWrapper } from '../../UI/DragAndDropItemWrapper'
import AddressPOILookup, { AddressPOILookupValue } from '../../lookups/AddressPOILookup'

import { Waypoint, WAYPOINT_TYPE } from '../../../models/route'
import { getErrorMessage } from '../../../utils/reactHookForm'
import { RouteFormSchema, keys } from './routeFormModel'

const Stops: FC = () => {
  const { enqueueSnackbar } = useSnackbar()

  /*
  https://github.com/react-hook-form/react-hook-form/discussions/8229
  The react hook form doesn't support multiple useFieldArray with the same name.
  */
  const {
    control,
    formState: { errors },
    //@ts-ignore
    fields,
    //@ts-ignore
    append,
    //@ts-ignore
    remove,
    //@ts-ignore
    move
  } = useFormContext<RouteFormSchema>()

  const [abortDragging, setAbortDragging] = useState(false)

  const intermediateWaypoints = useWatch({ control, name: keys.intermediateWaypoints })
  const startWaypoint = useWatch({ control, name: keys.startWaypoint })
  const endWaypoint = useWatch({ control, name: keys.endWaypoint })

  const getAddressPOILookupValue = useCallback(
    (
      value:
        | RouteFormSchema['startWaypoint']
        | RouteFormSchema['endWaypoint']
        | RouteFormSchema['intermediateWaypoints'][number]
    ): AddressPOILookupValue | null => (value ? { name: value.name, point: value.point } : null),
    []
  )

  const getStopLabel = (id: string) => {
    const index = fields
      //@ts-ignore
      .filter(({ type }) => type === WAYPOINT_TYPE.STOP)
      //@ts-ignore
      .findIndex(({ id: currentId }) => currentId === id)
    return `Stop ${index + 1}`
  }

  const addStop = () => {
    const waypoints = [startWaypoint, ...intermediateWaypoints, endWaypoint].filter(
      (waypoint): waypoint is Waypoint => !!waypoint
    )
    if (waypoints.length) {
      const { point } = waypoints.at(-1)!
      const d = 0.002
      const waypoint: Waypoint = {
        name: '',
        point: [point[0] + d, point[1] + d],
        type: WAYPOINT_TYPE.STOP
      }
      //Add a waypoint with a random position near the last waypoint in the correct direction
      append(waypoint)
    } else {
      enqueueSnackbar(`Please, add a start or an end waypoint.`, {
        variant: 'warning'
      })
    }
  }

  return (
    <Grid container flexDirection='column' alignItems='start' justifyContent='start' mt='24px'>
      <Typography variant='bodyBold' color='secondary'>
        Stops
      </Typography>

      <Grid
        container
        spacing={2}
        flexDirection='column'
        alignItems='start'
        justifyContent='start'
        mt='0'
      >
        <DndProvider backend={HTML5Backend}>
          {fields.map(
            //@ts-ignore
            (field, intermediateWaypointIndex) =>
              //@ts-ignore
              field.type === WAYPOINT_TYPE.STOP && (
                <DragAndDropItemWrapper
                  item
                  //@ts-ignore
                  key={field.id}
                  container
                  abortDragging={abortDragging}
                  justifyContent='start'
                  alignItems='start'
                  wrap='nowrap'
                  type={'intermediateWaypoints'}
                  index={intermediateWaypointIndex}
                  id={field.id}
                  moveItem={(dragIndex: number, hoverIndex: number) => move(dragIndex, hoverIndex)}
                  dragStart={() => {
                    console.log()
                  }}
                  dragEnd={() => {
                    console.log()
                  }}
                >
                  <DragIndicatorIcon sx={{ alignSelf: 'center' }} />
                  <Controller
                    control={control}
                    name={`${keys.intermediateWaypoints}.${intermediateWaypointIndex}`}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <AddressPOILookup
                          textFeildProps={{
                            label: getStopLabel(field.id),
                            error: !!(
                              errors[keys.intermediateWaypoints] &&
                              errors[keys.intermediateWaypoints]![intermediateWaypointIndex]
                            ),
                            helperText:
                              errors[keys.intermediateWaypoints] &&
                              errors[keys.intermediateWaypoints]![intermediateWaypointIndex] &&
                              getErrorMessage(
                                errors[keys.intermediateWaypoints]![intermediateWaypointIndex]!
                              ),
                            placeholder: 'Search for address',
                            onFocus: () => setAbortDragging(true),
                            onBlur: () => setAbortDragging(false)
                          }}
                          value={getAddressPOILookupValue(value)}
                          onChange={(v) => {
                            if (v) {
                              onChange({ ...v, type: WAYPOINT_TYPE.STOP })
                            }
                          }}
                        />
                      )
                    }}
                  />
                  <IconButton
                    sx={{ mt: '8px' }}
                    color='error'
                    onClick={() => remove(intermediateWaypointIndex)}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </DragAndDropItemWrapper>
              )
          )}
        </DndProvider>

        <Grid item alignSelf='start'>
          <Button
            sx={{ minWidth: 'unset' }}
            variant='text'
            startIcon={<AddIcon />}
            onClick={addStop}
          >
            <Typography variant='textBold'>Add stop </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Stops
