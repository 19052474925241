import { createTheme } from '@mui/material/styles'
import React from 'react'

const WHITE = '#FFFFFF'
const MAIN_BLUE = '#0C64B3'
const RED = '#DA353A'
const STROKE = '#F1F1F1'
const MAIN_GRAY = '#7C8DB5'
const LIGHT_GRAY = '#F8FBFF'
const MAIN_DARK = '#2F3850'
const GRAY = '#E6E6E6'
const LIGHT_BLUE_1 = '#F6F8FC'
const LIGHT_BLUE_2 = '#E2E9F0'
const BORDER_GRAY = '#E9F0F9'
const GREEN = '#51AA54'

export const COLORS = {
  WHITE,
  MAIN_BLUE,
  RED,
  STROKE,
  MAIN_GRAY,
  LIGHT_GRAY,
  MAIN_DARK,
  GRAY,
  LIGHT_BLUE_1,
  LIGHT_BLUE_2,
  BORDER_GRAY,
  GREEN
}

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '20px',
          minWidth: '230px',
          minHeight: '40px',
          borderRadius: '8px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: MAIN_GRAY
          },
          '& label.Mui-focused': {
            color: MAIN_BLUE
          },
          '& .MuiOutlinedInput-root': {
            background: WHITE,
            '& fieldset': {
              borderColor: BORDER_GRAY
            },
            '&:hover fieldset': {
              borderColor: MAIN_BLUE
            },
            '&.Mui-focused fieldset': {
              borderColor: MAIN_BLUE
            },
            '& .MuiSvgIcon-root': {
              color: MAIN_GRAY
            },
            '& .MuiInputAdornment-root': {
              '& .MuiTypography-root': {
                color: MAIN_GRAY
              }
            }
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            borderRadius: '5px',
            height: '3px'
          },
          '& .MuiTypography-root': {
            textTransform: 'none',
            color: MAIN_GRAY
          },
          '& .Mui-selected': {
            '& .MuiTypography-root': {
              color: MAIN_BLUE,
              fontWeight: 600
            }
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            '&:nth-of-type(even)': {
              background: '#ECF2FA'
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '14px 12px 12px 12px',
          borderBottom: 'unset'
        }
      }
    }
  },
  palette: {
    text: {
      primary: MAIN_DARK
    },
    primary: {
      main: MAIN_BLUE
    },
    secondary: {
      main: MAIN_GRAY
    },
    error: {
      main: RED
    },
    success: {
      main: COLORS.GREEN
    }
  },
  typography: {
    largeTitle: {
      fontSize: 34,
      lineHeight: '41px'
    },
    largeTitleBold: {
      fontSize: 34,
      lineHeight: '41px',
      fontWeight: 600
    },
    title1: {
      fontSize: 28,
      lineHeight: '34px'
    },
    title1Bold: {
      fontSize: 28,
      lineHeight: '34px',
      fontWeight: 600
    },
    title2: {
      fontSize: 24,
      lineHeight: '28px'
    },
    title2Bold: {
      fontSize: 24,
      lineHeight: '28px',
      fontWeight: 600
    },
    title3: {
      fontSize: 20,
      lineHeight: '24px'
    },
    title3Bold: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 600
    },
    body: {
      fontSize: 17,
      lineHeight: '22px'
    },
    bodyBold: {
      fontSize: 17,
      lineHeight: '22px',
      fontWeight: 600
    },
    text: {
      fontSize: 16,
      lineHeight: '21px'
    },
    textBold: {
      fontSize: 16,
      lineHeight: '21px',
      fontWeight: 600
    },
    subheadline: {
      fontSize: 14,
      lineHeight: '20px'
    },
    subheadlineBold: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 600
    },
    caption: {
      fontSize: 12,
      lineHeight: '16px'
    },
    captionBold: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 600
    }
  },
  shape: {
    borderRadius: 4
  }
})

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    largeTitle: React.CSSProperties
    title1: React.CSSProperties
    title2: React.CSSProperties
    title3: React.CSSProperties
    body: React.CSSProperties
    text: React.CSSProperties
    subheadline: React.CSSProperties
    caption: React.CSSProperties

    largeTitleBold: React.CSSProperties
    title1Bold: React.CSSProperties
    title2Bold: React.CSSProperties
    title3Bold: React.CSSProperties
    bodyBold: React.CSSProperties
    textBold: React.CSSProperties
    subheadlineBold: React.CSSProperties
    captionBold: React.CSSProperties
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    largeTitle: React.CSSProperties
    title1: React.CSSProperties
    title2: React.CSSProperties
    title3: React.CSSProperties
    body: React.CSSProperties
    text: React.CSSProperties
    subheadline: React.CSSProperties
    caption: React.CSSProperties

    largeTitleBold: React.CSSProperties
    title1Bold: React.CSSProperties
    title2Bold: React.CSSProperties
    title3Bold: React.CSSProperties
    bodyBold: React.CSSProperties
    textBold: React.CSSProperties
    subheadlineBold: React.CSSProperties
    captionBold: React.CSSProperties
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    largeTitle: true
    title1: true
    title2: true
    title3: true
    body: true
    text: true
    subheadline: true
    caption: true

    largeTitleBold: true
    title1Bold: true
    title2Bold: true
    title3Bold: true
    bodyBold: true
    textBold: true
    subheadlineBold: true
    captionBold: true
  }
}
