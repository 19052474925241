import { useLocation } from 'react-router-dom'

export const useGoBack = () => {
  const location = useLocation()
  const currentItemGroups = location.pathname.match(/^(\/\w+\/*\w*)(\/\d)/)
  const currentItemPath = currentItemGroups ? currentItemGroups[1] : '/'
  const { state } = location
  const backPath = state || `${currentItemPath}?page=1`
  return { backPath }
}
