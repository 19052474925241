import axios, { AxiosRequestConfig } from 'axios'
import {
  Auth0ContextInterface,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions
} from '@auth0/auth0-react'

const audience = process.env.REACT_APP_AUTH0_AUDIENCE
const scope = process.env.REACT_APP_AUTH0_SCOPE

const getTokenSilentlyOptions: GetTokenSilentlyOptions = {
  audience,
  scope
}

const getTokenWithPopupOptions: GetTokenWithPopupOptions = {
  audience,
  scope
}

const setupAxiosInterceptors = (
  getIdTokenClaims: Auth0ContextInterface['getIdTokenClaims'],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  getAccessTokenWithPopup: Auth0ContextInterface['getAccessTokenWithPopup']
) => {
  const onRequestSuccess = async (config: AxiosRequestConfig) => {
    try {
      const token = await getAccessTokenSilently(getTokenSilentlyOptions)
      const idtoken: any = await getIdTokenClaims();
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${idtoken.__raw}`,
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_ROUTING_OCP_APIM_KEY!
      }
    } catch (e: any) {
      // from @auth0/auth0-spa-js
      // export const RECOVERABLE_ERRORS = [
      //   'login_required',
      //   'consent_required',
      //   'interaction_required',
      //   'account_selection_required',
      //   // Strictly speaking the user can't recover from `access_denied` - but they
      //   // can get more information about their access being denied by logging in
      //   // interactively.
      //   'access_denied'
      // ];
      if (e.error === 'consent_required') {
        const token = await getAccessTokenWithPopup(getTokenWithPopupOptions)
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_ROUTING_OCP_APIM_KEY!
        }
      }
    }
    return config
  }
  axios.interceptors.request.use(onRequestSuccess, void 0)
}

export default setupAxiosInterceptors
