import React, { useState, useEffect } from 'react'
import * as signalR from '@microsoft/signalr'
import { useNegotiate } from '../queries/tracking'

type Tracking = {
  transport?: signalR.HttpTransportType | signalR.ITransport
}

type TrackingReturnType = {
  connection: signalR.HubConnection | null
}

const useTracking = ({
  transport = signalR.HttpTransportType.WebSockets
}: Tracking): TrackingReturnType => {
  const { data } = useNegotiate()
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null)

  useEffect(() => {
    if (connection) {
      if (data && data.accessToken && data.url) {
        connection.stop()

        const { accessToken, url } = data

        const newConnection = new signalR.HubConnectionBuilder()
          .withUrl(url, {
            accessTokenFactory: () => accessToken
          })
          .build()
        setConnection(newConnection)
      }
    } else {
      if (data && data.accessToken && data.url) {
        const { accessToken, url } = data

        const newConnection = new signalR.HubConnectionBuilder()
          .withUrl(url, {
            accessTokenFactory: () => accessToken
          })
          .build()
        setConnection(newConnection)
      }
    }
  }, [data])

  useEffect(
    () => () => {
      connection?.stop()
    },
    [connection]
  )

  return { connection }
}

export default useTracking
