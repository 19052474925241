import React, { CSSProperties, FC } from 'react'
import BackdropMUI from '@mui/material/Backdrop'
import CircularProgress from './CircularProgress'

const Backdrop: FC<CSSProperties> = ({ ...styles }) => (
  <div>
    <BackdropMUI
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, ...styles }}
      open={true}
    >
      <CircularProgress size={70} thickness={5} disableShrink />
    </BackdropMUI>
  </div>
)

export default Backdrop
