import React, { FC, useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '../components/UI/Switch'
import EmptyTableResult from '../components/UI/EmptyTableResult'
import CircularProgress from '../components/UI/CircularProgress'
import Backdrop from '../components/UI/Backdrop'
import Pagination from '../components/UI/Pagination'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import FileUploader from '../components/UI/FileUploader'

import { useRoutes } from '../queries/routes'
import { NumericFormat, PatternFormat } from 'react-number-format'

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  {
    title: 'The Lord of the Rings: The Return of the King',
    year: 2003
  }
]

const rows = [
  {
    id: 2,
    routeNumber: 1,
    routeName: 'Route name',
    routeDescription: 'Route description',
    startPoint: ['Start point', 'Start point'],
    endPoint: ['Start point', 'Start point'],
    vehicleType: 'Vehicle type',
    stops: 6,
    routeEnabled: true
  },
  {
    id: 3,
    routeNumber: 1,
    routeName: 'Route name',
    routeDescription: 'Route description',
    startPoint: ['Start point', 'Start point'],
    endPoint: ['Start point', 'Start point'],
    vehicleType: 'Vehicle type',
    stops: 6,
    routeEnabled: true
  },
  {
    id: 4,
    routeNumber: 1,
    routeName: 'Route name',
    routeDescription: 'Route description',
    startPoint: ['Start point', 'Start point'],
    endPoint: ['Start point', 'Start point'],
    vehicleType: 'Vehicle type',
    stops: 6,
    routeEnabled: true
  },
  {
    id: 5,
    routeNumber: 1,
    routeName: 'Route name',
    routeDescription: 'Route description',
    startPoint: ['Start point', 'Start point'],
    endPoint: ['Start point', 'Start point'],
    vehicleType: 'Vehicle type',
    stops: 6,
    routeEnabled: true
  }
]

const BasicTable = () => {
  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow sx={{ verticalAlign: 'bottom' }}>
            <TableCell>
              <Typography variant='subheadlineBold' color='secondary'>
                Route number
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography variant='subheadlineBold' color='secondary'>
                Route name
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography variant='subheadlineBold' color='secondary'>
                Route description
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography variant='subheadlineBold' color='secondary'>
                Start point
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography variant='subheadlineBold' color='secondary'>
                End point
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography variant='subheadlineBold' color='secondary'>
                Vehicle type
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography variant='subheadlineBold' color='secondary'>
                Stops
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography variant='subheadlineBold' color='secondary'>
                Route enabled
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography variant='subheadlineBold' color='secondary'>
                Actions
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                height: '70px',
                verticalAlign: 'top'
              }}
            >
              <TableCell component='th' scope='row'>
                {row.routeNumber}
              </TableCell>
              <TableCell align='left'>{row.routeName}</TableCell>
              <TableCell align='left'>{row.routeDescription}</TableCell>
              <TableCell align='left'>
                <Typography variant='subheadline'>{row.startPoint[0]}</Typography>
                <br />
                <Typography variant='subheadline' color='secondary'>
                  {row.startPoint[1]}
                </Typography>
              </TableCell>
              <TableCell align='left'>
                <Typography variant='subheadline'>{row.endPoint[0]}</Typography>
                <br />
                <Typography variant='subheadline' color='secondary' paddingTop={'40px'}>
                  {row.endPoint[1]}
                </Typography>
              </TableCell>
              <TableCell align='left'>{row.vehicleType}</TableCell>
              <TableCell align='left'>{row.stops}</TableCell>
              <TableCell align='left'>{row.routeEnabled}</TableCell>
              <TableCell align='left'>actions</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination count={10} totalItems={10} />
    </>
  )
}

const UIComponents: FC = () => {
  const [maskValue, setMaskValue] = useState('')

  const { status, data: routes, error, isFetching } = useRoutes()

  useEffect(() => {
    console.log('status', status)
    console.log('routes', routes)
    console.log('error', error)
    console.log('isFetching', isFetching)
    console.log('____________')
  }, [status, routes, error, isFetching])

  return (
    <>
      <Grid direction='row' justifyContent='space-between' alignItems='center'>
        <Button variant='contained'>Contained</Button>
        <Button disabled variant='contained'>
          Contained
        </Button>
        <Button variant='contained' startIcon={<AddIcon />}>
          Contained
        </Button>
      </Grid>
      <Divider sx={{ marginY: '20px' }} />
      <Grid direction='row' justifyContent='space-between' alignItems='center'>
        <Button variant='text'>Contained</Button>
        <Button disabled variant='text' startIcon={<AddIcon />}>
          Contained
        </Button>
        <Button variant='text' startIcon={<AddIcon />}>
          Contained
        </Button>
      </Grid>
      <Divider sx={{ marginY: '20px' }} />
      <Grid direction='row' justifyContent='space-between' alignItems='center'>
        <Button variant='outlined'>Contained</Button>
        <Button disabled variant='outlined' startIcon={<AddIcon />}>
          Contained
        </Button>
        <Button variant='outlined' startIcon={<AddIcon />}>
          Contained
        </Button>
      </Grid>
      <Divider sx={{ marginY: '20px' }} />
      <Grid direction='row' justifyContent='space-between' alignItems='center'>
        <TextField
          label='Wisth'
          helperText='Incorrect entry.'
          error
          InputProps={{
            startAdornment: <InputAdornment position='start'>inch</InputAdornment>
          }}
        />
        <TextField
          label='Route description'
          InputProps={{
            endAdornment: (
              <IconButton>
                <HighlightOffIcon />
              </IconButton>
            )
          }}
        />
        <TextField
          label='Text area'
          InputProps={{
            startAdornment: <SearchIcon />
          }}
        />
        <Autocomplete
          id='controllable-states-demo'
          fullWidth
          disablePortal
          options={top100Films.map((option) => option.title)}
          clearIcon={<HighlightOffIcon />}
          popupIcon={null}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Route description'
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon />
              }}
            />
          )}
        />
        <NumericFormat
          label='react-number-format'
          decimalScale={3}
          allowNegative={false}
          thousandSeparator=' '
          InputProps={{
            startAdornment: <SearchIcon />
          }}
          value={12323}
          customInput={TextField}
        />
        <PatternFormat
          label='react-pattern-format'
          format='+1 (###) #### ###'
          allowEmptyFormatting
          mask='_'
          customInput={TextField}
          InputProps={{
            startAdornment: <SearchIcon />
          }}
        />
      </Grid>
      <Divider sx={{ marginY: '20px' }} />
      <Grid direction='row' justifyContent='space-between' alignItems='center'>
        <Tabs value={2} aria-label='basic tabs example'>
          <Tab label={<Typography variant='textBold'> Item One </Typography>}></Tab>
          <Tab label={<Typography variant='textBold'> Item Two </Typography>} />
          <Tab label={<Typography variant='textBold'> Item Three </Typography>} />
        </Tabs>
      </Grid>
      <Divider sx={{ marginY: '20px' }} />
      <Grid direction='row' justifyContent='space-between' alignItems='center'>
        <FormControlLabel control={<Switch sx={{ m: 1 }} defaultChecked />} label='iOS style' />
      </Grid>
      <Divider sx={{ marginY: '20px' }} />
      <Grid direction='row' justifyContent='space-between' alignItems='center'>
        <CircularProgress size={70} thickness={5} disableShrink />
      </Grid>
      <Divider sx={{ marginY: '20px' }} />
      <BasicTable />
      <Divider sx={{ marginY: '20px' }} />

      <Grid>
        <FileUploader
          title='Upload file with drivers'
          accept={{ 'text/csv': ['.csv'] }}
          onSave={(file) => console.log(file)}
        >
          <Button variant='contained'>FileUploader</Button>
        </FileUploader>
      </Grid>

      <Grid direction='row' justifyContent='space-between' alignItems='center'>
        <EmptyTableResult title='No drivers added' subtitle='Add at least one driver' />
      </Grid>

      {/* <Backdrop /> */}
    </>
  )
}

export default UIComponents
